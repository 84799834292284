<template>
  <div>
    <div class="container">
      <div>
        <h4>Bir Anket Seçin</h4>
        <select v-model="selectedSurveyId" @change="onSurveyChange">
          <option value="" disabled>Anket Seç</option>
          <option v-for="survey in surveys" :value="survey.survey_id" :key="survey.survey_id">
            {{ survey.title }}
          </option>
        </select>
      </div>
      <div v-if="selectedSurveyId">
        <h4>Anket Yanıtları</h4>
        <button @click="toggleAllResponses">{{ allResponsesVisible ? 'Tümünü Gizle' : 'Tümünü Göster' }}</button>
        <div v-for="(user, userId, index) in surveyResponses" :key="userId" class="user-responses">
          <div class="user-header" @click="toggleUserResponses(userId)">
            <span><b>{{ index + 1 }}</b>.</span>
            <h5>Kullanıcı {{ userId }} ({{ user.e_mail }})</h5>
            <span>({{ user.responses.length }} yanıt)</span>
          </div>
          <div v-if="user.isVisible">
            <div v-for="response in user.responses" :key="response.response_id">
              <p><b>Soru:</b> {{ response.question?.question_text || 'N/A' }}</p>
              <p><b>Cevap:</b> {{ response.answer || response.option?.option_text || 'N/A' }}</p>
              <hr>
            </div>
          </div>
        </div>
        <button v-if="hasMoreResponses" @click="loadMoreResponses">Daha Fazla Yükle</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      surveys: [],
      selectedSurveyId: null,
      surveyResponses: {},
      offset: 0,
      limit: 500,
      hasMoreResponses: true,
      allResponsesVisible: false
    };
  },
  async mounted() {
    await this.fetchSurveys();
  },
  methods: {
    async fetchSurveys() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/surveys', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.surveys = response.data.filter(survey => survey.status === 'active' || survey.status === 'inactive');
      } catch (error) {
        console.error('Anketler alınırken hata oluştu:', error);
      }
    },
    async fetchSurveyResponses() {
      if (!this.selectedSurveyId) return;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/admin/surveys/${this.selectedSurveyId}/responses-by-user?limit=${this.limit}&offset=${this.offset}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const data = response.data;

        if (Object.keys(data).length < this.limit) {
          this.hasMoreResponses = false;
        }

        if (this.offset === 0) {
          this.surveyResponses = {};
        }

        for (const userId in data) {
          if (!this.surveyResponses[userId]) {
            this.surveyResponses[userId] = {
              ...data[userId],
              isVisible: this.allResponsesVisible
            };
          } else {
            this.surveyResponses[userId].responses.push(...data[userId].responses);
          }
        }
      } catch (error) {
        console.error('Anket yanıtları alınırken hata oluştu:', error);
      }
    },
    onSurveyChange() {
      this.surveyResponses = {};
      this.offset = 0;
      this.hasMoreResponses = true;
      this.fetchSurveyResponses();
    },
    loadMoreResponses() {
      this.offset += this.limit;
      this.fetchSurveyResponses();
    },
    toggleUserResponses(userId) {
      this.surveyResponses[userId].isVisible = !this.surveyResponses[userId].isVisible;
    },
    toggleAllResponses() {
      this.allResponsesVisible = !this.allResponsesVisible;
      for (const userId in this.surveyResponses) {
        this.surveyResponses[userId].isVisible = this.allResponsesVisible;
      }
    }
  }
};
</script>

<style scoped>
 
.container {
  background-color: #050505;
  padding: 20px;
  border-radius: 8px;
}

select, button {  
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
 

.user-responses .user-header {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 5px;
  background-color: #242424;
  border-radius: 4px;
}

.user-responses .user-header span {
  margin-right: 10px;
}

.user-header {
  cursor: pointer;
}
.user-responses .user-header:hover {
  background-color: #202020;
}

button {
  margin-top: 10px;
}  
hr {
    border: none; 
    height: 1px; 
    background-color: #272727; 
}  
</style>
