<template>
  <div>
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div>
    <div class="component-wrap"><component :is="currentComponent"></component></div>
  </div>
</template>

<script>
import CategoryManager from './CategoryManager.vue'; 
import StaticPages from './StaticPages.vue';
import AgreementManagement from './AgreementManagement.vue';
import MessageMain from './MessageMain.vue';
import EducationMain from './education/EducationMain.vue'; 
import GeneralDocumentManager from './GeneralDocumentManager.vue';
import MasterWorldMain from './masterworld/MasterWorldMain.vue';
import FaqManager from './FaqManager.vue';
import AnnouncementManager from './AnnouncementManager.vue'; 
import ShowroomManager from './ShowroomManager.vue';
import QuoteManager from './QuoteManager.vue';    
import NotesMain from './notes/NotesMain.vue';
import HowToMain from './kullanim/HowToMain.vue'; 
import HesapSeviyeleri from './HesapSeviyeleri.vue';
import Feedbacks from './FeedBacks.vue';
import SurveyMain from './survey/SurveyMain.vue';
import ActivityMain from './activity/ActivityMain.vue';
import SupportMain from './support/SupportMain.vue';
import NotificationMain from './notifications/NotificationMain.vue';
import ProductMain from './products/ProductMain.vue';
import PortalMain from './portal/PortalMain.vue';

export default {
  data() { 
    return {
      currentComponent: 'CategoryManager', 
      components: [  
        { name: 'Ana Sayfa Kategoriler', component: 'CategoryManager' },
        { name: 'Ürünler Genel', component: 'ProductMain' }, 
        { name: 'Portal', component: 'PortalMain' }, 
        { name: 'Eğitim Yönetimi', component: 'EducationMain' },
        { name: 'Kullanım Klavuzu', component: 'HowToMain' }, 
        { name: 'Özet bilgiler', component: 'QuoteManager' }, 
        { name: 'Uluslararası Belgeler', component: 'GeneralDocumentManager' }, 
        { name: 'Mesaj Sistemi', component: 'MessageMain' },
        { name: 'Geri Bildirimler', component: 'Feedbacks' },
        { name: 'Anketler', component: 'SurveyMain' }, 
        { name: 'Sözleşmeler', component: 'AgreementManagement' },
        { name: 'Master World', component: 'MasterWorldMain' }, 
        { name: 'Sabit sayfalar', component: 'StaticPages' },
        { name: 'Showroom', component: 'ShowroomManager' }, 
        { name: 'SSS', component: 'FaqManager' },  
        { name: 'Duyurular', component: 'AnnouncementManager' },  
        { name: 'Hesap Seviyeleri', component: 'HesapSeviyeleri' },
        { name: 'Kullanıcı Aktiviteleri', component: 'ActivityMain' },
        { name: 'Kullanıcı Bildirimleri', component: 'NotificationMain' },
        { name: 'Destek Kayıtları', component: 'SupportMain' }, 
      ],
    };
  },
  components: {
    CategoryManager,  
    ProductMain,
    StaticPages,
    AgreementManagement,
    MessageMain,
    EducationMain,  
    GeneralDocumentManager,
    MasterWorldMain,
    FaqManager,
    AnnouncementManager, 
    ShowroomManager,
    QuoteManager,   
    NotesMain,
    HowToMain, 
    HesapSeviyeleri,
    Feedbacks,
    SurveyMain,
    ActivityMain,
    SupportMain,
    NotificationMain,
    PortalMain
  },
};
</script>
  
<style scoped>
.button-container {
margin: 1em 0rem; 
text-align: center;
display: flex; 
flex-wrap: wrap; 
align-items: center;
justify-content: center; 
padding: 2em;
} 

.button-container button { 
border-radius: 0rem;
display: inline-block;
padding: 0.4rem 2rem;
min-width: 6rem;
margin: 0.2rem;
border: 0.06rem solid #6666; 
background-color: #050505;
color: #b5b5b5;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
} 
.button-container button:hover {
background-color: #000000;  
color: #d9dee0;
} 
.button-container button.active {
background-color: #065959;
color: #ffffff; 
} 
@media (max-width: 360px) {
.button-container {
  flex-direction: column;
} 
.button-container button {
  width: 90%;
  margin: 0.2em auto; 
}
} 
</style>
  