<template>
  <div class="activity-container">
    <h3>Kullanıcı Aktiviteleri</h3>

    <table v-if="activities.length" class="activity-table">
      <thead>
        <tr>
          <th>User ID</th>
          <th>Kullanıcı Adı</th>
          <th>Aktivite</th>
          <th>Tarih</th>
          <th>İşlem</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="activity in activities" :key="activity.id">
          <td>{{ activity.user_id }}</td>
          <td>{{ activity.user_name }}</td>
          <td>{{ activity.activity }}</td>
          <td>{{ activity.created_at }}</td>
          <td>
            <button @click="deleteActivity(activity.id)" class="delete">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else>Henüz bir aktivite yok.</div>
  </div>
</template>

<script>
import axios from "axios"; 

export default { 
  data() {
    return {
      activities: [], 
    };
  },
  methods: {
    async fetchActivities() {
      try {
        const response = await axios.get("/admin/useractivities");
        this.activities = response.data.data;
      } catch (error) {
        console.error("Aktiviteler alınırken hata oluştu:", error);
      } 
    },
    async deleteActivity(id) {
      if (!confirm("Bu aktiviteyi silmek istediğinizden emin misiniz?")) return;
      try {
        await axios.delete(`/admin/useractivities/${id}`);
        this.activities = this.activities.filter(activity => activity.id !== id);
      } catch (error) {
        console.error("Aktivite silinirken hata oluştu:", error);
      }
    }
  },
  mounted() {
    this.fetchActivities();
  }
};
</script>

<style scoped> 
</style>
