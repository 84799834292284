<template>
    <div class="product-platform-manager">
        <div class="alert info"><b>Bu özellik kullanımdışı.</b><br>Ürünlerin hangi platformlarda aktif olarak kullanılabileceği. </div>
      <h3>Ürün-Platform İlişkileri</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Ürün Adı</th>
            <th>Platformlar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.product_id">
            <td>{{ product.product_name }}</td>
            <td>
              <div v-for="web in webs" :key="web.web_id" class="platform-checkbox">
                <label>
                  <input
                    type="checkbox"
                    :checked="isProductLinkedToWeb(product.product_id, web.web_id)"
                    @change="toggleProductPlatform($event, product.product_id, web.web_id)"
                  />
                  {{ web.web_name }}
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "ProductPlatformManager",
    data() {
      return {
        products: [],
        webs: [], 
        productPlatformsMap: {},
      };
    },
    methods: { 
      async fetchProducts() {
        try {
          const response = await axios.get("/admin/products");
          this.products = response.data;
        } catch (error) {
          console.error("Ürünler alınırken hata:", error);
        }
      }, 
      async fetchWebs() {
        try {
          const response = await axios.get("/admin/webs");
          this.webs = response.data;
        } catch (error) {
          console.error("Platformlar alınırken hata:", error);
        }
      }, 
      async fetchProductPlatforms() {
        try {
          const response = await axios.get("/admin/productplatforms"); 
          this.productPlatformsMap = {};
          response.data.forEach((item) => {
            if (!this.productPlatformsMap[item.product_id]) {
              this.productPlatformsMap[item.product_id] = {};
            }
            this.productPlatformsMap[item.product_id][item.web_id] = item.productplatform_id;
          });
        } catch (error) {
          console.error("Ürün-platform ilişkileri alınırken hata:", error);
        }
      }, 
      isProductLinkedToWeb(productId, webId) {
        return (
          this.productPlatformsMap[productId] &&
          this.productPlatformsMap[productId][webId] !== undefined
        );
      }, 
      async toggleProductPlatform(event, productId, webId) {
        const checked = event.target.checked; 
        if (checked) {
          try {
            const payload = { product_id: productId, web_id: webId };
            const response = await axios.post("/admin/productplatforms", payload); 
            if (!this.productPlatformsMap[productId]) {
              this.productPlatformsMap[productId] = {};
            }
            this.productPlatformsMap[productId][webId] = response.data.productplatform_id;
          } catch (error) {
            console.error("İlişki oluşturulurken hata:", error); 
            event.target.checked = false;
          }
        } else { 
          const platformId = this.productPlatformsMap[productId]
            ? this.productPlatformsMap[productId][webId]
            : null;
          if (platformId) {
            try {
              await axios.delete(`/admin/productplatforms/${platformId}`); 
              delete this.productPlatformsMap[productId][webId];
            } catch (error) {
              console.error("İlişki silinirken hata:", error); 
              event.target.checked = true;
            }
          }
        }
      }, 
      async fetchAllData() {
        await Promise.all([
          this.fetchProducts(),
          this.fetchWebs(),
          this.fetchProductPlatforms(),
        ]);
      },
    },
    mounted() {
      this.fetchAllData();
    },
  };
  </script>
  
  <style scoped> 
  .platform-checkbox {
    display: inline-block;
    margin-right: 1rem;
  }
  </style>
  