<template>
  <div class="container">
    <div style="color: lightgray;text-align: center;">Lütfen dikkat. Ürün adını ve Kampanya adını belirtmeyi unutmayın. <br> Genel istatistikler için GPT 3.5 i kullanabilirsiniz</div>
    <div v-if="messages.length" class="messages">
      <div v-for="(message, index) in messages" :key="index" class="message">
        <p><strong>{{ message.role === 'user' ? 'You' : 'AI' }}:</strong> {{ message.content }}</p>
      </div>
    </div>

    <form @submit.prevent="sendPrompt">
      <select v-model="selectedModel">
        <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
        <option value="gpt-4o">GPT-4o</option>
      </select>
      <textarea v-model="prompt" placeholder="Enter Here"></textarea>
      <button :disabled="loading" type="submit">
        {{ loading ? 'Sending...' : 'Send' }}
      </button>
    </form>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      prompt: '',
      selectedModel: 'gpt-4o',
      messages: [],
      loading: false
    };
  },
  methods: {
    async sendPrompt() {
      if (this.prompt.trim() === '') return;
      this.messages.push({ role: 'user', content: this.prompt });
      this.loading = true;

      try {
        const res = await axios.post('/ai/chatwith/generate-ai-response', {
          prompt: this.prompt,
          model: this.selectedModel,
        });

        this.messages.push({ role: 'assistant', content: res.data.response });
        this.prompt = '';
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.container { 
  margin: 0 auto;
  padding: 1rem;
}

.messages {
  margin: 20px 0;
  min-height: 32px;
  background-color: #1e1e1e;
  padding: 1rem;
  border-radius: 8px;
  color: #f1f1f1;
}

.message {
  margin-bottom: 10px;
}

textarea {
  display: block;
  width: 100%; 
  font-size: 14px;
  padding: 8px;
  border-radius: 4px; 
}

button {
  border-radius: 0.4rem;
  display: inline-block;
  padding: 0.4rem 2rem;
  min-width: 6rem;
  margin: 0.2rem;
  border: 0.06rem solid #262626;
  background-color: #587a41;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

select {
  display: block;
  width: 100%;
  padding: 8px; 
  font-size: 14px;
  border-radius: 4px; 
}
</style>
