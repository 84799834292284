<template>
  <div style="color: red;">xAPI BAĞLANTI HATASI
  </div>
</template>

<script>
export default { 
}
</script>

<style scoped> 
</style>
