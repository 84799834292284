<template>
    <div> 
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  
  <script>
import CurrentStocks from './CurrentStocks.vue'; 

    
  
  export default {
    data() {
      return {
        currentComponent: 'CurrentStocks', 
        components: [
         { name: 'Güncel Stok', component: 'CurrentStocks' }, 
          { name: 'Stok Hareketleri', component: 'ProductPrices' }, 
          { name: 'Giriş/Çıkış', component: 'AllProductPrices' }, 
          { name: 'Aktiviteler', component: 'AllProductPr2ices' }, 
        ]
      };
    },
  
    components: {
        CurrentStocks
    },
    
  };
  </script>
    
  <style scoped> 
      .button-container button.active {
    background-color: #815328;
    color: #ffffff; 
    } 
  </style>
    