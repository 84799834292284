<template>
  <div class="closed-tickets">
    <h3>Kapalı Destek Talepleri</h3>
    <table>
      <thead>
        <tr>
          <th>Talep ID</th>
          <th>Kullanıcı ID</th>
          <th>Konu</th>
          <th>Durum</th>
          <th>Oluşturulma Tarihi</th>
          <th>Güncellenme Tarihi</th>
          <th>Bölüm</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ticket in tickets" :key="ticket.ticket_id">
          <td>{{ ticket.ticket_id }}</td>
          <td>{{ ticket.user_id }}</td>
          <td>{{ ticket.subject }}</td>
          <td>{{ ticket.status }}</td>
          <td>{{ formatDate(ticket.created_at) }}</td>
          <td>{{ formatDate(ticket.updated_at) }}</td>
          <td>{{ ticket.department }}</td>
          <td>
            <button @click="deleteTicket(ticket.ticket_id)" class="delete">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
    <button @click="loadMore" class="moreButton">Daha Fazla Göster</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    tickets: [],
    page: 1,
    limit: 20
  };
},
async mounted() {
  await this.fetchTickets();
},
methods: {
  async fetchTickets() {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`/admin/closed-tickets?page=${this.page}&limit=${this.limit}`, { 
        headers: { Authorization: `Bearer ${token}` } 
      });
      this.tickets.push(...response.data.tickets);
      this.page++;
    } catch (error) {
      console.error('Kapalı talepler getirilirken hata oluştu:', error);
    }
  },
  formatDate(date) {
    return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
  },
  loadMore() {
    this.fetchTickets();
  },
  async deleteTicket(ticketId) {
    if (!confirm("Bu talebi ve tüm yanıtlarını silmek istediğinizden emin misiniz?")) {
      return;
    }

    const token = localStorage.getItem('token');
    try {
      const response = await axios.delete(`/admin/delete-ticket/${ticketId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data && response.data.success) {
        this.tickets = this.tickets.filter(ticket => ticket.ticket_id !== ticketId);
        alert("Talep ve yanıtları başarıyla silindi.");
      } else {
        alert("Talep silinirken hata oluştu: " + (response.data.error || "Bilinmeyen hata"));
      }
    } catch (error) {
      console.error('Talep silinirken hata oluştu:', error);
      alert("Talep silinirken hata oluştu: " + (error.response && error.response.data && error.response.data.error ? error.response.data.error : "Bilinmeyen hata"));
    }
  }
}
};
</script>
 
<style scoped> 
</style>