<template>
    <div class="studio-worker-management">
      <h3>Stüdyo Çalışan Yönetimi</h3>
  
  
      <h4>Tüm Çalışanlar</h4>
      <table class="worker-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Stüdyo</th>
            <th>Adı</th>
            <th>Telefon</th>
            <th>E-Posta</th>
            <th>Başlangıç</th>
            <th>Bitiş</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="worker in workers" :key="worker.studioworker_id">
            <td>{{ worker.studioworker_id }}</td>
            <td>{{ worker.studio ? worker.studio.name : '' }}</td>
            <td>{{ worker.name }}</td>
            <td>{{ worker.phone }}</td>
            <td>{{ worker.e_mail }}</td>
            <td>{{ worker.started_at }}</td>
            <td>{{ worker.ended_at || '-' }}</td>
            <td><button @click="editWorker(worker)">Düzenle</button></td>
            <td><button @click="confirmDelete(worker.studioworker_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>

      <br>

      
      <h4>{{ isEdit ? "Çalışanı Düzenle" : "Yeni Çalışan Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateWorker() : createWorker()">
        <label>Stüdyo Seç</label>
        <select v-model="selectedWorker.studio_id" required>
          <option disabled value="">Lütfen bir stüdyo seçin</option>
          <option v-for="studio in studios" :key="studio.studio_id" :value="studio.studio_id">
            {{ studio.name }}
          </option>
        </select>
  
        <input v-model="selectedWorker.name" placeholder="Çalışan Adı" required />
        <input v-model="selectedWorker.password" type="password" placeholder="Şifre" required />
        <input v-model="selectedWorker.phone" placeholder="Telefon" required />
        <input v-model="selectedWorker.e_mail" type="email" placeholder="E-Posta" required />
        <input v-model="selectedWorker.started_at" type="date" placeholder="Başlangıç Tarihi" required />
        <input v-model="selectedWorker.ended_at" type="date" placeholder="Bitiş Tarihi" />
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Kaydet" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        workers: [],
        studios: [],
        selectedWorker: {
          studioworker_id: null,
          studio_id: '',
          name: '',
          password: '',
          phone: '',
          e_mail: '',
          started_at: '',
          ended_at: '',
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchStudios() {
        try {
          const response = await axios.get('/admin/refika/studios');
          this.studios = response.data.studios;
        } catch (error) {
          console.error('Stüdyolar alınırken hata oluştu:', error);
        }
      },
      async fetchWorkers() {
        try {
          const response = await axios.get('/admin/refika/studioworkers');
          this.workers = response.data.workers;
        } catch (error) {
          console.error('Çalışanlar alınırken hata oluştu:', error);
        }
      },
      async createWorker() {
        try {
          await axios.post('/admin/refika/studioworkers', this.selectedWorker);
          this.fetchWorkers();
          this.resetForm();
        } catch (error) {
          console.error('Çalışan eklenirken hata oluştu:', error);
        }
      },
      editWorker(worker) {
        this.selectedWorker = { ...worker, studio_id: worker.studio_id };
        this.isEdit = true;
      },
      async updateWorker() {
        try {
          await axios.put(`/admin/refika/studioworkers/${this.selectedWorker.studioworker_id}`, this.selectedWorker);
          this.fetchWorkers();
          this.resetForm();
        } catch (error) {
          console.error('Çalışan güncellenirken hata oluştu:', error);
        }
      },
      async deleteWorker(id) {
        try {
          await axios.delete(`/admin/refika/studioworkers/${id}`);
          this.fetchWorkers();
        } catch (error) {
          console.error('Çalışan silinirken hata oluştu:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu çalışanı silmek istediğinizden emin misiniz?')) {
          this.deleteWorker(id);
        }
      },
      resetForm() {
        this.selectedWorker = {
          studioworker_id: null,
          studio_id: '',
          name: '',
          password: '',
          phone: '',
          e_mail: '',
          started_at: '',
          ended_at: '',
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchStudios();
      this.fetchWorkers();
    },
  };
  </script> 