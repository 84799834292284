<template>
    <div class="sysnote-manager">
      <h3>Sistem Notları ve Bilgileri</h3> 
      <p>Bu bilgilerin içerisine şifre yerleştirmeyin</p>
      <div class="note-grid">
        <div v-for="note in notes" :key="note.id" class="note-card">
          <h4>{{ note.title }}</h4>
          <p class="description">{{ note.description }}</p>
          <p class="details">{{ note.more_details }}</p>
          <div class="card-actions">
            <button @click="editNote(note)" class="edit-btn">Düzenle</button>
            <button @click="confirmDelete(note.id)" class="delete-btn">Sil</button>
          </div>
        </div>
      </div>
      <form @submit.prevent="isEdit ? updateNote() : createNote()" class="note-form">
        <input v-model="selectedNote.title" placeholder="Başlık" required />
        <textarea v-model="selectedNote.description" placeholder="Açıklama"></textarea>
        <textarea v-model="selectedNote.more_details" placeholder="Detaylı Açıklama"></textarea>
        <div class="button-group">
          <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
          <button v-if="isEdit" @click="cancelEdit" type="button" class="cancel-btn">İptal</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        notes: [],
        selectedNote: {
          id: null,
          title: '',
          description: '',
          more_details: ''
        },
        isEdit: false
      };
    },
    methods: {
      async fetchNotes() {
        const response = await axios.get('/admin/sysnotes');
        this.notes = response.data.notes;
      },
      async createNote() {
        await axios.post('/admin/sysnotes', {
          title: this.selectedNote.title,
          description: this.selectedNote.description,
          more_details: this.selectedNote.more_details,
        });
        this.fetchNotes();
        this.resetForm();
      },
      editNote(note) {
        this.selectedNote = { ...note };
        this.isEdit = true;
      },
      async updateNote() {
        await axios.put(`/admin/sysnotes/${this.selectedNote.id}`, {
          title: this.selectedNote.title,
          description: this.selectedNote.description,
          more_details: this.selectedNote.more_details,
        });
        this.fetchNotes();
        this.resetForm();
      },
      async deleteNote(id) {
        await axios.delete(`/admin/sysnotes/${id}`);
        this.fetchNotes();
      },
      confirmDelete(id) {
        if (confirm('Bu notu silmek istediğinizden emin misiniz?')) {
          this.deleteNote(id);
        }
      },
      resetForm() {
        this.selectedNote = {
          id: null,
          title: '',
          description: '',
          more_details: ''
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      }
    },
    mounted() {
      this.fetchNotes();
    }
  };
  </script>
  
  <style scoped>
  .sysnote-manager {
    background: #050505;
    padding: 20px;
    border-radius: 10px; 
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sysnote-manager h3 {
    font-size: 24px;
    margin-bottom: 20px;
  } 
  
  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .button-group button {
    flex: 1;
    padding: 10px; 
    border-radius: 5px; 
  }
  
  .cancel-btn {
    background: #9b0101;
  }
  
  .note-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    margin-top: 20px;
  }
  
  .note-card {
    background: #0a0a0a;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease;
  }
  
  .note-card:hover {
    background: #0b0b0b;
  }
  
  .note-card h4 { 
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 14px; 
    margin-bottom: 5px;
  }
  
  .details {
    font-size: 12px;
    color: #aaa;
  }
  
  .card-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .edit-btn,
  .delete-btn { 
    padding: 8px;
    border: none; 
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .edit-btn {
    background: #106e35;
  }
  
  .delete-btn {
    background: #9b0101;
  }
  </style> 