<template>
  <div> 
    <div class="sub-button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import EmailAnalytics from './EmailAnalytics.vue';
import EmailCampaignManager from './EmailCampaignManager.vue';
import EmailCategoryManager from './EmailCategoryManager.vue';
import EmailLog from './EmailLog.vue';
import EmailPreferenceManager from './EmailPreferenceManager.vue';
import EmailRecipientManager from './EmailRecipientManager.vue';
import EmailTemplateManager from './EmailTemplateManager.vue';
import GeneralServices from './GeneralServices.vue'; 

export default {
  data() {
    return {
      currentComponent: 'GeneralServices',
      components: [
        { name: 'Genel Servisler', component: 'GeneralServices' }, 
        { name: 'E-Mail Kategorileri', component: 'EmailCategoryManager' }, 
        { name: 'E-Mail Şablonları', component: 'EmailTemplateManager' },
        { name: 'Kampanyalar', component: 'EmailCampaignManager' },
        { name: 'E-Mail Alıcıları', component: 'EmailRecipientManager' },
        { name: 'E-Mail Seçenekleri', component: 'EmailPreferenceManager' },
        { name: 'Anlytics', component: 'EmailAnalytics' },
        { name: 'E-Mail Logları', component: 'EmailLog' },
      ]
    };
  },

  components: { 
    GeneralServices,
    EmailCategoryManager,
    EmailTemplateManager,
    EmailCampaignManager,
    EmailRecipientManager,
    EmailPreferenceManager,
    EmailAnalytics,
    EmailLog
  }
};
</script>  
