<template>
  <div class="current-stocks">
    <h3>Güncel Ürün Stokları</h3>
    <table class="table">
      <thead>
        <tr>
          <th>Ürün Seri No</th>
          <th>Ürün Adı</th>
          <th>Stok Miktarı</th>
          <th>Durum/Satışta</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.product_id">
          <td>{{ product.product_serial }}</td>
          <td>{{ product.product_name }}</td>
          <td>{{ product.stock_amount }}</td>
          <td>
            <span :class="getStatusClass(product.status)">
              {{ product.status }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CurrentStocks",
  data() {
    return {
      products: [],
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get("/admin/products");
        this.products = response.data;
      } catch (error) {
        console.error("Ürünler alınırken hata:", error);
      }
    },
    getStatusClass(status) {
      return {
        "status-active": status === "Aktif",
        "status-inactive": status === "İnaktif",
      };
    },
  },
  mounted() {
    this.fetchProducts();
  },
};
</script>

<style scoped>
.current-stocks {
  padding: 20px;
} 

.status-active {
  color: white;
  background-color: green;
  padding: 5px 10px;
  border-radius: 4px;
}

.status-inactive {
  color: white;
  background-color: red;
  padding: 5px 10px;
  border-radius: 4px;
}
</style>
