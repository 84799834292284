<template>
    <div>
      <h3>Uygulama/İşlem Yönetimi</h3>
  
      <div>NOT-PARABIRIMLERI PROMO İLE BİRLEŞMELİ</div>
      <!-- Ürün Formu -->
      <h4>{{ isEditProduct ? "Ürünü Düzenle" : "Yeni Ürün Ekle" }}</h4>
      <form @submit.prevent="isEditProduct ? updateProduct() : createProduct()">
        <input v-model="selectedProduct.name" placeholder="Ürün Adı" required />
        <textarea v-model="selectedProduct.description" placeholder="Açıklama"></textarea>
        <textarea v-model="selectedProduct.details" placeholder="Detaylar"></textarea>
        <button type="submit">{{ isEditProduct ? "Güncelle" : "Kaydet" }}</button>
        <button v-if="isEditProduct" @click="resetProductForm" type="button">İptal</button>
      </form>
  
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Ad</th>
            <th>Açıklama</th>
            <th>Detaylar</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.product_id">
            <td>{{ product.product_id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.details }}</td>
            <td><button @click="editProduct(product)">Düzenle</button></td>
            <td><button @click="deleteProduct(product.product_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>
  
      <hr />
  
      <!-- Fiyat Formu -->
      <h3>Uygulama/İşlem Fiyat Yönetimi</h3>
      <h4>{{ isEditPrice ? "Fiyatı Düzenle" : "Yeni Fiyat Ekle" }}</h4>
      <form @submit.prevent="isEditPrice ? updatePrice() : createPrice()">
        <label>Ürün Seç</label>
        <select v-model="selectedPrice.product_id" required>
          <option disabled value="">Ürün seçiniz</option>
          <option v-for="product in products" :key="product.product_id" :value="product.product_id">
            {{ product.name }}
          </option>
        </select>
        <input v-model="selectedPrice.currency_id" placeholder="Para Birimi ID" required />
        <input v-model="selectedPrice.amount" type="number" step="0.01" placeholder="Tutar" required />
        <input v-model="selectedPrice.discount_amount" type="number" step="0.01" placeholder="İndirim Tutarı" />
        <button type="submit">{{ isEditPrice ? "Güncelle" : "Kaydet" }}</button>
        <button v-if="isEditPrice" @click="resetPriceForm" type="button">İptal</button>
      </form>
  
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Ürün</th>
            <th>Para Birimi</th>
            <th>Tutar</th>
            <th>İndirim</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="price in prices" :key="price.productprice_id">
            <td>{{ price.productprice_id }}</td>
            <td>{{ price.product ? price.product.name : '' }}</td>
            <td>{{ price.currency_id }}</td>
            <td>{{ price.amount }}</td>
            <td>{{ price.discount_amount }}</td>
            <td><button @click="editPrice(price)">Düzenle</button></td>
            <td><button @click="deletePrice(price.productprice_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        products: [],
        prices: [],
        selectedProduct: { product_id: null, name: '', description: '', details: '' },
        selectedPrice: { productprice_id: null, product_id: '', currency_id: '', amount: '', discount_amount: 0 },
        isEditProduct: false,
        isEditPrice: false,
      };
    },
    methods: {
      async fetchProducts() {
        const res = await axios.get('/admin/refika/products');
        this.products = res.data.products;
      },
      async fetchPrices() {
        const res = await axios.get('/admin/refika/productprices');
        this.prices = res.data.prices;
      },
      async createProduct() {
        await axios.post('/admin/refika/products', this.selectedProduct);
        this.fetchProducts();
        this.resetProductForm();
      },
      editProduct(product) {
        this.selectedProduct = { ...product };
        this.isEditProduct = true;
      },
      async updateProduct() {
        await axios.put(`/admin/refika/products/${this.selectedProduct.product_id}`, this.selectedProduct);
        this.fetchProducts();
        this.resetProductForm();
      },
      async deleteProduct(id) {
        await axios.delete(`/admin/refika/products/${id}`);
        this.fetchProducts();
      },
      resetProductForm() {
        this.selectedProduct = { product_id: null, name: '', description: '', details: '' };
        this.isEditProduct = false;
      },
  
      async createPrice() {
        await axios.post('/admin/refika/productprices', this.selectedPrice);
        this.fetchPrices();
        this.resetPriceForm();
      },
      editPrice(price) {
        this.selectedPrice = { ...price, product_id: price.product_id };
        this.isEditPrice = true;
      },
      async updatePrice() {
        await axios.put(`/admin/refika/productprices/${this.selectedPrice.productprice_id}`, this.selectedPrice);
        this.fetchPrices();
        this.resetPriceForm();
      },
      async deletePrice(id) {
        await axios.delete(`/admin/refika/productprices/${id}`);
        this.fetchPrices();
      },
      resetPriceForm() {
        this.selectedPrice = { productprice_id: null, product_id: '', currency_id: '', amount: '', discount_amount: 0 };
        this.isEditPrice = false;
      },
    },
    mounted() {
      this.fetchProducts();
      this.fetchPrices();
    },
  };
  </script>
  