<template>
  <div class="header"> 
      <img src="../assets/noah-logo.svg"  class="logo" @click="goHome" />
      <img src="../assets/home-icon.svg" class="home" @click="goHome" />
  </div>
</template>

<script>
export default {
  name: 'MainHeader',
  methods: {
    goHome() {
      this.$router.push('/administration');
    }
  }
}
</script>

<style scoped>
.header {  
  padding-top: 10px;
  display: flex;
  justify-content: space-between; 
  width: 100%;
  transition: background-color 0.3s, padding 0.3s;
  z-index: 1000;
}

.logo {
  max-width: 150px; 
  margin-left: 10px; 
  cursor: pointer;  
  z-index: 2;
  opacity: 1;
  filter: invert(75%);
}  
.home {
  max-width: 3.5rem; 
  margin-right: 5px; 
  cursor: pointer;  
  z-index: 2;
  opacity: 0.8;
} 
.home:hover { 
  opacity: 1; 
} 

@media (max-width: 600px) {
  .header { 
    padding: 5px;
    padding-top: 20px;
    display: flex;
    
  }
  .logo {
    max-width: 130px;  
  }
}
</style>
