<template>
    <div class="studio-agreement-management">
      <h3>Stüdyo Sözleşme Yönetimi</h3>
   
      <div>DOSYA YÖNETİM MODULU EKSİK</div>
  
      <h4>Tüm Sözleşmeler</h4>
      <table class="agreement-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Stüdyo</th>
            <th>Tarih</th>
            <th>Link</th>
            <th>Aktif</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="agreement in agreements" :key="agreement.studioagreement_id">
            <td>{{ agreement.studioagreement_id }}</td>
            <td>{{ agreement.studio ? agreement.studio.name : '' }}</td>
            <td>{{ agreement.date }}</td>
            <td><a :href="agreement.link" target="_blank">Görüntüle</a></td>
            <td>{{ agreement.is_active ? "Evet" : "Hayır" }}</td>
            <td><button @click="editAgreement(agreement)">Düzenle</button></td>
            <td><button @click="confirmDelete(agreement.studioagreement_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>


      <h4>{{ isEdit ? "Sözleşmeyi Düzenle" : "Yeni Sözleşme Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateAgreement() : createAgreement()">
        <label>Stüdyo Seç</label>
        <select v-model="selectedAgreement.studio_id" required>
          <option disabled value="">Lütfen bir stüdyo seçin</option>
          <option v-for="studio in studios" :key="studio.studio_id" :value="studio.studio_id">
            {{ studio.name }}
          </option>
        </select>
  
        <input v-model="selectedAgreement.date" type="date" placeholder="Tarih" required />
        <input v-model="selectedAgreement.link" type="url" placeholder="Sözleşme Linki" required />
        <label>
          <input type="checkbox" v-model="selectedAgreement.is_active" /> Aktif
        </label>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Kaydet" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        agreements: [],
        studios: [],
        selectedAgreement: {
          studioagreement_id: null,
          studio_id: '',
          date: '',
          link: '',
          is_active: true,
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchStudios() {
        try {
          const response = await axios.get('/admin/refika/studios');
          this.studios = response.data.studios;
        } catch (error) {
          console.error('Stüdyolar alınırken hata oluştu:', error);
        }
      },
      async fetchAgreements() {
        try {
          const response = await axios.get('/admin/refika/studioagreements');
          this.agreements = response.data.agreements;
        } catch (error) {
          console.error('Sözleşmeler alınırken hata oluştu:', error);
        }
      },
      async createAgreement() {
        try {
          await axios.post('/admin/refika/studioagreements', this.selectedAgreement);
          this.fetchAgreements();
          this.resetForm();
        } catch (error) {
          console.error('Sözleşme eklenirken hata oluştu:', error);
        }
      },
      editAgreement(agreement) {
        this.selectedAgreement = { ...agreement, studio_id: agreement.studio_id };
        this.isEdit = true;
      },
      async updateAgreement() {
        try {
          await axios.put(`/admin/refika/studioagreements/${this.selectedAgreement.studioagreement_id}`, this.selectedAgreement);
          this.fetchAgreements();
          this.resetForm();
        } catch (error) {
          console.error('Sözleşme güncellenirken hata oluştu:', error);
        }
      },
      async deleteAgreement(id) {
        try {
          await axios.delete(`/admin/refika/studioagreements/${id}`);
          this.fetchAgreements();
        } catch (error) {
          console.error('Sözleşme silinirken hata oluştu:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu sözleşmeyi silmek istediğinizden emin misiniz?')) {
          this.deleteAgreement(id);
        }
      },
      resetForm() {
        this.selectedAgreement = {
          studioagreement_id: null,
          studio_id: '',
          date: '',
          link: '',
          is_active: true,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchStudios();
      this.fetchAgreements();
    },
  };
  </script>
  