<template>
  <div>
    <h3>Geri Bildirimler</h3>

    <div class="feedbacks-container"> 
      <div v-if="!isLoading" class="feedbacks-list"> 
        <div class="toggle-delete">
          <input type="checkbox" v-model="showDeleteButtons" /> Silme butonlarını göster
        </div>

        <table>
          <thead>
            <tr>
              <th>Kullanıcı ID</th>
              <th>Başlık</th>
              <th>Detay</th>
              <th>Link</th>
              <th>Oluşturulma Tarihi</th>
              <th v-if="showDeleteButtons">Aksiyonlar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="feedback in feedbacks" :key="feedback.id" @click="openEditFeedbackModal(feedback)">
              <td>{{ feedback.user_id || 'N/A' }}</td>
              <td>{{ feedback.title }}</td>
              <td>{{ feedback.details }}</td>
              <td>{{ feedback.link || 'N/A' }}</td>
              <td>{{ feedback.created_at }}</td>
              <td v-if="showDeleteButtons">
                <button @click.stop="confirmDelete(feedback.id)">Sil</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!isLoading && feedbacks.length === 0" class="no-feedbacks">
        <p>Henüz geri bildirim bulunmamaktadır.</p>
      </div>
    </div>
    
    <div class="add-feedback">
      <button @click="openAddFeedbackModal">Yeni Geri Bildirim Ekle</button>
    </div> 

    <!-- Düzenleme Modalı -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h2>{{ currentFeedback.id ? 'Geri Bildirim Düzenle' : 'Yeni Geri Bildirim Ekle' }}</h2>
        <form @submit.prevent="submitFeedback">
          <input v-model="currentFeedback.title" type="text" placeholder="Başlık" required />
          <textarea v-model="currentFeedback.details" placeholder="Detay" required></textarea>
          <input v-model="currentFeedback.link" type="url" placeholder="Link (Opsiyonel)" />
          <input v-model="currentFeedback.user_id" type="number" placeholder="Kullanıcı ID (Opsiyonel)" />
          <button type="submit">{{ currentFeedback.id ? 'Güncelle' : 'Gönder' }}</button>
        </form>
        <button @click="closeModal">Kapat</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      feedbacks: [],
      showDeleteButtons: false, 
      currentFeedback: {
        id: null,
        user_id: null,
        title: '',
        details: '',
        link: '',
        created_at: '',
      },
      isLoading: true,
      showModal: false,  
    };
  },
  methods: {
    async fetchFeedbacks() {
      try {
        const response = await axios.get('/admin/feedbacks');
        this.feedbacks = response.data.data;
      } catch (error) {
        console.error('Geri bildirimler alınırken hata oluştu:', error);
      } finally {
        this.isLoading = false;
      }
    },
 
    openEditFeedbackModal(feedback) {
      this.currentFeedback = { ...feedback }; 
      this.showModal = true;
    },

    openAddFeedbackModal() {
      this.currentFeedback = { id: null, user_id: null, title: '', details: '', link: '', created_at: '' };
      this.showModal = true;  
    },
 
    closeModal() {
      this.showModal = false;
    },
 
    async submitFeedback() {
      try {
        if (this.currentFeedback.id) { 
          const response = await axios.put(`/admin/feedbacks/${this.currentFeedback.id}`, this.currentFeedback);
          const index = this.feedbacks.findIndex(feedback => feedback.id === this.currentFeedback.id);
          this.feedbacks[index] = response.data.data;  
          alert('Geri bildirim başarıyla güncellendi!');
        } else { 
          const response = await axios.post('/admin/feedbacks', this.currentFeedback);
          this.feedbacks.push(response.data.data);  
          alert('Geri bildirim başarıyla eklendi!');
        }
        this.closeModal();
      } catch (error) {
        console.error('Geri bildirim işlemi yapılırken hata oluştu:', error);
        alert('Bir hata oluştu.');
      }
    },

    confirmDelete(id) {
      if (confirm('Bu geri bildirimi silmek istediğinizden emin misiniz?')) {
        this.deleteFeedback(id);
      }
    },

    async deleteFeedback(id) {
      try {
        await axios.delete(`/admin/feedbacks/${id}`);
        this.feedbacks = this.feedbacks.filter(feedback => feedback.id !== id);
        alert('Geri bildirim başarıyla silindi.');
      } catch (error) {
        console.error('Geri bildirim silinirken hata oluştu:', error);
        alert('Bir hata oluştu.');
      }
    },
  },
  mounted() {
    this.fetchFeedbacks();
  },
};
</script>

<style scoped> 
.feedbacks-container {
  padding: 20px;
}

.add-feedback {
  margin-top: 20px;
}

.no-feedbacks {
  padding: 20px;
  text-align: center;
  color: #888;
}
 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>
