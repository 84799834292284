<template>
  <div class="container">
    <h4>Genel Hizmetler Yöneticisi</h4>
    <div v-for="service in services" :key="service.service_id" class="service-item">
      <h4>{{ service.name }}</h4>
      <p>{{ service.details }}</p>
          <div style="display: flex;">
            <button @click="editService(service)">Düzenle</button>
            <button @click="confirmDelete(service.service_id)">Sil</button>
          </div>
    </div>
    <div class="form-area">
      <h4>{{ editingService ? 'Hizmeti Düzenle' : 'Yeni Hizmet Ekle' }}</h4>
      <form @submit.prevent="submitService">
        <input v-model="serviceForm.name" placeholder="Hizmet Adı" required />
        <textarea v-model="serviceForm.details" placeholder="Hizmet Detayları"></textarea>
        <button type="submit">Kaydet</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    services: [],
    serviceForm: {
      service_id: null,
      name: '',
      details: ''
    },
    editingService: false
  };
},
async mounted() {
  await this.fetchServices();
},
methods: {
  async fetchServices() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/generalservices', {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.services = response.data;
    } catch (error) {
      console.error('Genel hizmetler alınırken hata oluştu:', error);
    }
  },
  editService(service) {
    this.serviceForm = { ...service };
    this.editingService = true;
  },
  confirmDelete(serviceId) {
    if (confirm('Bu hizmeti silmek istediğinize emin misiniz?')) {
      this.deleteService(serviceId);
    }
  },
  async deleteService(serviceId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/generalservices/${serviceId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      await this.fetchServices();
    } catch (error) {
      console.error('Genel hizmet silinirken hata oluştu:', error);
    }
  },
  async submitService() {
    try {
      const token = localStorage.getItem('token');
      const method = this.serviceForm.service_id ? 'put' : 'post';
      const url = this.serviceForm.service_id
        ? `/admin/generalservices/${this.serviceForm.service_id}`
        : '/admin/generalservices';
      await axios[method](url, this.serviceForm, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.resetForm();
      await this.fetchServices();
    } catch (error) { 
      console.error('Genel hizmet kaydedilirken hata oluştu:', error);
    }
  },
  resetForm() {
    this.serviceForm = { service_id: null, name: '', details: '' };
    this.editingService = false;
  }
}
};
</script>

  
  <style scoped>
  .container {
    padding: 1em;
  }
  .service-item {
    background-color: #050505;
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 0.5em;
  }  
  </style>
  