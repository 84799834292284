<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <h3>Sohbet Mesajları - {{ chat_id }}</h3>
     <div style="display: flex; gap:10px;">      
      <p><strong>Sohbet ID:</strong> {{ chat_id }}</p>
      <p><strong>GPT ID:</strong> {{ gptId }}</p>
      <p><strong>Sohbet Adı:</strong> {{ chatName }}</p>
      <p><strong>Arşiv Durumu:</strong> {{ isArchived ? 'Arşivli' : 'Arşivli Değil' }}</p>
      <p><strong>Durum:</strong> {{ isDeleted ? 'Silinmiş' : 'Aktif' }}</p>
    </div>
      <hr />
      <h4>Mesajlar</h4>
      <div class="chat-window">
        <div 
          class="message" 
          v-for="message in messages" 
          :key="message.message_id"
          :style="{ backgroundColor: message.role === 'assistant' ? '#001a20' : '#00200d' }"
        >
          <p>{{ message.role }}:</p>
          <p>{{ message.message_text }}</p>
          <p><em style="font-size: 0.8em;"> {{ formatDateTime(message.created_at) }} </em></p>
        </div>
      </div>
      <button @click="closeModal">Kapat</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserChatModal",
  props: {
    chat_id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      chatName: "",
      gptId: null,
      isArchived: false,
      isDeleted: false,
      messages: []
    };
  },
  methods: {
    fetchChatDetails() {
      axios.get(`/admin/noahai/chats/${this.chat_id}`)
        .then(response => {
          const chat = response.data.chat;
          this.chatName = chat.chat_name;
          this.gptId = chat.gpt_id;
          this.isArchived = chat.is_archived;
          this.isDeleted = chat.is_deleted;
        })
        .catch(error => {
          console.error("Sohbet detayları alınırken hata:", error);
        });
    },
    fetchMessages() {
      axios.get(`/admin/noahai/chats/${this.chat_id}/messages`)
        .then(response => {
          this.messages = response.data.messages;
        })
        .catch(error => {
          console.error("Mesajlar alınırken hata:", error);
        });
    },
    closeModal() {
      this.$emit("close");
    }
  },
  created() {
    this.fetchChatDetails();
    this.fetchMessages();
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content { 
  background-color: #0a0a0a;
  padding: 20px;
  border-radius: 8px;
  width: 980px;
}
button {
  margin-top: 10px;
  padding: 8px 16px; 
  border: none;
  cursor: pointer;
}
.chat-window { max-height: 20rem; overflow: auto;} 
.message {
  background-color: #0a0a0a;
  margin: 5px 0;
  padding: 5px 1em;
  border-radius: 1em;
}
</style>
