<template>
    <div class="modal-overlay">
      <div class="modal">
        <h4>Ürün Kategorisi</h4> 
        <h3>{{ productName }}</h3>
  
        <div v-for="category in categories" :key="category.category_id" class="category-group">
          <label 
            class="category-item"
            :class="{ 'success': category.status === 'success', 'error': category.status === 'error' }"
          >
            <input
              type="checkbox"
              :value="category.category_id"
              v-model="selectedCategories"
              @change="handleCategoryChange(category.category_id)"
            />
            {{ category.category_name }}
          </label>
  
          <div 
            v-for="subcategory in category.subCategories" 
            :key="subcategory.subcategory_id" 
            class="subcategory-group"
          >
            <label 
              class="subcategory-item"
              :class="{ 'success': subcategory.status === 'success', 'error': subcategory.status === 'error' }"
            >
              <input
                type="checkbox"
                :value="subcategory.subcategory_id"
                v-model="selectedSubcategories"
                @change="handleSubcategoryChange(subcategory.subcategory_id)"
              />
              {{ subcategory.sub_category_name }}
            </label>
          </div>
        </div>
  
        <button class="delete" @click="$emit('close')">Kapat</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    props: {
      productId: Number,
      productName: String,
    },
    data() {
      return {
        categories: [],
        selectedCategories: [],
        selectedSubcategories: [],
      };
    },
    async created() {
      await this.fetchCategories();
      await this.fetchProductCategories();
    },
    methods: {
      async fetchCategories() {
        try {
          const [categoriesRes, subcategoriesRes] = await Promise.all([
            axios.get("/admin/categories"),
            axios.get("/admin/subcategories"),
          ]);
  
          this.categories = categoriesRes.data.map(category => ({
            ...category,
            status: 'neutral',
            subCategories: subcategoriesRes.data
              .filter(sub => sub.category_id === category.category_id)
              .map(sub => ({ ...sub, status: 'neutral' }))
          }));
          
        } catch (error) {
          console.error("Kategoriler yüklenirken hata:", error);
          alert("Kategoriler yüklenemedi!");
        }
      },
  
      async fetchProductCategories() {
        try {
          const response = await axios.get(`/admin/productcategoriesbyproductid/${this.productId}`);
          
          this.selectedCategories = response.data
            .filter(pc => pc.category_id)
            .map(pc => pc.category_id);
  
          this.selectedSubcategories = response.data
            .filter(pc => pc.subcategory_id)
            .map(pc => pc.subcategory_id);
  
        } catch (error) {
          if(error.response?.status === 404) {
            this.selectedCategories = [];
            this.selectedSubcategories = [];
          } else {
            alert("Kategoriler yüklenemedi!");
          }
        }
      },
  
      async handleCategoryChange(categoryId) {
        try {
          if(this.selectedCategories.includes(categoryId)) {
            await axios.post("/admin/productcategories", {
              product_id: this.productId,
              category_id: categoryId
            });
          } else {
            const pcRes = await axios.get(`/admin/productcategoriesbyproductid/${this.productId}`);
            const toDelete = pcRes.data.find(pc => 
              pc.category_id === categoryId && !pc.subcategory_id
            );
            if(toDelete) {
              await axios.delete(`/admin/productcategories/${toDelete.productcategory_id}`);
            }
          }
  
          const category = this.categories.find(c => c.category_id === categoryId);
          if(category) {
            category.status = 'success';
            setTimeout(() => category.status = 'neutral', 2000);
          }
        } catch (error) {
          console.error("Kategori işlemi hatası:", error);
          const category = this.categories.find(c => c.category_id === categoryId);
          if(category) {
            category.status = 'error';
            setTimeout(() => category.status = 'neutral', 2000);
          }
          alert("İşlem başarısız!");
        }
      },
  
      async handleSubcategoryChange(subcategoryId) {
        try {
          if(this.selectedSubcategories.includes(subcategoryId)) {
            await axios.post("/admin/productcategories", {
              product_id: this.productId,
              subcategory_id: subcategoryId
            });
          } else {
            const pcRes = await axios.get(`/admin/productcategoriesbyproductid/${this.productId}`);
            const toDelete = pcRes.data.find(pc => 
              pc.subcategory_id === subcategoryId
            );
            if(toDelete) {
              await axios.delete(`/admin/productcategories/${toDelete.productcategory_id}`);
            }
          }
  
          let targetSub = null;
          for(const category of this.categories) {
            targetSub = category.subCategories.find(sc => sc.subcategory_id === subcategoryId);
            if(targetSub) {
              targetSub.status = 'success';
              setTimeout(() => targetSub.status = 'neutral', 2000);
              break;
            }
          }
        } catch (error) {
          console.error("Alt kategori işlemi hatası:", error);
          for(const category of this.categories) {
            const targetSub = category.subCategories.find(sc => sc.subcategory_id === subcategoryId);
            if(targetSub) {
              targetSub.status = 'error';
              setTimeout(() => targetSub.status = 'neutral', 2000);
              break;
            }
          }
          alert("İşlem başarısız!");
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
  }
  
  .category-group { 
    margin: 1rem 0;
  }
  
  .category-item { 
    font-size: 1.2em;
    display: block;
  }
  
  .subcategory-item { 
    margin-left: 1em;
    display: block;
  }
  
  .success {
    color: #28a745 !important;
  }
  
  .error {
    color: #dc3545 !important;
  }
  </style>