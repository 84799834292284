<template>
  <div> 
    <div class="head">GENEL SISTEM YONETIMI</div>
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>


<script>   
import ActivityLog from './ActivityLog.vue';
import CurrencyManager from './currency/CurrencyManager.vue';
import LanguageManager from './language/LanguageManager.vue';
import MessageMain from './messages/MessageMain.vue';
import UserMain from './users/UserMain.vue';
import WebManager from './WebManager.vue';
import EmailMain from './email/EmailMain.vue';
import ShopWebsiteManager from './ShopWebsiteManager.vue';
import SysNoteManager from './SysNoteManager.vue';

export default {
  data() {
    return {
      currentComponent: 'WebManager', 
      components: [
        { name: 'Web Yönetimi', component: 'WebManager' }, 
        { name: 'Shop Websiteleri', component: 'ShopWebsiteManager' },
        { name: 'Kullanıcı Yönetimi', component: 'UserMain' },  
        { name: 'Mesaj Sistemi', component: 'MessageMain' }, 
        { name: 'E-posta Sistemi / CRM', component: 'EmailMain' }, 
        { name: 'Dil Yönetimi', component: 'LanguageManager' },
        { name: 'Para Birimleri', component: 'CurrencyManager' },
        { name: 'Sistem Notları', component: 'SysNoteManager' }, 
        { name: 'Tüm Aktiviteler', component: 'ActivityLog' }, 
      ]
    };
  },

  components: { 
    UserMain,
    WebManager,
    ActivityLog,
    MessageMain,
    LanguageManager,
    CurrencyManager,
    EmailMain,
    ShopWebsiteManager,
    SysNoteManager
  },
  
};
</script>
  
<style scoped>
.button-container {
    align-items: center;
    justify-content: center;
    padding: 1em;
    background-color: black;
    } 
    .head {
    text-align: center;
    font-size: 2em; font-weight: 300;
    } 

</style>
  