<template>
    <div class="currency-management">
        <div class="alert">Lütfen silmeyin</div>
      <div class="table-header">
        <h3>Tüm Para Birimleri</h3>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table>
        <thead>
          <tr>
            <th>Para Birimi Adı</th>
            <th>Sembol</th>
            <th>Güncelle</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="currency in currencies" :key="currency.currency_id">
            <td>{{ currency.currency_name }}</td>
            <td>{{ currency.currency_symbol }}</td>
            <td>
              <button @click="editCurrency(currency)">Güncelle</button>
              <button class="deletebutton" v-if="showDeleteButtons" @click="confirmDelete(currency.currency_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <form @submit.prevent="isEdit ? updateCurrency() : createCurrency()">
        <h4>{{ isEdit ? "Para Birimini Düzenle" : "Yeni Para Birimi Ekle" }}</h4>
        <input v-model="selectedCurrency.currency_name" placeholder="Para Birimi Adı" required />
        <input v-model="selectedCurrency.currency_symbol" placeholder="Sembol" required />
  
        <div class="formbutton">
          <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
          <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        currencies: [],
        showDeleteButtons: false,
        selectedCurrency: {
          currency_id: null,
          currency_name: "",
          currency_symbol: "",
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchCurrencies() {
        try {
          const response = await axios.get("/admin/currencies");
          this.currencies = response.data;
        } catch (error) {
          console.error("Para birimleri alınırken hata oluştu:", error);
        }
      },
      async createCurrency() {
        try {
          await axios.post("/admin/currencies", this.selectedCurrency);
          this.fetchCurrencies();
          this.resetForm();
        } catch (error) {
          console.error("Para birimi eklenirken hata oluştu:", error);
        }
      },
      editCurrency(currency) {
        this.selectedCurrency = { ...currency };
        this.isEdit = true;
      },
      async updateCurrency() {
        try {
          await axios.put(`/admin/currencies/${this.selectedCurrency.currency_id}`, this.selectedCurrency);
          this.fetchCurrencies();
          this.resetForm();
        } catch (error) {
          console.error("Para birimi güncellenirken hata oluştu:", error);
        }
      },
      async deleteCurrency(currency_id) {
        try {
          await axios.delete(`/admin/currencies/${currency_id}`);
          this.fetchCurrencies();
        } catch (error) {
          console.error("Para birimi silinirken hata oluştu:", error);
        }
      },
      confirmDelete(currency_id) {
        if (confirm("Bu para birimini silmek istediğinize emin misiniz?")) {
          this.deleteCurrency(currency_id);
        }
      },
      resetForm() {
        this.selectedCurrency = {
          currency_id: null,
          currency_name: "",
          currency_symbol: "",
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchCurrencies();
    },
  };
  </script>
  
  <style scoped> 
  </style>
  