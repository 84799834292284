<template>
    <div class="add-activity">
      <h2>Yeni Aktivite Ekle</h2>
      <input v-model="user_id" type="number" placeholder="User ID" />
      <textarea v-model="activity" placeholder="Aktivite detaylarını girin"></textarea>
      <button class="confirm" @click="createActivity">Ekle</button>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        user_id: "",
        activity: "",
      };
    },
    methods: {
      async createActivity() {
        if (!this.user_id || !this.activity) {
          alert("Lütfen tüm alanları doldurun.");
          return;
        }
        try {
          await axios.post("/admin/useractivities", { user_id: this.user_id, activity: this.activity });
          alert("Aktivite başarıyla eklendi!");
          this.user_id = "";
          this.activity = "";
        } catch (error) {
          console.error("Aktivite eklenirken hata oluştu:", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .add-activity {
    padding: 20px;
    max-width: 500px;
    margin: auto;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
  }
  button {
    padding: 8px 12px;
    cursor: pointer;
  }
  </style>
  