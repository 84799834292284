<template>
  <div class="container">
    <h4>Email Kayıtları</h4>
 
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Alıcı</th>
          <th>Kullanıcı</th>
          <th>Durum</th>
          <th>Hata Mesajı</th>
          <th>Oluşturulma Tarihi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in logs" :key="log.log_id">
          <td>{{ log.log_id }}</td>
          <td>{{ log.recipient?.recipient_id || 'N/A' }}</td>
          <td>{{ log.user?.user_name || 'N/A' }}</td>
          <td>{{ log.status }}</td>
          <td>{{ log.error_message || 'Yok' }}</td>
          <td>{{ log.created_at }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    logs: [],
  };
},
async mounted() {
  await this.fetchLogs(); 
},
methods: {
  async fetchLogs() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emaillogs', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.logs = response.data;
    } catch (error) {
      console.error('E-posta kayıtları alınırken hata oluştu:', error);
    }
  },
},
};
</script>
 