<template>
    <div>
      <h3>Stüdyo - Ürün Eşleşmeleri</h3>
      <form @submit.prevent="isEdit ? updateStudioProduct() : createStudioProduct()">
        <label>Stüdyo Seç</label>
        <select v-model="selected.studio_id" required>
          <option disabled value="">Seçiniz</option>
          <option v-for="studio in studios" :key="studio.studio_id" :value="studio.studio_id">{{ studio.name }}</option>
        </select>
  
        <label>Ürün Seç</label>
        <select v-model="selected.product_id" required>
          <option disabled value="">Seçiniz</option>
          <option v-for="product in products" :key="product.product_id" :value="product.product_id">{{ product.name }}</option>
        </select>
  
        <textarea v-model="selected.notes" placeholder="Notlar"></textarea>
        <label><input type="checkbox" v-model="selected.is_active" /> Aktif</label>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="resetForm" type="button">İptal</button>
      </form>
  
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Stüdyo</th>
            <th>Ürün</th>
            <th>Not</th>
            <th>Aktif</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sp in studioProducts" :key="sp.studioproduct_id">
            <td>{{ sp.studioproduct_id }}</td>
            <td>{{ sp.studio ? sp.studio.name : '' }}</td>
            <td>{{ sp.product ? sp.product.name : '' }}</td>
            <td>{{ sp.notes }}</td>
            <td>{{ sp.is_active ? "Evet" : "Hayır" }}</td>
            <td><button @click="editStudioProduct(sp)">Düzenle</button></td>
            <td><button @click="deleteStudioProduct(sp.studioproduct_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        studioProducts: [],
        studios: [],
        products: [],
        selected: {
          studioproduct_id: null,
          studio_id: '',
          product_id: '',
          notes: '',
          is_active: true,
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchStudios() {
        const res = await axios.get('/admin/refika/studios');
        this.studios = res.data.studios;
      },
      async fetchProducts() {
        const res = await axios.get('/admin/refika/products');
        this.products = res.data.products;
      },
      async fetchStudioProducts() {
        const res = await axios.get('/admin/refika/studioproducts');
        this.studioProducts = res.data.studioProducts;
      },
      async createStudioProduct() {
        await axios.post('/admin/refika/studioproducts', this.selected);
        this.fetchStudioProducts();
        this.resetForm();
      },
      editStudioProduct(record) {
        this.selected = { ...record, studio_id: record.studio_id, product_id: record.product_id };
        this.isEdit = true;
      },
      async updateStudioProduct() {
        await axios.put(`/admin/refika/studioproducts/${this.selected.studioproduct_id}`, this.selected);
        this.fetchStudioProducts();
        this.resetForm();
      },
      async deleteStudioProduct(id) {
        await axios.delete(`/admin/refika/studioproducts/${id}`);
        this.fetchStudioProducts();
      },
      resetForm() {
        this.selected = { studioproduct_id: null, studio_id: '', product_id: '', notes: '', is_active: true };
        this.isEdit = false;
      }
    },
    mounted() {
      this.fetchStudios();
      this.fetchProducts();
      this.fetchStudioProducts();
    },
  };
  </script>
  