<template>
  <div> 
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import SupportDesk from './SupportDesk.vue';   
import OldRecords from './OldRecords.vue';
 
export default {
  data() {
    return {
      currentComponent: 'SupportDesk',
      components: [
        { name: 'Destek Kayıtları', component: 'SupportDesk' },  
        { name: 'Eski Destek Kayıtları', component: 'OldRecords' },
      ]
    };
  },

  components: {
    SupportDesk,  
    OldRecords
  }
};
</script>

<style scoped>
.button-container {
margin: 2em 0.2em 0.6em 0.2em;
text-align: center;
display: flex;
justify-content: center;
flex-wrap: wrap;
}
 
</style>
