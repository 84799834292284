<template>
    <div class="product-management">
      <div class="table-header">
        <h3>Silinmiş Ürünler</h3>
        <div class="alert"> <b>KALICI SİLMEK YASAK!</b> Ürünleri kalıcı olarak silme işlemi geri alınamaz. Muhasebe, siparişler vb. her yeri etkiler. Tüm izleri sistemden silinir. </div>
        <div>
          <label>
            <input type="checkbox" v-model="showDeleteButtons" /> Kalıcı Silme Butonlarını Göster
          </label>
        </div>
        <input type="text" v-model="searchQuery" placeholder="Ürün adı ile ara..." />
      </div>
      
      <table> 
        <thead>
          <tr>
            <th>Seri Numarası</th>
            <th>Ürün Adı</th>
            <th>Açıklama</th>
            <th>Stok Miktarı</th>
            <th>Durum</th>
            <th>Oluşturulma Tarihi</th>
            <th>Kategori</th>
            <th>Silmeyi Geri Al</th>
            <th v-if="showDeleteButtons">Kalıcı Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in filteredProducts" :key="product.product_id">
            <td>#{{ product.product_serial }}</td>
            <td>{{ product.product_name }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.stock_amount }}</td>
            <td>
              <select v-model="product.status" @change="updateProductStatus(product)">
                <option value="Aktif">Aktif</option>
                <option value="İnaktif">İnaktif</option>
              </select>
            </td>
            <td>{{ formatDateTime(product.created_at) }}</td>
            <td>
              <button @click="openCategoryModal(product)">Ürün Kategorisi</button>
            </td>
            <td>
              <button @click="restoreDeletedProduct(product.product_id)">Silmeyi Geri Al</button>
            </td>
            <td v-if="showDeleteButtons">
              <button class="deletebutton" @click="confirmHardDelete(product.product_id)">Kalıcı Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Ürün Kategori Modal -->
      <ProductCategories
        v-if="showCategoryModal"
        :productId="selectedProductForCategory.product_id"
        :productName="selectedProductForCategory.product_name"
        @close="showCategoryModal = false"
      />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import ProductCategories from "./ProductCategories.vue";  
  
  export default {
    components: {
      ProductCategories,
    },
    data() {
      return {
        products: [],
        showDeleteButtons: false,
        searchQuery: "", 
        showCategoryModal: false,
        selectedProductForCategory: {},
      };
    },
    computed: {
      filteredProducts() {
        return this.products.filter(product =>
          product.product_name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    methods: {
      async fetchDeletedProducts() {
        try {
          const response = await axios.get("/admin/deletedproducts");
          this.products = response.data;
        } catch (error) {
          console.error("Silinmiş ürünler alınırken hata oluştu:", error);
        }
      },
      openCategoryModal(product) {
        this.selectedProductForCategory = { ...product };
        this.showCategoryModal = true;
      },
      async updateProductStatus(product) {
        try {
          await axios.put(`/admin/products/${product.product_id}`, product);
          this.fetchDeletedProducts();
          alert("Ürün durumu başarıyla güncellendi.");
        } catch (error) {
          console.error("Ürün durumu güncellenirken hata oluştu:", error);
          alert("Ürün durumu güncellenirken bir hata oluştu.");
        }
      },
      async restoreDeletedProduct(product_id) {
        try {
          await axios.put(`/admin/products/softdelete/back/${product_id}`);
          this.fetchDeletedProducts();
        } catch (error) {
          console.error("Silme işlemi geri alınırken hata oluştu:", error);
        }
      },
      async hardDeleteProduct(product_id) {
        try {
          await axios.delete(`/admin/products/${product_id}`);
          this.fetchDeletedProducts();
        } catch (error) {
          console.error("Ürün kalıcı olarak silinirken hata oluştu:", error);
        }
      },
      confirmHardDelete(product_id) {
        if (confirm("Bu ürünü kalıcı olarak silmek istediğinize emin misiniz?")) {
          this.hardDeleteProduct(product_id);
        }
      }, 
    },
    mounted() {
      this.fetchDeletedProducts();
    },
  };
  </script>
  