<template>
    <div>
      <h3>Güncel Ürün Fiyatları Yönetimi</h3> 
      <div style="margin-bottom: 1rem;">
        <label for="search">Ürünlerde Ara:</label>
        <input
          id="search"
          type="text"
          v-model="searchQuery"
          placeholder="Ürün adı girin..."
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Ürün Adı</th>
            <th v-for="currency in currencies" :key="currency.currency_id">
              Fiyat ({{ currency.currency_name }})
            </th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="product in filteredProducts"
            :key="product.product_id"
            :style="editingRow === product.product_id ? { backgroundColor: '#160f01' } : {}"
          >
            <td>{{ product.product_name }}</td>
            <td v-for="currency in currencies" :key="currency.currency_id">
              <div v-if="editingRow === product.product_id">
                <div>
                  <label>Normal Fiyat:</label>
                  <input
                    v-model.number="product.prices[currency.currency_id].price"
                    type="number"
                    step="0.01"
                  />
                  <span>{{ product.prices[currency.currency_id].currency_symbol }}</span>
                </div>
                <div>
                  <label>İndirimli Fiyat:</label>
                  <input
                    v-model.number="product.prices[currency.currency_id].sale_price"
                    type="number"
                    step="0.01"
                  />
                </div>
                <div>
                  <label>İndirimli mi?</label>
                  <input
                    type="checkbox"
                    v-model="product.prices[currency.currency_id].is_in_sale"
                  />
                </div>
              </div>
              <div v-else>
                <div>
                  <strong>Normal Fiyat:</strong>
                  {{ product.prices[currency.currency_id].price.toFixed(2) }}
                  {{ product.prices[currency.currency_id].currency_symbol }}
                </div>
                <div>
                  <strong>İndirimli Fiyat:</strong>
                  {{ product.prices[currency.currency_id].sale_price !== null
                    ? product.prices[currency.currency_id].sale_price.toFixed(2)
                    : '-' }}
                </div>
                <div>
                  <strong>İndirim:</strong>
                  {{ product.prices[currency.currency_id].is_in_sale ? 'Evet' : 'Hayır' }}
                </div>
              </div>
            </td>
            <td>
              <button v-if="editingRow !== product.product_id" @click="editRow(product)">
                Güncelle
              </button>
              <div v-else>
                <button class="confirm" @click="saveProductPrice(product)">Kaydet</button>
                <button @click="cancelEdit(product)">İptal</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, onMounted, computed } from 'vue';
  
  export default {
    setup() {
      const productPrices = ref([]);
      const currencies = ref([]);
      const editingRow = ref(null);
      const searchQuery = ref('');
  
      const fetchProductPrices = async () => {
        try {
          const response = await axios.get('/admin/currentproductprices');
          const productMap = {};
  
          response.data.forEach((row) => {
            const prodId = row.product_id;
            if (!productMap[prodId]) {
              productMap[prodId] = {
                product_id: prodId,
                product_name: row.product.product_name,
                prices: {}
              };
            }
            productMap[prodId].prices[row.currency_id] = {
              productprice_id: row.productprice_id,
              price: parseFloat(row.price),
              is_in_sale: row.is_in_sale,
              sale_price: row.sale_price !== null ? parseFloat(row.sale_price) : null,
              currency_symbol: row.currency.currency_symbol
            };
          });
  
          const products = Object.values(productMap);
          products.forEach(product => {
            currencies.value.forEach(currency => {
              if (!product.prices[currency.currency_id]) {
                product.prices[currency.currency_id] = {
                  productprice_id: null,
                  price: 0.00,
                  is_in_sale: false,
                  sale_price: null,
                  currency_symbol: currency.currency_symbol
                };
              }
            });
            product.originalPrices = JSON.parse(JSON.stringify(product.prices));
          });
          productPrices.value = products;
        } catch (error) {
          console.error('Ürün fiyatları alınırken hata:', error);
        }
      };
  
      const fetchCurrencies = async () => {
        try {
          const response = await axios.get('/auth/currencies');
          currencies.value = response.data;
        } catch (error) {
          console.error('Para birimleri alınırken hata:', error);
        }
      };
  
      const editRow = (product) => {
        editingRow.value = product.product_id;
        product.originalPrices = JSON.parse(JSON.stringify(product.prices));
      };
  
      const cancelEdit = (product) => {
        product.prices = JSON.parse(JSON.stringify(product.originalPrices));
        editingRow.value = null;
      };
  
      const saveProductPrice = async (product) => {
        try {
          const updatePromises = [];
          for (const [currencyId, priceData] of Object.entries(product.prices)) {
            const original = product.originalPrices[currencyId];
            if (
              original.price === priceData.price &&
              original.is_in_sale === priceData.is_in_sale &&
              original.sale_price === priceData.sale_price
            ) {
              continue;
            }
            if (priceData.productprice_id) {
              const updateData = {
                price: priceData.price,
                is_in_sale: priceData.is_in_sale,
                sale_price: priceData.sale_price
              }; 
              updatePromises.push(
                axios.put(`/admin/productprices/${priceData.productprice_id}`, updateData)
              );
            }
          }
          await Promise.all(updatePromises);
          editingRow.value = null;
          fetchProductPrices();
        } catch (error) {
          console.error('Fiyat güncellenirken hata:', error);
        }
      };
  
      const filteredProducts = computed(() => {
        if (!searchQuery.value) {
          return productPrices.value;
        }
        return productPrices.value.filter(product =>
          product.product_name.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      });
  
      onMounted(async () => {
        await fetchCurrencies();
        await fetchProductPrices();
      });
  
      return { productPrices, currencies, editingRow, searchQuery, filteredProducts, editRow, saveProductPrice, cancelEdit };
    }
  };
  </script>
  
  
  
  <style scoped>
  input{max-width: 150px; margin:5px;}
  </style>