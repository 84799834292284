<template>
    <div>
      <div class="sub-button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div>
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script>     
import AddUserActivity from './AddUserActivity.vue';
import GetUserActivity from './GetUserActivity.vue';
import UserActivities from './UserActivities.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'UserActivities', 
        components: [ 
          { name: 'Kullanıcı Aktiviteleri', component: 'UserActivities' },
          { name: 'Bir kullancıı Aktivitesi', component: 'GetUserActivity' }, 
          { name: 'Kullanıcıya aktivite ekle', component: 'AddUserActivity' }, 
        ],
      };
    },
    components: {     
      AddUserActivity,
      GetUserActivity,
      UserActivities 

    },
  };
  </script>
    
  <style scoped> .sub-button-container{display: flex;}
  </style>
    