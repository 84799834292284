<template>
  <div>
    <h2>Tüm Profil Resimleri</h2>
    <div v-if="pictures.length === 0">
      Hiç profil resmi bulunamadı.
    </div>
    <div v-else class="picture-grid">
      <div v-for="picture in pictures" :key="picture.id" class="picture-item">
        <div class="user-info">
          <strong>{{ picture.user_name }}</strong>
          <div>{{ picture.first_name }} {{ picture.last_name }}</div>
        </div>
        <img
          :src="picture.image_link"
          :alt="`${picture.user_name}'ın profil resmi`"
          class="profile-image"
        />
        <button @click="deletePicture(picture.id)">Sil</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AllUserProfilePictures',
  data() {
    return {
      pictures: [],
    };
  },
  methods: {
    async fetchPictures() {
      try {
        const response = await axios.get('/admin/user-profile-pictures');
        if (response.data.success) {
          this.pictures = response.data.data;
        }
      } catch (error) {
        console.error("Profil resimleri alınırken hata oluştu:", error);
      }
    },
    async deletePicture(id) {
      if (confirm("Bu profil resmini silmek istediğinize emin misiniz?")) {
        try {
          const response = await axios.delete(`/admin/user-profile-picture/${id}`);
          if (response.data.success) {
            alert("Profil resmi silindi.");
            this.pictures = this.pictures.filter(pic => pic.id !== id);
          }
        } catch (error) {
          console.error("Profil resmi silinirken hata oluştu:", error);
        }
      }
    }
  },
  mounted() {
    this.fetchPictures();
  }
};
</script>

<style scoped>
.picture-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.picture-item {
  border: 1px solid #086b68;
  padding: 1rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1em; 
}

.profile-image {
  max-width: 100%;
  display: block;
  margin-bottom: 0.5rem;
  border-radius: 0.5em;
}

.user-info {
  text-align: center;
  margin-bottom: 8px;
}
</style>
