<template>
  <div class="container">
    <h3>Bilgi Dokümanları Yönetimi</h3>

    <div class="alert info">Sohbet ederken doküman güncelleyebilirsiniz. Değişiklikler otomatik yansıyacak.</div>
    <div class="table-header">
       <div>
         <label for="filterGptSelect">GPT Filtrele</label>
         <select id="filterGptSelect" v-model="selectedGPT">
           <option value="">Hepsi</option>
           <option v-for="gpt in gpts" :key="gpt.gpt_id" :value="gpt.gpt_id">
             {{ gpt.gpt_name }}
           </option>
         </select>
       </div>
 
       <div>
         <label>
           <input type="checkbox" v-model="showActive" />
           Aktif dokümanları göster
         </label>
         <label>
           <input type="checkbox" v-model="showInactive" />
           Pasif dokümanları göster
         </label> 
         <label class="toggle-delete">
           <input type="checkbox" v-model="showDeleteButtons" />
           Sil butonlarını göster
         </label>
        </div>
      </div> 
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>GPT</th>
          <th>Başlık</th>
          <th>İçerik</th>
          <th>Durum</th>
          <th v-if="showDeleteButtons">Sil</th>
        </tr>
      </thead>
      <tbody> 
        <tr v-for="doc in filteredDocuments" :key="doc.doc_id">
          <td>{{ doc.doc_id }}</td>
          
          <td>
            <select
              v-model="doc.gpt_id"
              @change="updateDocument(doc, 'gpt_id', $event, true)"
            >
              <option
                v-for="gpt in gpts"
                :key="gpt.gpt_id"
                :value="gpt.gpt_id"
              >
                {{ gpt.gpt_name }}
              </option>
            </select>
          </td>

          <td contenteditable @blur="updateDocument(doc, 'title', $event)">
            {{ doc.title }}
          </td>
          <td contenteditable @blur="updateDocument(doc, 'content', $event)">
            {{ doc.content }}
          </td>

          <td>
            <select
              v-model="doc.is_active"
              @change="updateDocument(doc, 'is_active', $event, true)"
            >
              <option :value="true">Aktif</option>
              <option :value="false">Pasif</option>
            </select>
          </td>

          <td v-if="showDeleteButtons">
            <button class="delete" @click="deleteDocument(doc.doc_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>

    <h3>Yeni Bilgi Dokümanı Ekle</h3>
    <form @submit.prevent="createDocument">
      <label for="gpt">AI versiyonu seçin:</label>
      <select v-model="newDocument.gpt_id" required>
        <option v-for="gpt in gpts" :key="gpt.gpt_id" :value="gpt.gpt_id">
          {{ gpt.gpt_name }}
        </option>
      </select>

      <input type="text" v-model="newDocument.title" placeholder="Başlık" required />
      <textarea v-model="newDocument.content" placeholder="İçerik"></textarea>

      <select v-model="newDocument.is_active">
        <option :value="true">Aktif</option>
        <option :value="false">Pasif</option>
      </select>

      <button type="submit">Doküman Ekle</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted, computed } from "vue";

export default {
  setup() {
    const documents = ref([]);
    const gpts = ref([]);
    const showDeleteButtons = ref(false);

    // Yeni doküman form verileri
    const newDocument = ref({
      gpt_id: "",
      title: "",
      content: "",
      is_active: true,
    });

    // Filtreleme için eklenen reaktif değişkenler
    const selectedGPT = ref("");
    const showActive = ref(true);
    const showInactive = ref(false);

    const fetchDocuments = async () => {
      try {
        const response = await axios.get("/admin/gpt/knowledge-documents");
        documents.value = response.data.documents;
      } catch (error) {
        console.error("Bilgi dokümanları alınırken hata:", error);
      }
    };

    const fetchGPTs = async () => {
      try {
        const response = await axios.get("/admin/gpt/gpt");
        gpts.value = response.data.gpts;
      } catch (error) {
        console.error("GPT listesi alınırken hata:", error);
      }
    };

    const updateDocument = async (doc, field, event, isSelect = false) => {
      const newValue = isSelect ? event.target.value : event.target.innerText;
      try {
        await axios.put(`/admin/gpt/knowledge-documents/${doc.doc_id}`, {
          [field]: newValue,
        });
        doc[field] = newValue;
      } catch (error) {
        console.error("Bilgi dokümanı güncellenirken hata:", error);
      }
    };

    const deleteDocument = async (doc_id) => {
      if (!confirm("Bu bilgi dokümanını kalıcı olarak silmek istediğinize emin misiniz?")) {
        return;
      }
      try {
        await axios.delete(`/admin/gpt/knowledge-documents/${doc_id}`);
        documents.value = documents.value.filter((doc) => doc.doc_id !== doc_id);
      } catch (error) {
        console.error("Bilgi dokümanı silinirken hata:", error);
      }
    };

    const createDocument = async () => {
      try {
        await axios.post("/admin/gpt/knowledge-documents", newDocument.value);
        // Yeni doküman eklendikten sonra listeyi tekrar güncelliyoruz
        fetchDocuments();
      } catch (error) {
        console.error("Bilgi dokümanı eklenirken hata:", error);
      }
    };

    // Filtrelenmiş dokümanları döndüren computed property
    const filteredDocuments = computed(() => {
      return documents.value.filter((doc) => {
        // GPT filtresi aktifse ve doc.gpt_id eşleşmiyorsa eliyoruz
        if (selectedGPT.value && doc.gpt_id !== selectedGPT.value) {
          return false;
        }
        // Aktif checkbox işaretli değilse ve doküman aktifse eliyoruz
        if (!showActive.value && doc.is_active === true) {
          return false;
        } 
        if (!showInactive.value && doc.is_active === false) {
          return false;
        }
        return true;
      });
    });

    onMounted(() => {
      fetchDocuments();
      fetchGPTs();
    });

    return {
      documents,
      gpts,
      showDeleteButtons,
      newDocument,
      selectedGPT,
      showActive,
      showInactive,
      updateDocument,
      deleteDocument,
      createDocument,
      filteredDocuments,
    };
  },
};
</script>



    <style scoped>
    td{
      min-width: 120px;
    }
  </style>