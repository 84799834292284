<template>
  <div class="admin-user-test-stats">
    <h3>Kullanıcı Test İstatistikleri</h3>
 
    <input
      v-model="searchQuery"
      @input="filterUsers"
      placeholder="Kullanıcı adı veya ID ara"
    /> 
    <ul v-if="filteredUsers.length > 0" class="user-suggestions">
      <li
        v-for="user in filteredUsers"
        :key="user.user_id"
        @click="selectUser(user)"
        style="cursor: pointer;"
      >
        {{ user.user_name }} (ID: {{ user.user_id }})
      </li>
    </ul>
 
    <div v-if="selectedUser">
      <h3>
        Seçilen Kullanıcı:
        <strong>{{ selectedUser.user_name }} (ID: {{ selectedUser.user_id }})</strong>
      </h3> 
 
      <div v-if="loadingStats">Yükleniyor...</div>

      <!-- İstatistikler Geldiğinde -->
      <div v-else-if="stats">
        <table  cellpadding="6" cellspacing="0">
          <thead>
            <tr>
              <th>Ortalama Puan</th>
              <th>Toplam Cevap</th>
              <th>Doğru Sayısı</th>
              <th>Yanlış Sayısı</th>
              <th>Toplam Test</th>
              <th>Tamamlanmış</th>
              <th>Devam Eden</th>
              <th>Süresi Dolmuş</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ stats.averageScore !== null ? stats.averageScore : '-' }}</td>
              <td>{{ stats.totalAnswers }}</td>
              <td>{{ stats.totalCorrect }}</td>
              <td>{{ stats.totalIncorrect }}</td>
              <td>{{ stats.totalAttempts }}</td>
              <td>{{ stats.completedAttempts }}</td>
              <td>{{ stats.inProgressAttempts }}</td>
              <td>{{ stats.expiredAttempts }}</td>
            </tr>
          </tbody>
        </table>
      </div>
 
      <div v-else>
        Bu kullanıcıya ait istatistik bulunamadı.
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserTestStats",
  data() {
    return {
      users: [],
      filteredUsers: [],
      searchQuery: "",
      selectedUser: null,
      stats: null,
      loadingStats: false
    };
  },
  methods: { 
    async fetchUsers() {
      try {
        const response = await axios.get("/admin/mainusers");
        this.users = response.data;
      } catch (error) {
        console.error("Kullanıcılar alınırken hata oluştu:", error);
      }
    },

    filterUsers() {
      this.filteredUsers = this.users.filter(user =>
        user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.user_id.toString().includes(this.searchQuery)
      );
    },

    selectUser(user) {
      this.selectedUser = user;
      this.searchQuery = "";
      this.filteredUsers = [];
      this.stats = null;  
      this.fetchUserStats(user.user_id);
    },

    async fetchUserStats(userId) {
      try {
        this.loadingStats = true;
        const response = await axios.post("/admin/userteststats", {
          user_id: userId
        });
        if (response.data.success) {
          this.stats = response.data.statistics;
        } else {
          this.stats = null;
        }
      } catch (error) {
        console.error("Kullanıcı istatistikleri alınırken hata oluştu:", error);
        this.stats = null;
      } finally {
        this.loadingStats = false;
      }
    }
  },
  mounted() {
    this.fetchUsers();
  }
};
</script>

<style scoped>
.user-suggestions {
  list-style-type: none;
  border: 1px solid #ccc;
  max-width: 300px;
  margin: 0;
  padding: 0;
}
.user-suggestions li {
  padding: 5px;
  border-bottom: 1px solid #eee;
}
</style>
