<template>
    <div> 
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  
  <script> 
  import AllUserProfilePics from './AllUserProfilePics.vue';
   import RoleManagement from './RoleManagement.vue'; 
  import UserManagement from './UserManagement.vue';
   import UserProfilePics from './UserProfilePics.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'RoleManagement', 
        components: [
         { name: 'Roller', component: 'RoleManagement' }, 
          { name: 'Kullanıcılar', component: 'UserManagement' }, 
          { name: 'Tüm Profil Fotoğrafları', component: 'AllUserProfilePics' }, 
          { name: 'Kullanıcı Profil Fotoğrafı', component: 'UserProfilePics' }, 
        ]
      };
    },
  
    components: { 
      UserManagement,
      RoleManagement,
      UserProfilePics,
      AllUserProfilePics
    },
    
  };
  </script>
    
  <style scoped> 
  </style>
    