<template>
  <div class="container">
    <h4>E-posta Alıcı Yöneticisi</h4>
 
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Kullanıcı</th>
          <th>Kampanya</th>
          <th>Durum</th>
          <th>Okundu</th>
          <th>Tıklandı</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="recipient in recipients" :key="recipient.recipient_id">
          <td>{{ recipient.recipient_id }}</td>
          <td>{{ recipient.user?.user_name || 'Yok' }}</td>
          <td>{{ recipient.campaign?.name || 'Yok' }}</td>
          <td>{{ recipient.status }}</td>
          <td>{{ recipient.read_at || 'Okunmadı' }}</td>
          <td>{{ recipient.clicked_at || 'Tıklanmadı' }}</td>
          <td>
            <button @click="editRecipient(recipient)">Düzenle</button>
            <button @click="confirmDelete(recipient.recipient_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
 
    <div class="form-area">
      <h4>{{ isEditing ? 'Alıcıyı Düzenle' : 'Yeni Alıcı Ekle' }}</h4>
      <form @submit.prevent="submitRecipient">
        <select v-model="form.user_id" required>
          <option disabled value="">Kullanıcı Seç</option>
          <option v-for="user in users" :key="user.user_id" :value="user.user_id">
            {{ user.user_name }} ({{ user.e_mail }})
          </option>
        </select>
        <select v-model="form.campaign_id" required>
          <option disabled value="">Kampanya Seç</option>
          <option v-for="campaign in campaigns" :key="campaign.campaign_id" :value="campaign.campaign_id">
            {{ campaign.name }}
          </option>
        </select>
        <select v-model="form.status" required>
          <option value="sent">Gönderildi</option>
          <option value="read">Okundu</option>
          <option value="clicked">Tıklandı</option>
          <option value="bounced">Geri Döndü</option>
        </select>
        <button type="submit">{{ isEditing ? 'Güncelle' : 'Ekle' }}</button>
        <button type="button" @click="resetForm">İptal</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    recipients: [],
    campaigns: [],
    users: [],
    form: {
      recipient_id: null,
      campaign_id: '',
      user_id: '',
      status: 'sent',
    },
    isEditing: false,
  };
},
async mounted() {
  await this.fetchRecipients();
  await this.fetchCampaigns();
  await this.fetchUsers();
},
methods: {
  async fetchRecipients() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailrecipients', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.recipients = response.data;
    } catch (error) {
      console.error('Alıcılar alınırken hata oluştu:', error);
    }
  },
  async fetchCampaigns() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailcampaigns', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.campaigns = response.data;
    } catch (error) {
      console.error('Kampanyalar alınırken hata oluştu:', error);
    }
  },
  async fetchUsers() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/mainusers', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.users = response.data;
    } catch (error) {
      console.error('Kullanıcılar alınırken hata oluştu:', error);
    }
  },
  editRecipient(recipient) {
    this.form = {
      recipient_id: recipient.recipient_id,
      campaign_id: recipient.campaign_id,
      user_id: recipient.user_id,
      status: recipient.status,
    };
    this.isEditing = true;
  },
  confirmDelete(recipientId) {
    if (confirm('Bu alıcıyı silmek istediğinize emin misiniz?')) {
      this.deleteRecipient(recipientId);
    }
  },
  async deleteRecipient(recipientId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/emailrecipients/${recipientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchRecipients();
    } catch (error) {
      console.error('Alıcı silinirken hata oluştu:', error);
    }
  },
  async submitRecipient() {
    try {
      const token = localStorage.getItem('token');
      const method = this.form.recipient_id ? 'put' : 'post';
      const url = this.form.recipient_id
        ? `/admin/emailrecipients/${this.form.recipient_id}`
        : '/admin/emailrecipients';
      await axios[method](url, this.form, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchRecipients();
      this.resetForm();
    } catch (error) {
      console.error('Alıcı kaydedilirken hata oluştu:', error);
    } 
  },
  resetForm() {
    this.form = {
      recipient_id: null,
      campaign_id: '',
      user_id: '',
      status: 'sent',
    };
    this.isEditing = false;
  },
},
};
</script>
  