<template>
    <div class="container">
      <h3>Silinmiş Sohbetler</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Kullanıcı ID</th>
            <th>GPT ID</th>
            <th>Ad</th>
            <th>Oluşturulma Tarihi</th>
            <th>Güncellenme Tarihi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="chat in deletedChats" :key="chat.chat_id">
            <td>{{ chat.chat_id }}</td>
            <td>{{ chat.user_id }}</td>
            <td>{{ chat.gpt_id }}</td>
            <td>{{ chat.chat_name }}</td>
            <td>{{ chat.created_at }}</td>
            <td>{{ chat.updated_at }}</td>
            <td>
              <button @click="showChatModal(chat.chat_id)">Görüntüle</button>
              <button class="delete" @click="deleteChatPermanently(chat.chat_id)">Kalıcı Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
       
      <UserChatModal v-if="isModalVisible" :chat_id="selectedChatId" @close="closeModal" />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import UserChatModal from "./UserChatModal.vue";
  
  export default {
    name: "DeletedChats",
    components: {
      UserChatModal,
    },
    data() {
      return {
        deletedChats: [],
        isModalVisible: false,
        selectedChatId: null,
      };
    },
    methods: {
      fetchDeletedChats() {
        axios.get("/admin/noahai/chats/deleted")
          .then((response) => {
            this.deletedChats = response.data.chats;
          })
          .catch((error) => {
            console.error("Silinmiş sohbetler alınırken hata:", error);
          });
      },
      deleteChatPermanently(chat_id) {
        if (!confirm("Bu sohbeti kalıcı olarak silmek istediğinize emin misiniz?"))
          return;
        axios.delete(`/admin/noahai/chats/${chat_id}`)
          .then(() => {
            this.deletedChats = this.deletedChats.filter(chat => chat.chat_id !== chat_id);
          })
          .catch((error) => {
            console.error("Sohbet kalıcı silinirken hata:", error);
          });
      },
      showChatModal(chat_id) {
        this.selectedChatId = chat_id;
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    },
    mounted() {
      this.fetchDeletedChats();
    }
  };
  </script>
  
  <style scoped> 
  </style>
  