<template>
    <div class="notification-manager">
      <div class="notification-form">
        <h3>{{ editingIndex === null ? 'Yeni Bildirim Ekle' : 'Bildirim Düzenle' }}</h3>
        <form @submit.prevent="editingIndex === null ? addNotification() : updateNotification()">
          <div class="form-group">
            <label for="platform">Platform</label>
            <input type="text" v-model="editingNotification.platform" required />
          </div>
          <div class="form-group">
            <label for="details">Açıklama</label>
            <textarea v-model="editingNotification.details"></textarea>
          </div>
          <div class="form-group">
            <label for="user_id">Kullanıcı ID</label>
            <input type="number" v-model="editingNotification.user_id" required />
          </div>
          <button type="submit">{{ editingIndex === null ? 'Ekle' : 'Güncelle' }}</button>
        </form>
      </div>
      
      <div class="notification-table">
        <h3>Bildirimler</h3>
        <table>
          <thead>
            <tr>
              <th>Platform</th>
              <th>Açıklama</th>
              <th>Kullanıcı Adı</th>
              <th>Kullanıcı ID</th>
              <th>Okundu</th>
              <th>Okunma Tarihi</th>
              <th>Oluşturulma Tarihi</th> 
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(notification, index) in notifications" :key="notification.id">
              <td>{{ notification.platform }}</td>
              <td>{{ notification.details }}</td>
              <td>{{ notification.User ? notification.User.user_name : 'Bilinmiyor' }}</td>
              <td>{{ notification.user_id }}</td>
              <td>
                <input type="checkbox" v-model="notification.is_read" @change="updateIsRead(notification.id, notification.is_read)" />
              </td>
              <td>{{ formatDateTime(notification.readed_at) }}</td>
              <td>{{ formatDateTime(notification.created_at) }}</td> 
              <td style="display: flex;">
                <button @click="editNotification(index)">Düzenle</button>
                <button class="delete" @click="deleteNotification(notification.id)">Sil</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        newNotification: {
          platform: '',
          details: '',
          user_id: '',  
        },
        notifications: [],
        editingIndex: null,
        editingNotification: {
          platform: '',
          details: '',
          user_id: '',
          created_at: '',
        },
      };
    },
    methods: {
      async addNotification() { 
        if (!this.editingNotification.user_id || isNaN(this.editingNotification.user_id)) {
          alert("Lütfen geçerli bir kullanıcı ID'si girin!");
          return;
        }
  
        try {
          await axios.post('/admin/account/notifications', this.editingNotification); 
          this.fetchNotifications();
          this.editingNotification = { platform: '', details: '', user_id: '' };  
        } catch (error) {
          console.error('Bildirim eklenirken hata oluştu:', error);
        }
      },
  
      async fetchNotifications() {
        try {
          const response = await axios.get('/admin/account/notifications');
          this.notifications = response.data.data;
        } catch (error) {
          console.error('Bildirimler getirilirken hata oluştu:', error);
        }
      },
  
      editNotification(index) {
        this.editingIndex = index;
        this.editingNotification = { ...this.notifications[index] }; 
      },
  
      async updateNotification() { 
        if (!this.editingNotification.user_id || isNaN(this.editingNotification.user_id)) {
          alert("Lütfen geçerli bir kullanıcı ID'si girin!");
          return;
        }
  
        try {
          const updatedNotification = this.editingNotification;
          await axios.put(`/admin/account/notifications/${updatedNotification.id}`, updatedNotification);
          // Listeyi yeniden al
          this.fetchNotifications();
          this.editingIndex = null;  
          this.editingNotification = { platform: '', details: '', user_id: '', created_at: '' };
        } catch (error) {
          console.error('Bildirim güncellenirken hata oluştu:', error);
        }
      },
  
      async deleteNotification(id) {
        try {
          await axios.delete(`/admin/account/notifications/${id}`);
          this.fetchNotifications(); 
        } catch (error) {
          console.error('Bildirim silinirken hata oluştu:', error);
        }
      },
  
      async updateIsRead(id, isRead) {
        try {
          await axios.put(`/admin/account/notifications/${id}`, { is_read: isRead });
        } catch (error) {
          console.error('Bildirim okundu durumu güncellenirken hata oluştu:', error);
        }
      }, 
    },
    created() {
      this.fetchNotifications();
    },
  };
  </script> 
  
  <style scoped>
  .notification-manager {
    margin: 20px;
  }
  
  .notification-form {
    margin-bottom: 20px;
  }
  
  .notification-form .form-group {
    margin-bottom: 10px;
  }
  
  .notification-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .notification-table table th,
  .notification-table table td {
    padding: 10px;
    text-align: left;
  }
  
  .notification-table table input,
  .notification-table table textarea {
    width: 100%;
  }
  
  .notification-table button {
    margin-left: 10px;
  }
  </style>
  