<template>
  <div>
    <h4>Anket Geçmişi</h4>
    <div v-if="surveys.length">
      <ul>
        <li v-for="survey in surveys" :key="survey.survey_id">
          <h4>{{ survey.title }}</h4>
          <p>{{ survey.description }}</p>
          <p>Başlangıç Tarihi: {{ survey.start_date }}</p>
          <p>Bitiş Tarihi: {{ survey.end_date }}</p>
          <p>Durum: {{ survey.status }}</p>
          <button @click="deleteSurvey(survey.survey_id)">Sil</button>
        </li>
      </ul>
    </div>
    <div v-else>
      <p>Hiç anket bulunamadı.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      surveys: []
    };
  },
  async mounted() {
    await this.fetchSurveys();
  },
  methods: {
    async fetchSurveys() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/allsurveys', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.surveys = response.data;
      } catch (error) {
        console.error('Anketler alınırken hata oluştu:', error);
      }
    },
    async deleteSurvey(surveyId) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/admin/surveys/${surveyId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.fetchSurveys();  
      } catch (error) {
        console.error('Anket silinirken hata oluştu:', error);
      }
    }
  }
};
</script>


<style scoped> 
ul {
  list-style-type: none;
  padding: 0;
}

li {  
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 0.5em;
  background-color: #050505;
  transition: background-color 0.3s;
}

li:hover {
  background-color: #0b141a; 
}

button { 
  padding: 0.5em 2em;
  margin: 0.5em 1em 0.5em 0em;
  border-radius: 4px; 
  cursor: pointer;
  transition: background-color 0.3s;
}
 
</style>  