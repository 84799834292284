<template>
    <div class="web-management">  

      <div class="alert info">
<b>Lütfen Web sitelerini silmeyin.</b> <br> - Pasif duruma geitirmeniz sitenin bakım moduna geçmesini sağlar. <br> 
- Silmeniz halinde web sitesi yüklenemez hale gelir <br> 
- Linkini değiştirmeniz halinde CORS ayarları nedeniyle backend taraından tüm erişimi engellenir. <br> <br>

-Yeni web sitesi eklerseniz APIlere erişim yetkisi verirsiniz. <b>Güvenlik açıklarına neden olabilir.</b>
      </div>
      <div class="table-header">
        <h3>Tüm Web Siteleri</h3>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table>
        <thead>
          <tr>
            <th>Web Adı</th>
            <th>Link</th>
            <th>Site Aktif Mi?</th>
            <th>Giriş Yapma Aktif Mi?</th>
            <th>Kayıt Olma Aktif Mi?</th>
            <th>Detaylar</th>
            <th>Güncelle</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="web in webs" :key="web.web_id">
            <td>{{ web.web_name }}</td>
            <td>{{ web.link }}</td>
            <td>
              <select v-model="web.is_active" @change="updateWebStatus(web)">
                <option :value="true">Aktif</option>
                <option :value="false">Pasif</option>
              </select>
            </td>
            <td>
              <select v-model="web.login_is_active" @change="updateWebStatus(web)">
                <option :value="true">Aktif</option>
                <option :value="false">Pasif</option>
              </select>
            </td>
            <td>
              <select v-model="web.register_is_active" @change="updateWebStatus(web)">
                <option :value="true">Aktif</option>
                <option :value="false">Pasif</option>
              </select>
            </td>
            <td>{{ web.details }}</td>
            <td>
              <button @click="editWeb(web)">Güncelle</button> 
              <button class="deletebutton" v-if="showDeleteButtons" @click="confirmDelete(web.web_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>  
  
      <form @submit.prevent="isEdit ? updateWeb() : createWeb()">
        <h4>{{ isEdit ? "Websiteyi Düzenle" : "Yeni Website Ekle" }}</h4>
        <input v-model="selectedWeb.web_name" placeholder="Website Adı" required />
        <input v-model="selectedWeb.link" placeholder="Link" required />
        <label>
          <input type="checkbox" v-model="selectedWeb.is_active" />
          Aktif Mi?
        </label>
         
        <textarea v-model="selectedWeb.details" placeholder="Detaylar"></textarea>
  <div class="formbutton">
    <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
    <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
  </div>

      </form>
  
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default { 
    data() {
      return {
        webs: [],
        showDeleteButtons: false, 
        selectedWeb: {
          web_id: null,
          web_name: '',
          link: '',
          is_active: false,
          login_is_active: false,
          register_is_active: false,
          details: '', 
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchWebs() {
        try {
          const response = await axios.get('/admin/webs');
          this.webs = response.data;
        } catch (error) {
          console.error('Web siteleri alınırken hata oluştu:', error);
        }
      },
      async createWeb() {
        try {
          await axios.post('/admin/webs', this.selectedWeb);
          this.fetchWebs();
          this.resetForm();
        } catch (error) {
          console.error('Web eklenirken hata oluştu:', error);
        }
      },
      editWeb(web) {
        this.selectedWeb = { ...web };
        this.isEdit = true;
      },
      async updateWeb() {
        try {
          await axios.put(`/admin/webs/${this.selectedWeb.web_id}`, this.selectedWeb);
          this.fetchWebs();
          this.resetForm();
        } catch (error) {
          console.error('Web güncellenirken hata oluştu:', error);
        }
      },
      async updateWebStatus(web) {
        try {
          await axios.put(`/admin/webs/${web.web_id}`, web);
          this.fetchWebs();
          alert('Web sitesi durumu başarıyla güncellendi.');
        } catch (error) {
          console.error('Web durumu güncellenirken hata oluştu:', error);
          alert('Web sitesi durumu güncellenirken bir hata oluştu.');
        }
      },
      async deleteWeb(web_id) {
        try {
          await axios.delete(`/admin/webs/${web_id}`);
          this.fetchWebs();
        } catch (error) {
          console.error('Web silinirken hata oluştu:', error);
        }
      },
      confirmDelete(web_id) {
        if (confirm('Bu web sitesini silmek istediğinize emin misiniz?')) {
          this.deleteWeb(web_id);
        }
      },
      resetForm() {
        this.selectedWeb = {
          web_id: null,
          web_name: '',
          link: '',
          is_active: false,
          login_is_active: false,
          register_is_active: false,
          details: '', 
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchWebs();
    },
  };
  </script>
  
   
<style>
.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.formbutton{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
