<template>
  <div class="minor-category-management">
    <h3>Gösterilen Ürünler</h3>

    <!-- Üstteki form: Ürün ekleme/düzenleme (VAR OLAN ÖZELLİKLERİ KORUYOR) -->
    <h4>{{ isEdit ? "Ürün Düzenle" : "Yeni Ürün Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateMinorCategory() : createMinorCategory()">
      <input v-model="selectedMinorCategory.name" placeholder="Ürün Adı" required />
      <textarea v-model="selectedMinorCategory.description" placeholder="Açıklama" rows="5" cols="50" style="resize: both;"></textarea>
      <input v-model="selectedMinorCategory.sub_tone" placeholder="Alt ton (soğuk, sıcak, nötr)" />
      <input v-model="selectedMinorCategory.color" placeholder="Renk kodu (örn. #0a0a0a)" />
      <select v-model="selectedMinorCategory.main_category_id" required>
        <option disabled value="">Ana Kategoriyi Seçin</option>
        <option v-for="category in categories" :key="category.id" :value="category.id">
          {{ category.name }}
        </option>
      </select>
      <select v-model="selectedMinorCategory.sub_category_id" required>
        <option disabled value="">Alt Kategoriyi Seçin</option>
        <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
          {{ subcategory.name }}
        </option>
      </select>

      <div>
        <label for="main-image">Ana Görsel (Ana ekranda ki kutularda 320*480px):</label>
        <input class="upload-button" type="file" id="main-image" @change="handleFileUpload('main_image', $event)" />
        <img v-if="preview.main_image" :src="preview.main_image" alt="Ana Görsel" class="image-preview" />
      </div>

      <div style="margin: 1rem 0;">
          <label for="main-video">Video:</label>
          <input class="upload-button" type="file" id="main-video" @change="handleFileUpload('main_video', $event)" />
      </div>

      <div style="margin: 1rem 0;">
        <label for="detail-image">Başlık Görsel (ürün sayfasındaki başlık görsel 380*400px):</label>
        <input class="upload-button" type="file" id="detail-image" @change="handleFileUpload('detail_image', $event)" />
        <img v-if="preview.detail_image" :src="preview.detail_image" alt="Detay Görsel" class="image-preview" />
      </div>
      <div style="margin: 1rem 0;">
        <label for="background-image">Alt görsel (ürün sayfasında 2. görsel, otomatik sığar, genişlik 980px den daha yüksek olmamalı):</label>
        <input class="upload-button" type="file" id="background-image" @change="handleFileUpload('background_image', $event)" />
        <img v-if="preview.background_image" :src="preview.background_image" alt="Arka Plan Görsel" class="image-preview" />
      </div>

      <div style="display: flex; justify-content: space-between;">
        <button class="confirm" type="submit">{{ isEdit ? "Güncelle" : "Ürün Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </div>
    </form>

    <!-- Tablo başlığı ve filtreler -->
    <div class="table-header">
      <h4>Tüm Ürünler</h4>
      <label for="subcategoryFilter">
        Alt Kategoriye Göre Filtrele:
        <select id="subcategoryFilter" v-model="selectedSubcategoryFilter">
          <option value="">Tüm Alt Kategoriler</option>
          <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
            {{ subcategory.name }}
          </option>
        </select>
      </label>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <!-- Ürün tablosu -->
    <table class="minor-category-table">
      <thead>
        <tr>
          <th>Ürün Adı</th>
          <th>Açıklama</th>
          <th>Alt Ton</th>
          <th>Renk</th>
          <th>Ana Kategori</th>
          <th>Alt Kategori</th>
          <th>Ana Görsel</th>
          <th>Detay Görsel</th>
          <th>Arka Plan Görsel</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="minorCategory in filteredMinorCategories"
          :key="minorCategory.id"
          @click="startInlineEdit(minorCategory)"
        >
          <!-- Inline düzenleme modunda -->
          <template v-if="editingId === minorCategory.id">
            <td>
              <input v-model="editingProduct.name" />
            </td>
            <td>
              <textarea v-model="editingProduct.description"></textarea>
            </td>
            <td>
              <input v-model="editingProduct.sub_tone" />
            </td>
            <td>
              <input v-model="editingProduct.color" />
            </td>
            <td>
              <select v-model="editingProduct.main_category_id">
                <option disabled value="">Ana Kategoriyi Seçin</option>
                <option v-for="category in categories" :key="category.id" :value="category.id">
                  {{ category.name }}
                </option>
              </select>
            </td>
            <td>
              <select v-model="editingProduct.sub_category_id">
                <option disabled value="">Alt Kategoriyi Seçin</option>
                <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
                  {{ subcategory.name }}
                </option>
              </select>
            </td>
            <!-- Ana Görsel sütunu -->
            <td>
              <div>
                <img
                  v-if="editingPreview.main_image"
                  :src="editingPreview.main_image"
                  alt="Ana Görsel"
                  class="image-preview"
                />
                <span v-else>Yok</span>
                <button type="button" @click.stop="triggerInlineFileInput('main_image')">Görsel Seç</button>
                <button type="button" @click.stop="inlineRemoveImage(editingProduct.id, 'main_image')">Görseli Kaldır</button>
                <input
                  type="file"
                  ref="inlineMainImageInput"
                  style="display:none"
                  @change="handleInlineFileUpload('main_image', $event)"
                />
              </div>
            </td>
            <!-- Detay Görsel sütunu -->
            <td>
              <div>
                <img
                  v-if="editingPreview.detail_image"
                  :src="editingPreview.detail_image"
                  alt="Detay Görsel"
                  class="image-preview"
                />
                <span v-else>Yok</span>
                <button type="button" @click.stop="triggerInlineFileInput('detail_image')">Görsel Seç</button>
                <button type="button" @click.stop="inlineRemoveImage(editingProduct.id, 'detail_image')">Görseli Kaldır</button>
                <input
                  type="file"
                  ref="inlineDetailImageInput"
                  style="display:none"
                  @change="handleInlineFileUpload('detail_image', $event)"
                />
              </div>
            </td>
            <!-- Arka Plan Görsel sütunu -->
            <td>
              <div>
                <img
                  v-if="editingPreview.background_image"
                  :src="editingPreview.background_image"
                  alt="Arka Plan Görsel"
                  class="image-preview"
                />
                <span v-else>Yok</span>
                <button type="button" @click.stop="triggerInlineFileInput('background_image')">Görsel Seç</button>
                <button type="button" @click.stop="inlineRemoveImage(editingProduct.id, 'background_image')">Görseli Kaldır</button>
                <input
                  type="file"
                  ref="inlineBackgroundImageInput"
                  style="display:none"
                  @change="handleInlineFileUpload('background_image', $event)"
                />
              </div>
            </td>
            <!-- Inline düzenleme için kaydet ve iptal butonları -->
            <td>
              <button type="button" @click.stop="saveInlineEdit">Kaydet</button>
              <button type="button" @click.stop="cancelInlineEdit">İptal</button>
            </td>
          </template>
          <!-- Normal görüntüleme modunda -->
          <template v-else>
            <td>{{ minorCategory.name }}</td>
            <td>{{ minorCategory.description }}</td>
            <td>{{ minorCategory.sub_tone }}</td>
            <td>{{ minorCategory.color }}</td>
            <td>{{ getCategoryName(minorCategory.main_category_id) }}</td>
            <td>{{ getSubCategoryName(minorCategory.sub_category_id) }}</td>
            <td>
              <img
                v-if="minorCategory.main_image"
                :src="minorCategory.main_image"
                alt="Ana Görsel"
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <img
                v-if="minorCategory.detail_image"
                :src="minorCategory.detail_image"
                alt="Detay Görsel"
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <img
                v-if="minorCategory.background_image"
                :src="minorCategory.background_image"
                alt="Arka Plan Görsel"
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <button @click.stop="editMinorCategory(minorCategory)">Düzenle</button>
              <button
                v-if="showDeleteButtons"
                class="deletebutton"
                @click.stop="confirmDelete(minorCategory.id)"
              >
                Sil
              </button>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      // Üst form verileri
      minorCategories: [],
      categories: [],
      subcategories: [],
      selectedMinorCategory: {
        id: null,
        name: '',
        description: '',
        sub_tone: '',
        color: '',
        main_category_id: '',
        sub_category_id: '',
        main_image: null,
        main_video: null,
        detail_image: null,
        background_image: null,
      },
      preview: {
        main_image: null,
        detail_image: null,
        background_image: null,
      },
      isEdit: false,
      showDeleteButtons: false,
      selectedSubcategoryFilter: '',
      // Inline düzenleme için veriler
      editingId: null,
      editingProduct: {},
      editingPreview: {
        main_image: null,
        detail_image: null,
        background_image: null,
      },
    };
  },
  computed: {
    filteredMinorCategories() {
      if (!this.selectedSubcategoryFilter) {
        return this.minorCategories;
      }
      return this.minorCategories.filter(
        (mc) => mc.sub_category_id === this.selectedSubcategoryFilter
      );
    },
  },
  methods: {
    getCategoryName(id) {
      const category = this.categories.find((cat) => cat.id === id);
      return category ? category.name : 'Bilinmiyor';
    },
    getSubCategoryName(id) {
      const subcategory = this.subcategories.find((sub) => sub.id === id);
      return subcategory ? subcategory.name : 'Bilinmiyor';
    },
    async fetchMinorCategories() {
      try {
        const response = await axios.get('/admin/minor-categories');
        this.minorCategories = response.data.minorCategories;
      } catch (error) {
        console.error('Ürünler alınırken hata oluştu:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('/admin/main-categories');
        this.categories = response.data.mainCategories;
      } catch (error) {
        console.error('Ana kategoriler alınırken hata oluştu:', error);
      }
    },
    async fetchSubcategories() {
      try {
        const response = await axios.get('/admin/sub-categories');
        this.subcategories = response.data.subCategories;
      } catch (error) {
        console.error('Alt kategoriler alınırken hata oluştu:', error);
      }
    },
    handleFileUpload(type, event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (type === 'main_image') {
          this.preview.main_image = e.target.result;
          this.selectedMinorCategory.main_image = file;
        } else if (type === 'main_video') {
          this.selectedMinorCategory.main_video = file;
        } else if (type === 'detail_image') {
          this.preview.detail_image = e.target.result;
          this.selectedMinorCategory.detail_image = file;
        } else if (type === 'background_image') {
          this.preview.background_image = e.target.result;
          this.selectedMinorCategory.background_image = file;
        }
      };
      reader.readAsDataURL(file);
    },
    async createMinorCategory() {
      try {
        const formData = new FormData();
        formData.append('name', this.selectedMinorCategory.name);
        formData.append('description', this.selectedMinorCategory.description);
        formData.append('sub_tone', this.selectedMinorCategory.sub_tone);
        formData.append('color', this.selectedMinorCategory.color);
        formData.append('main_category_id', this.selectedMinorCategory.main_category_id);
        formData.append('sub_category_id', this.selectedMinorCategory.sub_category_id);
        if (this.selectedMinorCategory.main_image) {
          formData.append('main_image', this.selectedMinorCategory.main_image);
        }
        if (this.selectedMinorCategory.main_video) {
          formData.append('main_video', this.selectedMinorCategory.main_video);
        }
        if (this.selectedMinorCategory.detail_image) {
          formData.append('detail_image', this.selectedMinorCategory.detail_image);
        }
        if (this.selectedMinorCategory.background_image) {
          formData.append('background_image', this.selectedMinorCategory.background_image);
        }
        await axios.post('/admin/minor-category', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.fetchMinorCategories();
        this.resetForm();
      } catch (error) {
        console.error('Ürün eklenirken hata oluştu:', error);
      }
    },
    async updateMinorCategory() {
      try {
        const formData = new FormData();
        formData.append('name', this.selectedMinorCategory.name);
        formData.append('description', this.selectedMinorCategory.description);
        formData.append('sub_tone', this.selectedMinorCategory.sub_tone);
        formData.append('color', this.selectedMinorCategory.color);
        formData.append('main_category_id', this.selectedMinorCategory.main_category_id);
        formData.append('sub_category_id', this.selectedMinorCategory.sub_category_id);
        if (this.selectedMinorCategory.main_image) {
          formData.append('main_image', this.selectedMinorCategory.main_image);
        }
        if (this.selectedMinorCategory.main_video) {
          formData.append('main_video', this.selectedMinorCategory.main_video);
        }
        if (this.selectedMinorCategory.detail_image) {
          formData.append('detail_image', this.selectedMinorCategory.detail_image);
        }
        if (this.selectedMinorCategory.background_image) {
          formData.append('background_image', this.selectedMinorCategory.background_image);
        }
        await axios.put(`/admin/minor-category/${this.selectedMinorCategory.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.fetchMinorCategories();
        this.resetForm();
      } catch (error) {
        console.error('Ürün güncellenirken hata oluştu:', error);
      }
    },
    editMinorCategory(minorCategory) {
      this.selectedMinorCategory = {
        ...minorCategory,
        main_image: null,
        detail_image: null,
        background_image: null,
      };
      this.preview = {
        main_image: minorCategory.main_image,
        detail_image: minorCategory.detail_image,
        background_image: minorCategory.background_image,
      };
      this.isEdit = true;
    },
    async deleteMinorCategory(id) {
      try {
        await axios.delete(`/admin/minor-category/${id}`);
        this.fetchMinorCategories();
      } catch (error) {
        console.error('Ürün silinirken hata oluştu:', error);
      }
    },
    confirmDelete(id) {
      if (confirm('Bu Ürün silmek istediğinizden emin misiniz?')) {
        this.deleteMinorCategory(id);
      }
    },
    resetForm() {
      this.selectedMinorCategory = {
        id: null,
        name: '',
        description: '',
        sub_tone: '',
        color: '',
        main_category_id: '',
        sub_category_id: '',
        main_image: null,
        main_video: null,
        detail_image: null,
        background_image: null,
      };
      this.preview = {
        main_image: null,
        detail_image: null,
        background_image: null,
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
    // Inline düzenleme metotları
    startInlineEdit(minorCategory) {
      this.editingId = minorCategory.id;
      this.editingProduct = { ...minorCategory };
      this.editingPreview = {
        main_image: minorCategory.main_image,
        detail_image: minorCategory.detail_image,
        background_image: minorCategory.background_image,
      };
    },
    cancelInlineEdit() {
      this.editingId = null;
      this.editingProduct = {};
      this.editingPreview = {
        main_image: null,
        detail_image: null,
        background_image: null,
      };
    },
    async saveInlineEdit() {
      try {
        const formData = new FormData();
        formData.append('name', this.editingProduct.name);
        formData.append('description', this.editingProduct.description);
        formData.append('sub_tone', this.editingProduct.sub_tone);
        formData.append('color', this.editingProduct.color);
        formData.append('main_category_id', this.editingProduct.main_category_id);
        formData.append('sub_category_id', this.editingProduct.sub_category_id);
        if (this.editingProduct.main_image instanceof File) {
          formData.append('main_image', this.editingProduct.main_image);
        }
        if (this.editingProduct.detail_image instanceof File) {
          formData.append('detail_image', this.editingProduct.detail_image);
        }
        if (this.editingProduct.background_image instanceof File) {
          formData.append('background_image', this.editingProduct.background_image);
        }
        await axios.put(`/admin/minor-category/${this.editingProduct.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.fetchMinorCategories();
        this.cancelInlineEdit();
      } catch (error) {
        console.error('Inline ürün güncellenirken hata oluştu:', error);
      }
    },
    triggerInlineFileInput(type) {
      let refName = '';
      if (type === 'main_image') {
        refName = 'inlineMainImageInput';
      } else if (type === 'detail_image') {
        refName = 'inlineDetailImageInput';
      } else if (type === 'background_image') {
        refName = 'inlineBackgroundImageInput';
      }
      const ref = this.$refs[refName];
      if (ref) {
        if (Array.isArray(ref)) {
          ref[0].click();
        } else {
          ref.click();
        }
      }
    },
    handleInlineFileUpload(type, event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (type === 'main_image') {
          this.editingPreview.main_image = e.target.result;
          this.editingProduct.main_image = file;
        } else if (type === 'detail_image') {
          this.editingPreview.detail_image = e.target.result;
          this.editingProduct.detail_image = file;
        } else if (type === 'background_image') {
          this.editingPreview.background_image = e.target.result;
          this.editingProduct.background_image = file;
        }
      };
      reader.readAsDataURL(file);
    },
    async inlineRemoveImage(id, column) {
      if (confirm('Bu görseli kaldırmak istediğinizden emin misiniz?')) {
        try {
          await axios.post(`/admin/minor-categories/removeimage/${id}`, { column });
          if (column === 'main_image') {
            this.editingPreview.main_image = null;
            this.editingProduct.main_image = null;
          } else if (column === 'detail_image') {
            this.editingPreview.detail_image = null;
            this.editingProduct.detail_image = null;
          } else if (column === 'background_image') {
            this.editingPreview.background_image = null;
            this.editingProduct.background_image = null;
          }
          this.fetchMinorCategories();
        } catch (error) {
          console.error('Görsel kaldırılırken hata oluştu:', error);
        }
      }
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchSubcategories();
    this.fetchMinorCategories();
  },
};
</script> 
 
  <style scoped>
  td{
    max-width: 430px;
  }
</style>