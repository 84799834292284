<template>
    <div class="admin-feedback">
      <h3>Son 100 Geri Bildirim</h3>
      <div v-if="loading">Yükleniyor...</div>
      <div v-else>
        <div v-for="feedback in feedbacks" :key="feedback.id" class="feedback-item">
          <p><strong>Chat ID:</strong> {{ feedback.chat_id }}</p>
          <p><strong>Mesaj ID:</strong> {{ feedback.message_id }}</p>
          <p><strong>Geri Bildirim:</strong> <br /> {{ feedback.feedback }}</p>
          <p class="date">{{ formatDate(feedback.created_at) }}</p>
          <hr />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import axios from "axios";
  
  export default {
    setup() {
      const feedbacks = ref([]);
      const loading = ref(true);
  
      const fetchFeedbacks = async () => {
        try {
          const response = await axios.get("/admin/noahaura/feedbacks");
          feedbacks.value = response.data.feedbacks;
        } catch (error) {
          console.error("Geri bildirimler alınamadı:", error);
        } finally {
          loading.value = false;
        }
      };
  
      const formatDate = (date) => {
        return new Date(date).toLocaleString('tr-TR');
      };
  
      onMounted(fetchFeedbacks);
  
      return { feedbacks, loading, formatDate };
    }
  };
  </script>
  
  
  <style scoped>
  .admin-feedback {
    max-width: 800px;
    margin: auto;
    padding: 1rem;
  }
  
  .feedback-item {
    margin-bottom: 1rem;
    background: #050505;
    padding: 0.75rem;
    border-radius: 8px;
  }
  
  .date {
    font-size: 0.8rem;
    color: #888;
  }
  </style>
  