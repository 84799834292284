<template>
  <div>
    <div class="container">
      <div>
        <h4>Bir Anket Seçin</h4>
        <select v-model="selectedSurveyId" @change="fetchSurveyQuestions">
          <option value="" disabled>Anket Seç</option>
          <option v-for="survey in surveys" :value="survey.survey_id" :key="survey.survey_id">
            {{ survey.title }}
          </option>
        </select>
      </div>
      <div v-if="selectedSurveyId">
        <h4>Anket Soruları</h4>
        <ul>
          <li style="border: solid 1px #2a3942;" v-for="question in questions" :key="question.question_id">
            <b>{{ question.question_text }}</b> - {{ question.question_type }}
            <button @click="editQuestion(question)">Düzenle</button>
            <button @click="deleteQuestion(question.question_id)">Sil</button>
            <ul>
              <li v-for="option in question.options" :key="option.option_id">
                {{ option.option_text }}
                <button @click="deleteOption(option.option_id)">Seçeneği Sil</button>
              </li>
            </ul> 
          </li>
        </ul>
        <button class="ad-new-q" @click="addQuestion">Yeni Soru Ekle</button>
        <div class="question-form">
          <h4>{{ editingQuestion ? 'Soruyu Düzenle' : 'Yeni Soru Ekle' }}</h4>
          <form @submit.prevent="editingQuestion ? updateQuestion() : createQuestion()">
            <input v-model="currentQuestion.question_text" placeholder="Soru Metni" required />
            <select v-model="currentQuestion.question_type">
              <option value="text">Metin</option>
              <option value="multiple_choice">Çoktan Seçmeli</option>
            </select>
            <button type="submit">{{ editingQuestion ? 'Güncelle' : 'Ekle' }} Soru</button>
          </form>
        </div>
        <div class="option-form" v-if="currentQuestion.question_type === 'multiple_choice'">
          <h4>{{ editingOption ? 'Seçeneği Düzenle' : 'Yeni Seçenek Ekle' }}</h4>
          <form @submit.prevent="editingOption ? updateOption() : createOption()">
            <input v-model="currentOption.option_text" placeholder="Seçenek Metni" required />
            <button type="submit">{{ editingOption ? 'Güncelle' : 'Ekle' }} Seçenek</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      surveys: [],
      selectedSurveyId: null,
      questions: [],
      currentQuestion: {
        question_text: '',
        question_type: 'text',
        options: []
      },
      currentOption: {
        option_text: ''
      },
      editingQuestion: false,
      editingOption: false
    };
  },
  async mounted() {
    await this.fetchSurveys();
  },
  methods: {
    async fetchSurveys() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/surveys', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.surveys = response.data.filter(survey => survey.status === 'active' || survey.status === 'inactive');
      } catch (error) {
        console.error('Anketler alınırken hata oluştu:', error);
      }
    },
    async fetchSurveyQuestions() {
      if (!this.selectedSurveyId) return;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/admin/surveys/${this.selectedSurveyId}/questions`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.questions = response.data;
      } catch (error) {
        console.error('Sorular alınırken hata oluştu:', error);
      }
    },
    addQuestion() {
      this.currentQuestion = {
        question_text: '',
        question_type: 'text',
        options: [] 
      };
      this.editingQuestion = false;
    },
    async createQuestion() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('/admin/surveyquestions', {
          survey_id: this.selectedSurveyId,
          question_text: this.currentQuestion.question_text,
          question_type: this.currentQuestion.question_type
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.questions.push(response.data);
        this.resetQuestionForm();
      } catch (error) {
        console.error('Soru oluşturulurken hata oluştu:', error);
      }
    },
    async updateQuestion() {
      try {
        const token = localStorage.getItem('token');
        await axios.put(`/admin/surveyquestions/${this.currentQuestion.question_id}`, {
          question_text: this.currentQuestion.question_text,
          question_type: this.currentQuestion.question_type
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.resetQuestionForm();
        await this.fetchSurveyQuestions();
      } catch (error) {
        console.error('Soru güncellenirken hata oluştu:', error);
      }
    },
    editQuestion(question) {
      this.currentQuestion = { ...question };
      this.editingQuestion = true;
    }, 
    async deleteQuestion(questionId) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/admin/surveyquestions/${questionId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.fetchSurveyQuestions(); 
      } catch (error) {
        console.error('Soru silinirken hata oluştu:', error);
      }
    },
    async createOption() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('/admin/surveyoptions', {
          question_id: this.currentQuestion.question_id,
          option_text: this.currentOption.option_text
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }); 
        const questionIndex = this.questions.findIndex(q => q.question_id === this.currentQuestion.question_id);
        if (questionIndex !== -1) {
          if (!this.questions[questionIndex].options) {
            this.$set(this.questions[questionIndex], 'options', []);
          }
          this.questions[questionIndex].options.push(response.data);
        }
        this.resetOptionForm();
      } catch (error) {
        console.error('Seçenek oluşturulurken hata oluştu:', error);
      }
    }, 
    addOption(question) {
      this.currentOption = {
        question_id: question.question_id,
        option_text: ''
      };
      this.editingOption = false;
    },
    async deleteOption(optionId) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/admin/surveyoptions/${optionId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.fetchSurveyQuestions();
      } catch (error) {
        console.error('Seçenek silinirken hata oluştu:', error);
      }
    },
    async updateOption() {
      try {
        const token = localStorage.getItem('token');
        await axios.put(`/admin/surveyoptions/${this.currentOption.option_id}`, {
          option_text: this.currentOption.option_text
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.resetOptionForm();
        await this.fetchSurveyQuestions();
      } catch (error) {
        console.error('Seçenek güncellenirken hata oluştu:', error);
      }
    },
    resetQuestionForm() {
      this.currentQuestion = { question_text: '', question_type: 'text', options: [] };
      this.editingQuestion = false;
    }, 
    resetOptionForm() {
      this.currentOption = { option_text: '' };
      this.editingOption = false;
    },
  }
};
</script>


<style scoped>
.container { 
  padding: 1em; 
} 
.form-area {
  background-color: #050505;
  margin-top: 1em;
  padding: 1em;
  border-radius: 1em;
} 
ul {
  list-style-type: none;
  padding: 0;
}

li {  
  padding: 0.5em;
  margin-bottom: 1em;
  border-radius: 0.5em;
  background-color: #0a0a0a;
  transition: background-color 0.3s;
}

li:hover {
  background-color: #000; 
}

button {  
  padding: 0.5em 2em;
  margin: 0.5em 1em 0.5em 0em;
  border-radius: 4px; 
  cursor: pointer;
  transition: background-color 0.3s;
}  

form {
  margin-top: 20px;
}

.question-form {
  background-color: #050505;
  padding: 2em;
  border: #202020 solid 1px;
}
.option-form {
  background-color: #080808;
  padding: 2em;
  margin: 0em 3em;
}

.ad-new-q {
  background-color: #127750; 
}
</style>  