<template>
  <div class="shop-website-management">
    <h3>Shop Website Yönetimi</h3>

    <h4>{{ isEdit ? "Web Sitesini Düzenle" : "Yeni Web Sitesi Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateWebsite() : createWebsite()">
      <select v-model="selectedWebsite.language_id" required>
        <option disabled value="">Dil Seçin</option>
        <option v-for="lang in languages" :value="lang.language_id" :key="lang.language_id">{{ lang.name }}</option>
      </select>
      <input v-model="selectedWebsite.no" type="number" placeholder="Sıra No" required />
      <input v-model="selectedWebsite.name" placeholder="İsim" required />
      <input v-model="selectedWebsite.link" placeholder="Link" required />
      <textarea v-model="selectedWebsite.description" placeholder="Açıklama"></textarea>
      <input v-model="selectedWebsite.manager" placeholder="Yönetici" />
      <textarea v-model="selectedWebsite.address" placeholder="Adres"></textarea>
      <input v-model="selectedWebsite.phone" placeholder="Telefon" />
      <label>
        <input type="checkbox" v-model="selectedWebsite.is_active" /> Aktif
      </label>
      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <h4>Mevcut Shop Web Siteleri</h4>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>No</th>
          <th>İsim</th>
          <th>Link</th>
          <th>Dil</th>
          <th>Aktif</th>
          <th>Düzenle</th>
          <th>Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="website in websites" :key="website.id">
          <td>{{ website.id }}</td>
          <td>{{ website.no }}</td>
          <td>{{ website.name }}</td>
          <td>{{ website.link }}</td>
          <td>{{ website.language.name }}</td>
          <td>{{ website.is_active ? "Evet" : "Hayır" }}</td>
          <td><button @click="editWebsite(website)">Düzenle</button></td>
          <td><button @click="confirmDelete(website.id)">Sil</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      websites: [],
      languages: [],
      selectedWebsite: {
        id: null,
        language_id: '',
        no: 0,
        name: '',
        link: '',
        description: '',
        manager: '',
        address: '',
        phone: '',
        is_active: true,
      },
      isEdit: false,
    };
  },
  methods: {
    async fetchWebsites() {
      const response = await axios.get('/admin/shopwebsites');
      this.websites = response.data.websites;
    },
    async fetchLanguages() {
      const response = await axios.get('/admin/languages');
      this.languages = response.data.languages;
    },
    async createWebsite() {
      await axios.post('/admin/shopwebsites', this.selectedWebsite);
      this.fetchWebsites();
      this.resetForm();
    },
    editWebsite(website) {
      this.selectedWebsite = { ...website };
      this.selectedWebsite.language_id = website.language.language_id;
      this.isEdit = true;
    },
    async updateWebsite() {
      await axios.put(`/admin/shopwebsites/${this.selectedWebsite.id}`, this.selectedWebsite);
      this.fetchWebsites();
      this.resetForm();
    },
    async deleteWebsite(id) {
      await axios.delete(`/admin/shopwebsites/${id}`);
      this.fetchWebsites();
    },
    confirmDelete(id) {
      if (confirm('Bu web sitesini silmek istediğinize emin misiniz?')) {
        this.deleteWebsite(id);
      }
    },
    resetForm() {
      this.selectedWebsite = {
        id: null,
        language_id: '',
        no: 0,
        name: '',
        link: '',
        description: '',
        manager: '',
        address: '',
        phone: '',
        is_active: true,
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    }
  },
  mounted() {
    this.fetchWebsites();
    this.fetchLanguages();
  }
};
</script>
