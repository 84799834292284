<template>
  <div class="education-lang-management">
    <h3>Eğitim - Dil İlişkilendirme Yönetimi</h3>
 
    <p class="alert info">Lütfen eğitim/dil ilişkilerini silmeyin. Kullanılmıyor ise pasif yapabilirsiniz.</p>
    <div class="table-header">
      <h4>Tüm İlişkiler</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" />
        Silme Butonlarını Göster
      </label>
    </div>

    <table class="relation-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Eğitim</th>
          <th>Dil</th>
          <th>Aktif</th>
          <th>Çeviri</th>
          <th>Düzenle</th>
          <th v-if="showDeleteButtons">Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="relation in relations" :key="relation.id">
          <td>{{ relation.id }}</td>
          <td>{{ relation.education ? relation.education.name : 'N/A' }}</td>
          <td>{{ relation.language ? relation.language.name : 'N/A' }}</td>
          <td>
            <input type="checkbox" :checked="relation.is_active" @change="toggleActive(relation)" />
          </td>
          <td> 
            <span v-if="!relation.editing" @click="enableEdit(relation)">
              {{ relation.education_name_translation || "Boş" }}
            </span>
            <input
              v-else
              type="text"
              v-model="relation.education_name_translation"
              @blur="saveTranslation(relation)"
              @keyup.enter="saveTranslation(relation)"
            />
          </td>
          <td>
            <button @click="editRelation(relation)">Düzenle</button>
          </td>
          <td v-if="showDeleteButtons">
            <button @click="confirmDelete(relation.id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>

    <h4>{{ isEdit ? "İlişkiyi Düzenle" : "Yeni İlişki Ekle" }}</h4> 
    <form @submit.prevent="isEdit ? updateRelation() : createRelation()">
      <div>
        <label>Eğitim:</label>
        <select v-model="selectedRelation.education_id" required>
          <option value="" disabled>Seçiniz</option>
          <option v-for="edu in educations" :key="edu.education_id" :value="edu.education_id">
            {{ edu.name }}
          </option>
        </select>
      </div>
      <div>
        <label>Dil:</label>
        <select v-model="selectedRelation.language_id" required>
          <option value="" disabled>Seçiniz</option>
          <option v-for="lang in languages" :key="lang.language_id" :value="lang.language_id">
            {{ lang.name }}
          </option>
        </select>
      </div>
      <div>
        <label>
          <input type="checkbox" v-model="selectedRelation.is_active" />
          Aktif
        </label>
      </div> 
      <div>
        <label>Çeviri:</label>
        <input type="text" v-model="selectedRelation.education_name_translation" placeholder="Eğitim çevirisini girin" />
      </div>
      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" type="button" @click="cancelEdit">İptal</button>
    </form>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      educations: [],
      languages: [],
      relations: [],
      selectedRelation: {
        id: null,
        education_id: '',
        language_id: '',
        is_active: true,
        education_name_translation: ''
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    async fetchEducations() {
      try {
        const response = await axios.get('/admin/educations');
        this.educations = response.data.educations;
      } catch (error) {
        console.error('Eğitimler alınırken hata:', error);
      }
    },
    async fetchLanguages() {
      try {
        const response = await axios.get('/admin/languages');
        this.languages = response.data.languages;
      } catch (error) {
        console.error('Diller alınırken hata:', error);
      }
    },
    async fetchRelations() {
      try {
        const response = await axios.get('/admin/educationlanguages');
        // Inline düzenleme için "editing" flag'i ekleyerek ilişkileri oluşturuyoruz.
        this.relations = response.data.educationLanguages.map(rel => ({
          ...rel,
          editing: false
        }));
      } catch (error) {
        console.error('İlişkiler alınırken hata:', error);
      }
    },
    async createRelation() {
      try {
        await axios.post('/admin/educationlanguages', {
          education_id: this.selectedRelation.education_id,
          language_id: this.selectedRelation.language_id,
          is_active: this.selectedRelation.is_active,
          education_name_translation: this.selectedRelation.education_name_translation,
        });
        this.fetchRelations();
        this.resetForm();
      } catch (error) {
        console.error('İlişki eklenirken hata:', error);
      }
    },
    editRelation(relation) {
      this.selectedRelation = { ...relation };
      this.isEdit = true;
    },
    async updateRelation() {
      try {
        await axios.put(`/admin/educationlanguages/${this.selectedRelation.id}`, {
          education_id: this.selectedRelation.education_id,
          language_id: this.selectedRelation.language_id,
          is_active: this.selectedRelation.is_active,
          education_name_translation: this.selectedRelation.education_name_translation,
        });
        this.fetchRelations();
        this.resetForm();
      } catch (error) {
        console.error('İlişki güncellenirken hata:', error);
      }
    },
    async deleteRelation(id) {
      try {
        await axios.delete(`/admin/educationlanguages/${id}`);
        this.fetchRelations();
      } catch (error) {
        console.error('İlişki silinirken hata:', error);
      }
    },
    confirmDelete(id) {
      if (confirm('Bu ilişkiyi silmek istediğinizden emin misiniz?')) {
        this.deleteRelation(id);
      }
    },
    resetForm() {
      this.selectedRelation = {
        id: null,
        education_id: '',
        language_id: '',
        is_active: true,
        education_name_translation: ''
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
    async toggleActive(relation) {
      try {
        await axios.put(`/admin/educationlanguages/${relation.id}`, {
          education_id: relation.education_id,
          language_id: relation.language_id,
          is_active: !relation.is_active,
          education_name_translation: relation.education_name_translation,
        });
        relation.is_active = !relation.is_active;
      } catch (error) {
        console.error('Aktiflik güncellenirken hata:', error);
      }
    },
    enableEdit(relation) {
      relation.editing = true;
    },
    async saveTranslation(relation) {
      try {
        await axios.put(`/admin/educationlanguages/${relation.id}`, {
          education_id: relation.education_id,
          language_id: relation.language_id,
          is_active: relation.is_active,
          education_name_translation: relation.education_name_translation,
        });
        relation.editing = false;
      } catch (error) {
        console.error('Çeviri güncellenirken hata:', error);
      }
    },
  },
  mounted() {
    this.fetchEducations();
    this.fetchLanguages();
    this.fetchRelations();
  },
};
</script>
