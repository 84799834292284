<template>
  <div>  
    <div class="head">SUNUCU SAĞLIĞI & TEST
    </div>
    <p style="text-align: center;">Doğrudan sunucu bağlantısı için PRIVATE key girişi yapınız.</p>
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
    <p style="color: red;">Please Enter SSH Key</p>
    <input type="text">
  </div>
</template>


<script>  

export default {
  data() {
    return {
      currentComponent: 'DomUpdates', 
      components: [
        { name: 'Kaynak tüketimi', component: 'DomUpdates' }, 
        { name: 'CRONS', component: 'SubCategoryManager' },
        { name: 'Mail Logs', component: 'ProductMain' }, 
        { name: 'İstatistik', component: 'ActivistyLog' }, 
        { name: 'Kullanıcı grubu aktiviteleri', component: 'AcstivityLog' },  
        { name: 'Sistem Sağlığı', component: 'PromotionMain' }, 
        { name: 'Backend Logs', component: 'ActivityLog' },
        { name: 'Front Logs', component: 'bActivityLog' },  
        { name: 'IP Logs', component: 'fActivityLog' },
      ]
    };
  },

  components: {   
  },
  
};
</script>
  
<style scoped>
.button-container {
    align-items: center;
    justify-content: center;
    padding: 1em;
    background-color: black;
    } 
    .head {
    text-align: center;
    font-size: 2em; font-weight: 300;
    } 
    .button-container button.active {
    background-color: #691111;
    color: #ffffff; 
    } 
</style>
  