<template>
    <div>
      <h3>Hedef Bölgeler</h3>
<div>OPTİMİZE EDİLECEK ve GELİŞTİRİLECEK</div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Alan</th>
            <th>Ülke</th>
            <th>Fiyat</th>
            <th>Hedef Tarihi</th>
            <th>Notlar</th>
            <th>Aktif</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="area in areas" :key="area.area_id">
            <td>{{ area.area_id }}</td>
            <td>{{ area.name }}</td>
            <td>{{ area.country }}</td>
            <td>{{ area.price }}</td>
            <td>{{ area.target_date }}</td>
            <td>{{ area.note }}</td>
            <td>{{ area.is_active ? "Evet" : "Hayır" }}</td>
            <td><button @click="editArea(area)">Düzenle</button></td>
            <td><button @click="deleteArea(area.area_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>

      <br>
      <form @submit.prevent="isEdit ? updateArea() : createArea()">
        <input v-model="selectedArea.name" placeholder="Alan Adı" required />
        <input v-model="selectedArea.country" placeholder="Ülke" required />
        <input v-model="selectedArea.price" type="number" step="0.01" placeholder="Fiyat" required />
        <input v-model="selectedArea.target_date" type="date" placeholder="Hedef Tarihi" required />
        <textarea v-model="selectedArea.note" placeholder="Not"></textarea>
        <label><input type="checkbox" v-model="selectedArea.is_active" /> Aktif</label>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="resetForm" type="button">İptal</button>
      </form>
  
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        areas: [],
        selectedArea: { area_id: null, name: '', country: '', price: '', target_date: '', note: '', is_active: true },
        isEdit: false,
      };
    },
    methods: {
      async fetchAreas() {
        const res = await axios.get('/admin/refika/targetareas');
        this.areas = res.data.areas;
      },
      async createArea() {
        await axios.post('/admin/refika/targetareas', this.selectedArea);
        this.fetchAreas();
        this.resetForm();
      },
      editArea(area) {
        this.selectedArea = { ...area };
        this.isEdit = true;
      },
      async updateArea() {
        await axios.put(`/admin/refika/targetareas/${this.selectedArea.area_id}`, this.selectedArea);
        this.fetchAreas();
        this.resetForm();
      },
      async deleteArea(id) {
        await axios.delete(`/admin/refika/targetareas/${id}`);
        this.fetchAreas();
      },
      resetForm() {
        this.selectedArea = { area_id: null, name: '', country: '', price: '', target_date: '', note: '', is_active: true };
        this.isEdit = false;
      }
    },
    mounted() {
      this.fetchAreas();
    },
  };
  </script>
  