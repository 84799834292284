<template>
  <div class="container">
    <h4>E-posta Tercih Yöneticisi</h4>

    <!-- Tercihler Tablosu -->
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Kullanıcı</th>
          <th>Kategori</th>
          <th>Abone</th>
          <th>Güncellenme Tarihi</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="preference in preferences" :key="preference.preference_id">
          <td>{{ preference.preference_id }}</td>
          <td>{{ preference.user?.user_name || 'Yok' }}</td>
          <td>{{ preference.category?.name || 'Yok' }}</td>
          <td>{{ preference.is_subscribed ? 'Evet' : 'Hayır' }}</td>
          <td>{{ preference.updated_at }}</td>
          <td>
            <button @click="editPreference(preference)">Düzenle</button>
            <button @click="confirmDelete(preference.preference_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Tercih Ekleme/Düzenleme Formu -->
    <div class="form-area">
      <h4>{{ isEditing ? 'Tercihi Düzenle' : 'Yeni Tercih Ekle' }}</h4>
      <form @submit.prevent="submitPreference">
        <select v-model="form.user_id" required>
          <option disabled value="">Kullanıcı Seç</option>
          <option v-for="user in users" :key="user.user_id" :value="user.user_id">
            {{ user.user_name }} ({{ user.e_mail }})
          </option>
        </select>
        <select v-model="form.category_id" required>
          <option disabled value="">Kategori Seç</option>
          <option v-for="category in categories" :key="category.category_id" :value="category.category_id">
            {{ category.name }}
          </option>
        </select>
        <select v-model="form.is_subscribed" required>
          <option :value="true">Abone</option>
          <option :value="false">Abone Değil</option>
        </select>
        <button type="submit">{{ isEditing ? 'Güncelle' : 'Ekle' }}</button>
        <button type="button" @click="resetForm">İptal</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    preferences: [],
    users: [],
    categories: [],
    form: {
      preference_id: null,
      user_id: '',
      category_id: '',
      is_subscribed: true,
    },
    isEditing: false,
  };
},
async mounted() {
  await this.fetchPreferences();
  await this.fetchUsers();
  await this.fetchCategories();
},
methods: {
  async fetchPreferences() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailpreferences', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.preferences = response.data;
    } catch (error) {
      console.error('Tercihler alınırken hata oluştu:', error);
    }
  },
  async fetchUsers() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/mainusers', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.users = response.data;
    } catch (error) {
      console.error('Kullanıcılar alınırken hata oluştu:', error);
    }
  },
  async fetchCategories() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailcategories', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.categories = response.data;
    } catch (error) {
      console.error('Kategoriler alınırken hata oluştu:', error);
    }
  },
  editPreference(preference) {
    this.form = { ...preference };
    this.isEditing = true;
  },
  confirmDelete(preferenceId) {
    if (confirm('Bu tercihi silmek istediğinize emin misiniz?')) {
      this.deletePreference(preferenceId);
    }
  },
  async deletePreference(preferenceId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/emailpreferences/${preferenceId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchPreferences();
    } catch (error) {
      console.error('Tercih silinirken hata oluştu:', error);
    }
  },
  async submitPreference() {
    try {
      const token = localStorage.getItem('token');
      const method = this.form.preference_id ? 'put' : 'post';
      const url = this.form.preference_id
        ? `/admin/emailpreferences/${this.form.preference_id}`
        : '/admin/emailpreferences';
      await axios[method](url, this.form, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchPreferences();
      this.resetForm();
    } catch (error) { 
      console.error('Tercih kaydedilirken hata oluştu:', error);
    }
  },
  resetForm() {
    this.form = {
      preference_id: null,
      user_id: '',
      category_id: '',
      is_subscribed: true,
    };
    this.isEditing = false;
  },
},
};
</script>

    