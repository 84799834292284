<template>
  <div class="quote-management">
    <h3>Alıntı Yönetimi</h3>

    <h4>{{ isEdit ? "Alıntıyı Düzenle" : "Yeni Alıntı Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateQuote() : createQuote()">
      <label for="type">Tür</label>
      <select v-model="selectedQuote.type" id="type" required>
        <option value="promo">promo</option>
        <option value="shop">shop</option>
        <option value="ai">ai</option>
        <option value="uygulama">uygulama</option>
        <option value="portal">portal</option>
        <option value="nrs">nrs</option>
      </select>

      <label for="name">Adı</label>
      <input v-model="selectedQuote.name" type="text" id="name" placeholder="Ad (Örneğin: Albert Einstein)" required />

      <label for="content">İçerik</label>
      <textarea v-model="selectedQuote.content" id="content" placeholder="Alıntı Metni" required></textarea>

      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Alıntılar</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table class="quote-table">
      <thead>
        <tr>
          <th>Tür</th>
          <th>Ad</th>
          <th>İçerik</th>
          <th>Oluşturulma Tarihi</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="quote in quotes" :key="quote.id">
          <td>{{ quote.type }}</td>
          <td>{{ quote.name }}</td>
          <td>{{ quote.content }}</td>
          <td>{{ formatDate(quote.created_at) }}</td>
          <td>
            <button @click="editQuote(quote)">Düzenle</button>
            <button
              v-if="showDeleteButtons"
              class="delete"
              @click="confirmDelete(quote.id)"
            >
              Sil
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      quotes: [],
      selectedQuote: {
        id: null,
        type: "promo", 
        name: "",
        content: "",
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    async fetchQuotes() {
      try {
        const response = await axios.get("/admin/quotes");
        this.quotes = response.data.data || [];
        console.log("Alıntılar başarıyla alındı:", this.quotes);
      } catch (error) {
        console.error("Alıntılar alınırken hata oluştu:", error);
      }
    },
    async createQuote() {
      try {
        const response = await axios.post("/admin/quote", this.selectedQuote);
        console.log("Alıntı başarıyla eklendi:", response.data);
        this.fetchQuotes();
        this.resetForm();
      } catch (error) {
        console.error("Alıntı eklenirken hata oluştu:", error);
      }
    },
    async updateQuote() {
      try {
        const response = await axios.put(`/admin/quote/${this.selectedQuote.id}`, this.selectedQuote);
        console.log("Alıntı başarıyla güncellendi:", response.data);
        this.fetchQuotes();
        this.resetForm();
      } catch (error) {
        console.error("Alıntı güncellenirken hata oluştu:", error);
      }
    },
    async deleteQuote(id) {
      try {
        const response = await axios.delete(`/admin/quote/${id}`);
        console.log("Alıntı başarıyla silindi:", response.data);
        this.fetchQuotes();
      } catch (error) {
        console.error("Alıntı silinirken hata oluştu:", error);
      }
    },
    editQuote(quote) {
      this.selectedQuote = { ...quote };
      this.isEdit = true;
    },
    confirmDelete(id) {
      if (confirm("Bu alıntıyı silmek istediğinizden emin misiniz?")) {
        this.deleteQuote(id);
      }
    },
    resetForm() {
      this.selectedQuote = {
        id: null,
        type: "promo",  
        name: "",
        content: "",
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString("tr-TR", options);
    },
  },
  mounted() {
    this.fetchQuotes();
  },
};
</script>
