    <template>
        <div class="product-table">
          <h3>Ürün Özellik Yönetimi</h3>

          <div class="alert info">  <b>Bu özellik kullanımdışı</b> <br> Kullanılmaya karar verilmesi halinde: <br>
              Her ürün için benzersiz özellikler eklenebiliyor. <br> ilerleyen dönemlerde etiket ya da gruplama amaçlı kullanılabilir... <br> <br> 
              Model yapısı <br>
              feature_id product_id feature_name feature_value created_at

          </div>
          
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Seri No</th>
                <th>Ürün Adı</th>
                <th>Açıklama</th>
                <th>Stok Miktarı</th>
                <th>Status</th>
                <th>Oluşturulma Tarihi</th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in products" :key="product.product_id">
                <td>{{ product.product_id }}</td>
                <td>{{ product.product_serial }}</td>
                <td>{{ product.product_name }}</td>
                <td>{{ product.description }}</td>
                <td>{{ product.stock_amount }}</td>
                <td>{{ product.status }}</td>
                <td>{{ formatDate(product.created_at) }}</td>
                <td>
                  <button class="btn confirm" @click="openFeaturesModal(product)">
                    Ürün Özellikleri
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
      
          <!-- Ürün Özellikleri Modal -->
          <div v-if="showModal" class="modal-overlay">
            <div class="modal-content">
              <h3><b>{{ selectedProduct.product_name }}</b> - Özellikleri</h3>
              <!-- CRUD İşlemleri için basit form örneği -->
              <div>
                <label>Özellik Adı:</label>
                <input v-model="featureForm.feature_name" type="text" />
              </div>
              <div>
                <label>Özellik Değeri:</label>
                <input v-model="featureForm.feature_value" type="text" />
              </div>
              <div class="modal-actions">
                <button class="btn confirm" @click="createFeature">Oluştur</button>
                <button class="btn btn-warning" @click="updateFeature">Güncelle</button>
                <button class="btn delete" @click="deleteFeature">Sil</button>
                <button class="btn btn-secondary" @click="closeModal">Kapat</button>
              </div>
      
              <!-- Listeleme: Ürüne ait özellikler -->
              <div>
                <h4>Özellik Listesi</h4>
                <ul>
                  <li v-for="feature in productFeatures" :key="feature.feature_id">
                    <strong>{{ feature.feature_name }}:</strong> {{ feature.feature_value }}
                    <button @click="loadFeature(feature)">Düzenle</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
      
      <script>
      import axios from "axios";
      
      export default {
        name: "ProductTable",
        data() {
          return {
            products: [],
            showModal: false,
            selectedProduct: {},
            productFeatures: [],
            featureForm: {
              feature_id: null,
              feature_name: "",
              feature_value: "",
            },
          };
        },
        methods: {
          // Ürünleri API'den çekme
          async fetchProducts() {
            try {
              const response = await axios.get("/admin/products");
              this.products = response.data;
            } catch (error) {
              console.error("Ürünler alınırken hata:", error);
            }
          },
          // Tarih formatlama fonksiyonu
          formatDate(dateString) {
            const options = { year: "numeric", month: "long", day: "numeric" };
            return new Date(dateString).toLocaleDateString("tr-TR", options);
          },
          // Modal açma işlemi
          openFeaturesModal(product) {
            this.selectedProduct = product;
            this.showModal = true;
            this.featureForm = { feature_id: null, feature_name: "", feature_value: "" };
            this.fetchProductFeatures(product.product_id);
          },
          // Modal kapatma işlemi
          closeModal() {
            this.showModal = false;
            this.selectedProduct = {};
            this.productFeatures = [];
          },
          // Seçilen ürünün özelliklerini API'den çekme
          async fetchProductFeatures(productId) {
            try {
              const response = await axios.get(`/admin/products/${productId}/features`);
              this.productFeatures = response.data;
            } catch (error) {
              console.error("Ürün özellikleri alınırken hata:", error);
            }
          },
          // Özellik oluşturma
          async createFeature() {
            try {
              const payload = {
                ...this.featureForm,
                product_id: this.selectedProduct.product_id,
              };
              const response = await axios.post(`/admin/products/${this.selectedProduct.product_id}/features`, payload);
              this.productFeatures.push(response.data);
              this.featureForm = { feature_id: null, feature_name: "", feature_value: "" };
            } catch (error) {
              console.error("Özellik oluşturulurken hata:", error);
            }
          },
          // Özellik güncelleme
          async updateFeature() {
            if (!this.featureForm.feature_id) {
              alert("Lütfen güncellemek için bir özellik seçiniz!");
              return;
            }
            try {
              const response = await axios.put(
                `/admin/products/${this.selectedProduct.product_id}/features/${this.featureForm.feature_id}`,
                this.featureForm
              );
              // Güncellenen özelliği listede değiştiriyoruz
              const index = this.productFeatures.findIndex(
                (f) => f.feature_id === this.featureForm.feature_id
              );
              if (index !== -1) {
                this.$set(this.productFeatures, index, response.data);
              }
              this.featureForm = { feature_id: null, feature_name: "", feature_value: "" };
            } catch (error) {
              console.error("Özellik güncellenirken hata:", error);
            }
          },
          // Özellik silme
          async deleteFeature() {
            if (!this.featureForm.feature_id) {
              alert("Lütfen silmek için bir özellik seçiniz!");
              return;
            }
            try {
              await axios.delete(
                `/admin/products/${this.selectedProduct.product_id}/features/${this.featureForm.feature_id}`
              );
              this.productFeatures = this.productFeatures.filter(
                (f) => f.feature_id !== this.featureForm.feature_id
              );
              this.featureForm = { feature_id: null, feature_name: "", feature_value: "" };
            } catch (error) {
              console.error("Özellik silinirken hata:", error);
            }
          }, 
          loadFeature(feature) {
            this.featureForm = { ...feature };
          },
        },
        mounted() {
          this.fetchProducts();
        },
      };
      </script>
      
      <style scoped> 
      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.8); 
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .modal-content {
        background: #000000;
        padding: 2rem;
        border-radius: 4px;
        width: 80%;
        max-width: 1080px;
        border: 1px solid #1f1f1f;
      }
      .modal-actions {
        display: flex;
      }
      .modal-actions button {
        margin-right: 0.5rem;
        margin-top: 1rem;
      }
      </style>
      