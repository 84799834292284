<template>
  <div class="user-management">
    <h3>Kullanıcı Yönetimi</h3> 

    <div class="alert info"> 
      Kendi güvenliğimiz için, bir kullanıcıyı silmemek önemlidir. <br> Eğer silerseniz tüm veriler kalıcı olarak silinir. <br> Bunun yerine, Kullanıcıyı düzenle butonu ile "Aktif" yazan hesap durumunu "Askıya Alındı" olarak değiştirebilirsiniz. Erişimlerini kaybeder.
    </div>

    <h3>{{ isEdit ? "Kullanıcı Düzenle" : "Yeni Kullanıcı Ekle" }}</h3>
    <form @submit.prevent="isEdit ? updateUser() : createUser()">
      <input v-model="selectedUser.user_name" placeholder="Kullanıcı Adı" required />
      <input v-model="selectedUser.e_mail" placeholder="E-posta" type="email" required />
      <input v-if="!isEdit" v-model="selectedUser.password" placeholder="Şifre" type="password" required />
      <input v-model="selectedUser.first_name" placeholder="Ad" />
      <input v-model="selectedUser.last_name" placeholder="Soyad" />
      <input v-model="selectedUser.phone_number" placeholder="Telefon" />
      <input v-model="selectedUser.country" placeholder="Ülke" />
      <input v-model="selectedUser.address" placeholder="Adres" />
      <input v-model="selectedUser.gender" placeholder="Cinsiyet" />
      <input v-model="selectedUser.birth_date" placeholder="Doğum Tarihi" type="date" />
      <select v-model="selectedUser.account_status">
        <option value="Active">Aktif</option>
        <option value="Suspended">Askıya Alındı</option>
        <option value="Inactive">Pasif</option>
      </select>
      <select v-model="selectedUser.role_id">
        <option v-for="role in roles" :key="role.role_id" :value="role.role_id">{{ role.role_name }}</option>
      </select>
      <button type="submit">{{ isEdit ? "Güncelle" : "Kullanıcı Ekle" }}</button>
    </form>   

    <div class="table-header">
      <h3>Tüm Kullanıcılar</h3>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>
        <!-- Arama Kutuları -->
    <div class="search-filters">
      <input v-model="searchEmail" placeholder="E-posta ile ara" />
      <input v-model="searchPhone" placeholder="Telefon ile ara" />
    </div>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Kullanıcı Adı</th>
          <th>E-posta</th>
          <th>Ad</th>
          <th>Soyad</th>
          <th>Telefon</th>
          <th>Ülke</th>
          <th>Adres</th>
          <th>Cinsiyet</th>
          <th>Doğum Tarihi</th>
          <th>Durum</th>
          <th>Son Giriş</th>
          <th>Rol</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in filteredUsers" :key="user.user_id">
          <td>{{ user.user_id }}</td>
          <td>{{ user.user_name }}</td>
          <td>{{ user.e_mail }}</td>
          <td>{{ user.first_name }}</td>
          <td>{{ user.last_name }}</td>
          <td>{{ user.phone_number }}</td>
          <td>{{ user.country }}</td>
          <td>{{ user.address }}</td>
          <td>{{ user.gender }}</td>
          <td>{{ user.birth_date }}</td>
          <td>{{ user.account_status }}</td>
          <td>{{ formatDateTime(user.last_login) }}</td>
          <td>{{ getRoleName(user.role_id) }}</td>
          <td>
            <button @click="editUser(user)">Düzenle</button>
            <button class="deletebutton" v-if="showDeleteButtons" @click="deleteUser(user.user_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      users: [],
      roles: [],
      selectedUser: {
        user_name: '',
        e_mail: '',
        password: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        country: '',
        address: '',
        gender: '',
        birth_date: '',
        account_status: 'Active',
        role_id: 16,
      },
      isEdit: false,
      showDeleteButtons: false,
      searchEmail: '',
      searchPhone: '',
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user => 
        (this.searchEmail ? user.e_mail.toLowerCase().includes(this.searchEmail.toLowerCase()) : true) &&
        (this.searchPhone ? user.phone_number.includes(this.searchPhone) : true)
      );
    }
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get('/admin/mainusers');
        this.users = response.data;
      } catch (error) {
        console.error('Kullanıcılar getirilirken hata:', error);
      }
    },
    async fetchRoles() {
      try {
        const response = await axios.get('/admin/roles');
        this.roles = response.data;
      } catch (error) {
        console.error('Roller getirilirken hata:', error);
      }
    },
    async createUser() {
      try {
        await axios.post('/admin/mainusers', this.selectedUser);
        this.fetchUsers();
        this.resetForm();
      } catch (error) {
        console.error('Kullanıcı oluşturulurken hata:', error);
      }
    },
    async updateUser() {
      try {
        await axios.put(`/admin/mainusers/${this.selectedUser.user_id}`, this.selectedUser);
        this.fetchUsers();
        this.resetForm();
        this.isEdit = false;
      } catch (error) {
        console.error('Kullanıcı güncellenirken hata:', error);
      }
    },
    editUser(user) {
      this.selectedUser = { ...user };
      this.isEdit = true;
    },
    async deleteUser(id) {
      if (confirm('Bu kullanıcıyı silmek istediğinize emin misiniz?')) {
        try {
          await axios.delete(`/admin/mainusers/${id}`);
          this.fetchUsers();
        } catch (error) {
          console.error('Kullanıcı silinirken hata:', error);
        }
      }
    },
    resetForm() {
      this.selectedUser = {
        user_name: '',
        e_mail: '',
        password: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        country: '',
        address: '',
        gender: '',
        birth_date: '',
        account_status: 'Active',
        role_id: 16,
      };
      this.isEdit = false;
    },
    getRoleName(roleId) {
      const role = this.roles.find(r => r.role_id === roleId);
      return role ? role.role_name : 'Bilinmiyor';
    }
  },
  mounted() {
    this.fetchUsers();
    this.fetchRoles();
  },
};
</script>
 

<style scoped>
.search-filters {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
} 
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 2rem; 
}

button {
  margin-right: 10px;
}
</style>
