<template>
    <div class="subcategory-management">
      <div class="table-header">
        <h3>Tüm Alt Kategoriler</h3>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table>
        <thead>
          <tr>
            <th>Alt Kategori Adı</th>
            <th>Bağlı Olduğu Kategori</th>
            <th>Oluşturulma Tarihi</th>
            <th>Güncelle</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="subcategory in subcategories" :key="subcategory.subcategory_id">
            <td>{{ subcategory.sub_category_name }}</td>
            <td>{{ getCategoryName(subcategory.category_id) }}</td>
            <td>{{ formatDateTime(subcategory.created_at) }}</td>
            <td>
              <button @click="editSubcategory(subcategory)">Güncelle</button>
              <button class="deletebutton" v-if="showDeleteButtons" @click="confirmDelete(subcategory.subcategory_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <form @submit.prevent="isEdit ? updateSubcategory() : createSubcategory()">
        <h4>{{ isEdit ? "Alt Kategoriyi Düzenle" : "Yeni Alt Kategori Ekle" }}</h4>
        <input v-model="selectedSubcategory.sub_category_name" placeholder="Alt Kategori Adı" required />
  
        <select v-model="selectedSubcategory.category_id" required>
          <option value="" disabled>Kategori Seçin</option>
          <option v-for="category in categories" :key="category.category_id" :value="category.category_id">
            {{ category.category_name }}
          </option>
        </select>
  
        <div class="formbutton">
          <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
          <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        subcategories: [],
        categories: [],
        showDeleteButtons: false,
        selectedSubcategory: {
          subcategory_id: null,
          category_id: "",
          sub_category_name: "",
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchSubcategories() {
        try {
          const response = await axios.get("/admin/subcategories");
          this.subcategories = response.data;
        } catch (error) {
          console.error("Alt kategoriler alınırken hata oluştu:", error);
        }
      },
      async fetchCategories() {
        try {
          const response = await axios.get("/admin/categories");
          this.categories = response.data;
        } catch (error) {
          console.error("Kategoriler alınırken hata oluştu:", error);
        }
      },
      async createSubcategory() {
        try {
          await axios.post("/admin/subcategories", this.selectedSubcategory);
          this.fetchSubcategories();
          this.resetForm();
        } catch (error) {
          console.error("Alt kategori eklenirken hata oluştu:", error);
        }
      },
      editSubcategory(subcategory) {
        this.selectedSubcategory = { ...subcategory };
        this.isEdit = true;
      },
      async updateSubcategory() {
        try {
          await axios.put(`/admin/subcategories/${this.selectedSubcategory.subcategory_id}`, this.selectedSubcategory);
          this.fetchSubcategories();
          this.resetForm();
        } catch (error) {
          console.error("Alt kategori güncellenirken hata oluştu:", error);
        }
      },
      async deleteSubcategory(subcategory_id) {
        try {
          await axios.delete(`/admin/subcategories/${subcategory_id}`);
          this.fetchSubcategories();
        } catch (error) {
          console.error("Alt kategori silinirken hata oluştu:", error);
        }
      },
      confirmDelete(subcategory_id) {
        if (confirm("Bu alt kategoriyi silmek istediğinize emin misiniz?")) {
          this.deleteSubcategory(subcategory_id);
        }
      },
      getCategoryName(category_id) {
        const category = this.categories.find((cat) => cat.category_id === category_id);
        return category ? category.category_name : "Bilinmiyor";
      },
      resetForm() {
        this.selectedSubcategory = {
          subcategory_id: null,
          category_id: "",
          sub_category_name: "",
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchSubcategories();
      this.fetchCategories();
    },
  };
  </script>
  