<template>
    <div class="productgroup-management">
      <h3>Ürün Grupları / Ürün Setleri</h3>

      <div class="alert info">
        Burada çeşitli setler için isim tanımları yapabilirsiniz. <br> 
        Daha sonra bu setleri farklı promosyonlarda kullanabilirsiniz. <br>
        Burada oluşturduğunuz set isimlerini sadece siz görürsünüz.
      </div>
      <form @submit.prevent="isEdit ? updateGroup() : createGroup()">
        <h4>{{ isEdit ? "Grubu Düzenle" : "Yeni Grup Ekle" }}</h4>
        <input v-model="selectedGroup.productgroup_name" placeholder="Grup Adı" required />
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>

      <table>
        <thead>
          <tr>
            <th>Grup Adı</th>
            <th>Oluşturulma Tarihi</th>
            <th>Güncelle</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in productGroups" :key="group.productgroup_id">
            <td>{{ group.productgroup_name }}</td>
            <td>{{ formatDateTime(group.created_at) }}</td>
            <td>
              <button @click="editGroup(group)">Güncelle</button>
              <button class="deletebutton" @click="confirmDelete(group.productgroup_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
   
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        productGroups: [],
        selectedGroup: {
          productgroup_id: null,
          productgroup_name: "",
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchGroups() {
        try {
          const response = await axios.get("/admin/productgroups");
          this.productGroups = response.data;
        } catch (error) {
          console.error("Ürün grupları alınırken hata:", error);
        }
      },
      async createGroup() {
        try {
          await axios.post("/admin/productgroups", this.selectedGroup);
          this.fetchGroups();
          this.resetForm();
        } catch (error) {
          console.error("Ürün grubu eklenirken hata:", error);
        }
      },
      editGroup(group) {
        this.selectedGroup = { ...group };
        this.isEdit = true;
      },
      async updateGroup() {
        try {
          await axios.put(`/admin/productgroups/${this.selectedGroup.productgroup_id}`, this.selectedGroup);
          this.fetchGroups();
          this.resetForm();
        } catch (error) {
          console.error("Ürün grubu güncellenirken hata:", error);
        }
      },
      async deleteGroup(id) {
        try {
          await axios.delete(`/admin/productgroups/${id}`);
          this.fetchGroups();
        } catch (error) {
          console.error("Ürün grubu silinirken hata:", error);
        }
      },
      confirmDelete(id) {
        if (confirm("Bu grubu silmek istiyor musunuz?")) {
          this.deleteGroup(id);
        }
      },
      resetForm() {
        this.selectedGroup = { productgroup_id: null, productgroup_name: "" };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchGroups();
    },
  };
  </script>
  