<template>
    <div class="settings-table">
      <div class="alert info">Karmaşık bir sorgu olduğu için tablo geç yüklenebilir. Lütfen bekleyiniz.</div>
      <h2>Kullanıcı Portal Ayarları ve İstatistikler</h2>
      <div class="stats">
        <div v-for="col in columns" :key="col.key" class="stat-item">
          <h3>{{ col.label }}</h3>
          <p>Aktif: {{ getStats(col.key).active }} ({{ getPercentage(col.key, 'active') }}%)</p>
          <p>Pasif: {{ getStats(col.key).passive }} ({{ getPercentage(col.key, 'passive') }}%)</p>
          <p>Boş: {{ getStats(col.key).empty }} ({{ getPercentage(col.key, 'empty') }}%)</p>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Kullanıcı Adı</th>
            <th v-for="(col, idx) in columns" :key="idx">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in settings" :key="row.id">
            <td>{{ row.user_name }}</td>
            <td v-for="col in columns" :key="col.key">
              <template v-if="col.key === 'pin'">
                <input 
                  type="number" 
                  v-model="row[col.key]" 
                  @change="updateField(row.id, col.key, row[col.key])" 
                  class="pin-input"
                />
              </template>
              <template v-else>
                <input 
                  type="checkbox" 
                  :checked="row[col.key]" 
                  @change="updateField(row.id, col.key, !row[col.key])"
                />
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AllUserPortalSettings',
    data() {
      return {
        settings: [],
        columns: [
          { key: 'is_advanced_mode_on', label: 'Gelişmiş Mod' },
          { key: 'is_note_notification_on', label: 'Not Bildirimleri' },
          { key: 'is_appoinment_notification_on', label: 'Randevu Bildirimleri' },
          { key: 'is_accounting_notification_on', label: 'Muhasebe Bildirimleri' },
          { key: 'is_whatsapp_notification_on', label: 'WhatsApp Bildirimleri' },
          { key: 'is_email_notification_on', label: 'E-posta Bildirimleri' },
          { key: 'is_local_notification_on', label: 'Yerel Bildirimler' },
          { key: 'is_pinlock_active', label: 'PIN Kilidi Aktif' },
          { key: 'pin', label: 'PIN Kodu' }
        ] 
      };
    },
    methods: {
      async fetchSettings() {
        try {
          const response = await axios.get('/admin/all-userportalsettings');
          this.settings = response.data.data;
        } catch (error) {
          console.error('Ayarlar alınırken hata:', error);
        }
      },
      async updateField(id, field, value) {
        try {
          await axios.put(`/admin/auserportalsettings/${id}`, { field, value });
        } catch (error) {
          console.error('Alan güncellenirken hata:', error);
        }
      },
      getStats(key) {
        let stats = { active: 0, passive: 0, empty: 0 };
        for (const row of this.settings) {
          if (key === 'pin') {
            if (!row[key]) {
              stats.empty++;
            } else {
              stats.active++;
            }
          } else {
            if (row[key] === true) {
              stats.active++;
            } else if (row[key] === false) {
              stats.passive++;
            } else {
              stats.empty++;
            }
          }
        }
        return stats;
      },
      getPercentage(key, type) {
        const stats = this.getStats(key);
        const total = stats.active + stats.passive + stats.empty;
        if (total === 0) return 0;
        return ((stats[type] / total) * 100).toFixed(1);
      }
    },
    mounted() {
      this.fetchSettings();
    }
  };
  </script>
  
  <style scoped>
  .settings-table {
    max-width: 100%;
    margin: 1rem auto;
    padding: 1em;
    background-color: #050505;
    border-radius: 8px;
    overflow: auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 1.5rem;
  }
  
  .stat-item {
    padding: 0.5em;
    border: 1px solid #086b68;
    margin: 0.5em;
    border-radius: 4px;
    width: 200px;
    text-align: center;
  }
  
  table {
    width: 100%;
    border-collapse: collapse; 
  }
  
  th, td {
    padding: 0.5em;
    text-align: center;
    border: 1px solid #086b68;
  }
  
  input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .pin-input {
    width: 70px;
  }
  </style>
  