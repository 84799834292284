<template>
    <div>
      <div class="sub-button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div>
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script>
import UserEducationRecord from './UserEducationRecord.vue';
import UserStatistics from './UserStatistics.vue';
import UserTests from './UserTests.vue';

  
  
  export default {
    data() {
      return {
        currentComponent: 'UserEducationRecord', 
        components: [ 
          { name: 'Kullanıcı Eğitim Kayıtları', component: 'UserEducationRecord' }, 
          { name: 'Kullanıcı Test Kayıtları', component: 'UserTests' }, 
          { name: 'Kullanıcı İstatistikleri', component: 'UserStatistics' }, 
        ],
      };
    },
    components: {  
      UserEducationRecord, 
      UserTests,
      UserStatistics
    },
  };
  </script>
    
  <style scoped> 
  </style>
    