<template>
  <div class="subcategory-management">
    <h3>Alt Kategoriler</h3>

    <h4>Yeni Alt Kategori Ekle</h4>
    <form @submit.prevent="createSubcategory">
      <input v-model="newSubcategory.name" placeholder="Alt Kategori Adı" required />
      <input v-model="newSubcategory.description" placeholder="Açıklama" />
      <select v-model="newSubcategory.main_category_id" required>
        <option disabled value="">Ana Kategoriyi Seçin</option>
        <option v-for="category in categories" :key="category.id" :value="category.id">
          {{ category.name }}
        </option>
      </select>
      <input v-model="newSubcategory.sira_no" type="number" placeholder="Sıra No" />
      <textarea v-model="newSubcategory.icon" placeholder="SVG İkon Kodu"></textarea>
      <button type="submit">Alt Kategori Ekle</button>
    </form>

    <table class="subcategory-table">
      <thead>
        <tr>
          <th>Alt Kategori Adı</th>
          <th>Açıklama</th>
          <th>Ana Kategori</th>
          <th>Sıra No</th>
          <th>İkon</th>
          <th>Mobil Görsel</th>
          <th>Web Görsel</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subcategory in subcategories" :key="subcategory.id">
          <td><input v-model="subcategory.name" @change="updateSubcategory(subcategory)" /></td>
          <td><input v-model="subcategory.description" @change="updateSubcategory(subcategory)" /></td>
          <td>
            <select v-model="subcategory.main_category_id" @change="updateSubcategory(subcategory)">
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </td>
          <td><input type="number" v-model="subcategory.sira_no" @change="updateSubcategory(subcategory)" /></td>
          <td><textarea v-model="subcategory.icon" @change="updateSubcategory(subcategory)"></textarea></td>
          <td>
            <input type="file" @change="handleFileUpload(subcategory, 'mobil_image', $event)" />
            <img v-if="subcategory.preview_mobil_image" :src="subcategory.preview_mobil_image" class="image-preview" />
          </td>
          <td>
            <input type="file" @change="handleFileUpload(subcategory, 'web_image', $event)" />
            <img v-if="subcategory.preview_web_image" :src="subcategory.preview_web_image" class="image-preview" />
          </td>
          <td>
            <button @click="deleteSubcategory(subcategory.id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      subcategories: [],
      categories: [],
      newSubcategory: {
        name: '',
        description: '',
        main_category_id: '',
        sira_no: null,
        icon: ''
      }
    };
  },
  methods: {
    async fetchSubcategories() {
      try {
        const response = await axios.get('/admin/sub-categories');
        this.subcategories = response.data.subCategories.map(subcategory => ({
          ...subcategory,
          preview_mobil_image: subcategory.mobil_image,
          preview_web_image: subcategory.web_image
        }));
      } catch (error) {
        console.error('Alt kategoriler alınırken hata oluştu:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('/admin/main-categories');
        this.categories = response.data.mainCategories;
      } catch (error) {
        console.error('Kategoriler alınırken hata oluştu:', error);
      }
    },
    async createSubcategory() {
      try {
        await axios.post('/admin/sub-category', this.newSubcategory);
        this.fetchSubcategories();
        this.newSubcategory = { name: '', description: '', main_category_id: '', sira_no: null, icon: '' };
      } catch (error) {
        console.error('Alt kategori eklenirken hata oluştu:', error);
      }
    },
    async updateSubcategory(subcategory) {
      try {
        await axios.put(`/admin/sub-category/${subcategory.id}`, subcategory);
      } catch (error) {
        console.error('Alt kategori güncellenirken hata oluştu:', error);
      }
    },
    handleFileUpload(subcategory, type, event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        if (type === 'mobil_image') {
          subcategory.preview_mobil_image = e.target.result;
          subcategory.mobil_image = file;
        } else if (type === 'web_image') {
          subcategory.preview_web_image = e.target.result;
          subcategory.web_image = file;
        }
      };
      reader.readAsDataURL(file);
    },
    async deleteSubcategory(id) {
      try {
        await axios.delete(`/admin/sub-category/${id}`);
        this.fetchSubcategories();
      } catch (error) {
        console.error('Alt kategori silinirken hata oluştu:', error);
      }
    }
  },
  mounted() {
    this.fetchCategories();
    this.fetchSubcategories();
  }
};
</script>
