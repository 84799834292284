<template>
    <div class="productgrouplist-management">
      <h3>Ürün Grubu Yönetimi</h3>
  
      <!-- Ürün Grubu Seçimi -->
      <label>Ürün Grubu Seç:</label>
      <select v-model="selectedGroupId" @change="fetchGroupProducts">
        <option value="" disabled>Seçiniz</option>
        <option v-for="group in productGroups" :key="group.productgroup_id" :value="group.productgroup_id">
          {{ group.productgroup_name }}
        </option>
      </select>
  
      <!-- Seçilen Gruba Ait Ürünler -->
      <h4 v-if="selectedGroupId">Gruba Ait Ürünler</h4>
      <table v-if="selectedGroupId">
        <thead>
          <tr>
            <th>Ürün Adı</th>
            <th>Adet</th>
            <th>Güncelle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in groupProducts" :key="item.product_id">
            <td>{{ getProductName(item.product_id) }}</td>
            <td>{{ item.quantity }}</td>
            <td>
              <button @click="editProduct(item)">Güncelle</button>
            </td>
            <td>
              <button class="deletebutton" @click="confirmDelete(item.productgroup_id, item.product_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
   
      <form v-if="selectedGroupId" @submit.prevent="isEdit ? updateProduct() : addProductToGroup()">
        <h4>{{ isEdit ? "Ürünü Güncelle" : "Gruba Yeni Ürün Ekle" }}</h4>
      
        <select v-model="selectedProductId" required>
          <option value="" disabled>Ürün Seçiniz</option>
          <option v-for="product in products" :key="product.product_id" :value="product.product_id">
            {{ product.product_name }}
          </option>
        </select>
      
        <input type="number" v-model="quantity" min="1" required placeholder="Adet" />
      
        <button class="confirm" type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
    </div>
  </template>
  
  
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        productGroups: [],
        products: [],
        groupProducts: [],
        selectedGroupId: "",
        selectedProductId: "",
        quantity: 1,
        isEdit: false,
        oldProductId: null,
      };
    },
    methods: {
      async fetchGroups() {
        try {
          const response = await axios.get("/admin/productgroups");
          this.productGroups = response.data;
        } catch (error) {
          console.error("Ürün grupları alınırken hata:", error);
        }
      },
      async fetchProducts() {
        try {
          const response = await axios.get("/admin/allactiveproducts");
          this.products = response.data;
        } catch (error) {
          console.error("Ürünler alınırken hata:", error);
        }
      },
      async fetchGroupProducts() {
        if (!this.selectedGroupId) return;
        try {
          const response = await axios.get(`/admin/productgrouplists/${this.selectedGroupId}`);
          this.groupProducts = response.data;
        } catch (error) {
          console.error("Seçilen grubun ürünleri alınırken hata:", error);
        }
      },

      async addProductToGroup() {
        if (!this.selectedGroupId || !this.selectedProductId || !this.quantity) return;
        try {
          await axios.post("/admin/productgrouplists", {
            productgroup_id: this.selectedGroupId,
            product_id: this.selectedProductId,
            quantity: this.quantity,
          });
          this.fetchGroupProducts();
          this.resetForm();
        } catch (error) {
          console.error("Ürün gruba eklenirken hata:", error);
        }
      },
      async updateProduct() {
        try {
          await axios.put(`/admin/productgrouplists/${this.selectedGroupId}/${this.oldProductId}`, {
            productgroup_id: this.selectedGroupId,
            product_id: this.selectedProductId,
            quantity: this.quantity,
          });
          this.fetchGroupProducts();
          this.resetForm();
        } catch (error) {
          console.error("Ürün güncellenirken hata:", error);
        }
      },
      editProduct(item) {
        this.selectedProductId = item.product_id;
        this.quantity = item.quantity;
        this.oldProductId = item.product_id;
        this.isEdit = true;
      },
      resetForm() {
        this.selectedProductId = "";
        this.quantity = 1;
        this.oldProductId = null;
        this.isEdit = false;
      },
      async deleteProductFromGroup(group_id, product_id) {
        try {
          await axios.delete(`/admin/productgrouplists/${group_id}/${product_id}`);
          this.fetchGroupProducts();
        } catch (error) {
          console.error("Ürün gruptan silinirken hata:", error);
        }
      }, 
      confirmDelete(group_id, product_id) {
        if (confirm("Bu ürünü gruptan silmek istiyor musunuz?")) {
          this.deleteProductFromGroup(group_id, product_id);
        }
      },
      getProductName(product_id) {
        const product = this.products.find((p) => p.product_id === product_id);
        return product ? product.product_name : "Bilinmiyor";
      }, 
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchGroups();
      this.fetchProducts();
    },
  };
  </script>
  