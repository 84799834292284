<template>
    <div class="container">
      <h3>E-posta/Kampanya İstatistikleri</h3>
   
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Kampanya</th>
            <th>Gönderim Sayısı</th>
            <th>Okunma Sayısı</th>
            <th>Tıklanma Sayısı</th>
            <th>Geri Dönen (Bounce) Sayısı</th>
            <th>Zaman Damgası</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="analytic in analytics" :key="analytic.analytics_id">
            <td>{{ analytic.analytics_id }}</td>
            <td>{{ analytic.campaign?.name || 'N/A' }}</td>
            <td>{{ analytic.sent_count }}</td>
            <td>{{ analytic.read_count }}</td>
            <td>{{ analytic.click_count }}</td>
            <td>{{ analytic.bounce_count }}</td>
            <td>{{ analytic.timestamp }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      analytics: [],
    };
  },
  async mounted() {
    await this.fetchAnalytics();
  },
  methods: {
    async fetchAnalytics() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/emailanalytics', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.analytics = response.data;
      } catch (error) {
        console.error('E-posta istatistikleri alınırken hata oluştu:', error);
      }
    },
  },
};
</script>
 