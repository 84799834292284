<template>
    <div>
      <h3>Kullanıcı Eğitim Kayıtları</h3>
  
      <!-- Kullanıcı arama input'u -->
      <input
        v-model="searchQuery"
        @input="filterUsers"
        placeholder="Kullanıcı adı veya ID ara"
      />
      <ul v-if="filteredUsers.length > 0" class="user-suggestions">
        <li
          v-for="user in filteredUsers"
          :key="user.user_id"
          @click="selectUser(user)"
          style="cursor: pointer;"
        >
          {{ user.user_name }} (ID: {{ user.user_id }})
        </li>
      </ul>
  
      <div v-if="selectedUser">
        <h4>
          Seçili Kullanıcı:
          <strong>{{ selectedUser.user_name }} (ID: {{ selectedUser.user_id }})</strong>
        </h4>
  
        <hr />
  
        <div v-if="educationRecords.length === 0">
          Bu kullanıcıya ait eğitim kaydı bulunamadı.
        </div>
  
        <!-- Education+Language listesi -->
        <div
          v-for="(record, index) in educationRecords"
          :key="record.education_id + '-' + record.language_id + '-' + index"
          class="education-block"
          style="margin-bottom: 1rem;"
        >
          <h3>
            {{ record.educationName }} 
            (Dil: {{ record.languageName }})
          </h3>
  
          <!-- dataRecords verileri -->
          <table cellpadding="6" cellspacing="0">
            <thead>
              <tr>
                <th>Data ID</th>
                <th>Başlık</th>
                <th>Alt Başlık</th>
                <th>Sayfa No</th>
                <th>İlk Çalışma</th>
                <th>Son Çalışma</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(dataRecord, idx) in record.dataRecords"
                :key="dataRecord.data_id + '-' + idx"
              >
                <td>{{ dataRecord.data_id }}</td>
                <td>{{ dataRecord.title }}</td>
                <td>{{ dataRecord.subtitle }}</td>
                <td>{{ dataRecord.serial_no }}</td>
                <td>{{ formatDateTime(dataRecord.created_at) }}</td>
                <td>{{ formatDateTime(dataRecord.updated_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'UserEducationRecord',
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: '',
        selectedUser: null,
        educationRecords: [] 
      };
    },
    methods: {
      async fetchUsers() {
        try {
          // Örnek rota: /admin/mainusers
          const response = await axios.get('/admin/mainusers');
          this.users = response.data;
        } catch (error) {
          console.error('Kullanıcılar alınırken hata oluştu:', error);
        }
      },
      filterUsers() {
        // Arama alanı
        this.filteredUsers = this.users.filter((user) =>
          user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.user_id.toString().includes(this.searchQuery)
        );
      },
      async selectUser(user) {
        // Kullanıcıyı seçince arama sonuçlarını temizle
        this.selectedUser = user;
        this.searchQuery = '';
        this.filteredUsers = [];
  
        // Seçili kullanıcıya ait education records'ları çek
        await this.fetchUserEducationRecords();
      },
      async fetchUserEducationRecords() {
        try {
          // "getUserEducationRecords" fonksiyonuna istek atar
          // Örnek POST isteği:
          const response = await axios.post('/admin/usereducationrecordsbyuser', {
            user_id: this.selectedUser.user_id,
          });
  
          // Beklenen data yapısı: { success: true, data: [ { educationName, ... }, ... ] }
          this.educationRecords = response.data.data || [];
        } catch (error) {
          console.error('Eğitim kayıtları alınırken hata oluştu:', error);
        }
      }
    },
    mounted() { 
      this.fetchUsers();
    }
  };
  </script>
  
  <style scoped>
  .user-suggestions {
    list-style-type: none;
    border: none;
    max-width: 300px;
    margin: 0;
    padding: 0;
  }
  .user-suggestions li {
    padding: 5px;
    border: none;
  }
  .education-block h5 {
    margin: 0.5rem 0;
  }
  </style>
  