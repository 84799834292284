<template>
  <div class="container">
    <h4>E-posta Şablon Yöneticisi</h4>

    <div class="form-area">
      <h4>{{ isEditing ? 'Şablonu Düzenle' : 'Yeni Şablon Ekle' }}</h4>
      <form @submit.prevent="submitTemplate">
        <input v-model="form.name" placeholder="Şablon Adı" required />
        <input v-model="form.subject" placeholder="Konu" required />
        <textarea v-model="form.body" placeholder="İçerik" required></textarea>
        <select v-model="form.category_id" required>
          <option disabled value="">Kategori Seç</option>
          <option v-for="category in categories" :key="category.category_id" :value="category.category_id">
            {{ category.name }}
          </option>
        </select>
        <button type="submit">{{ isEditing ? 'Güncelle' : 'Ekle' }}</button>
        <button type="button" @click="resetForm">İptal</button>
      </form>
    </div>

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Adı</th>
          <th>Konu</th>
          <th>Kategori</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="template in templates" :key="template.template_id">
          <td>{{ template.template_id }}</td>
          <td>{{ template.name }}</td>
          <td>{{ template.subject }}</td>
          <td>{{ template.category?.name || 'Yok' }}</td>
          <td>
            <button @click="viewTemplate(template)">Önizle</button>
            <button @click="editTemplate(template)">Düzenle</button>
            <button @click="confirmDelete(template.template_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table> 

    <div v-if="showPreview" class="modal">
      <div class="modal-content">
        <h4>Önizleme: {{ previewTemplate.name }}</h4>
        <h5>{{ previewTemplate.subject }}</h5>
        <div v-html="previewTemplate.body"></div>
        <button @click="closePreview">Kapat</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    templates: [],
    categories: [],
    form: {
      template_id: null,
      name: '',
      subject: '',
      body: '',
      category_id: '',
    },
    isEditing: false,
    showPreview: false,
    previewTemplate: {},
  };
},
async mounted() {
  await this.fetchTemplates();
  await this.fetchCategories();
},
methods: {
  async fetchTemplates() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailtemplates', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.templates = response.data;
    } catch (error) {
      console.error('Şablonlar alınırken hata oluştu:', error);
    }
  },
  async fetchCategories() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailcategories', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.categories = response.data;
    } catch (error) {
      console.error('Kategoriler alınırken hata oluştu:', error);
    }
  },
  viewTemplate(template) {
    this.previewTemplate = template;
    this.showPreview = true;
  },
  closePreview() {
    this.showPreview = false;
    this.previewTemplate = {};
  },
  editTemplate(template) {
    this.form = { ...template };
    this.isEditing = true;
  },
  confirmDelete(templateId) {
    if (confirm('Bu şablonu silmek istediğinize emin misiniz?')) {
      this.deleteTemplate(templateId);
    }
  },
  async deleteTemplate(templateId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/emailtemplates/${templateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchTemplates();
    } catch (error) {
      console.error('Şablon silinirken hata oluştu:', error);
    }
  },
  async submitTemplate() {
    try {
      const token = localStorage.getItem('token');
      const method = this.form.template_id ? 'put' : 'post';
      const url = this.form.template_id
        ? `/admin/emailtemplates/${this.form.template_id}`
        : '/admin/emailtemplates';
      await axios[method](url, this.form, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchTemplates();
      this.resetForm();
    } catch (error) {
      console.error('Şablon kaydedilirken hata oluştu:', error);
    }
  },
  resetForm() {
    this.form = {
      template_id: null,
      name: '',
      subject: '',
      body: '',
      category_id: '',
    };
    this.isEditing = false;
  }, 
},
};
</script>

  
  <style scoped>
  .container {
    padding: 1em;
  }  
  .form-area {
    background-color: #000;
    padding: 1em;
    border: none;
    margin: 1em 0;
    border-radius: 0.5em;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content {
    background-color: #1a262b;
    color: #f7f7f7;
    padding: 2em;
    border-radius: 0.5em;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  } 
  form {
    margin-top: 20px;
  }
  </style>
  