<template>
    <div class="studio-management">  
      <h3>Tüm Stüdyolar</h3>
      <table class="studio-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Adı</th>
            <th>Yönetici</th>
            <th>Adres</th>
            <th>Ülke</th>
            <th>Telefon</th>
            <th>Aktif</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="studio in studios" :key="studio.studio_id">
            <td>{{ studio.studio_id }}</td>
            <td>{{ studio.name }}</td>
            <td>{{ studio.manager }}</td>
            <td>{{ studio.address }}</td>
            <td>{{ studio.country }}</td>
            <td>{{ studio.phone }}</td>
            <td>{{ studio.is_active ? "Evet" : "Hayır" }}</td>
            <td><button @click="editStudio(studio)">Düzenle</button></td>
            <td><button @click="confirmDelete(studio.studio_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>

      <br>

      <h4>{{ isEdit ? "Stüdyoyu Düzenle" : "Yeni Stüdyo Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateStudio() : createStudio()">
        <input v-model="selectedStudio.name" placeholder="Stüdyo Adı" required />
        <input v-model="selectedStudio.manager" placeholder="Yönetici Adı" required />
        <textarea v-model="selectedStudio.address" placeholder="Adres" required></textarea>
        <input v-model="selectedStudio.country" placeholder="Ülke" required />
        <input v-model="selectedStudio.phone" placeholder="Telefon" required />
        <label>
          <input type="checkbox" v-model="selectedStudio.is_active" /> Aktif
        </label>
        <button class="confirm" type="submit">{{ isEdit ? "Güncelle" : "Stüdyo Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        studios: [],
        selectedStudio: {
          studio_id: null,
          name: '',
          manager: '',
          address: '',
          country: '',
          phone: '',
          is_active: true,
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchStudios() {
        try {
          const response = await axios.get('/admin/refika/studios');
          this.studios = response.data.studios;
        } catch (error) {
          console.error('Stüdyolar alınırken hata oluştu:', error);
        }
      },
      async createStudio() {
        try {
          await axios.post('/admin/refika/studios', {
            name: this.selectedStudio.name,
            manager: this.selectedStudio.manager,
            address: this.selectedStudio.address,
            country: this.selectedStudio.country,
            phone: this.selectedStudio.phone,
            is_active: this.selectedStudio.is_active,
          });
          this.fetchStudios();
          this.resetForm();
        } catch (error) {
          console.error('Stüdyo eklenirken hata oluştu:', error);
        }
      },
      editStudio(studio) {
        this.selectedStudio = { ...studio };
        this.isEdit = true;
      },
      async updateStudio() {
        try {
          await axios.put(`/admin/refika/studios/${this.selectedStudio.studio_id}`, {
            name: this.selectedStudio.name,
            manager: this.selectedStudio.manager,
            address: this.selectedStudio.address,
            country: this.selectedStudio.country,
            phone: this.selectedStudio.phone,
            is_active: this.selectedStudio.is_active,
          });
          this.fetchStudios();
          this.resetForm();
        } catch (error) {
          console.error('Stüdyo güncellenirken hata oluştu:', error);
        }
      },
      async deleteStudio(id) {
        try {
          await axios.delete(`/admin/refika/studios/${id}`);
          this.fetchStudios();
        } catch (error) {
          console.error('Stüdyo silinirken hata oluştu:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu stüdyoyu silmek istediğinizden emin misiniz?')) {
          this.deleteStudio(id);
        }
      },
      resetForm() {
        this.selectedStudio = {
          studio_id: null,
          name: '',
          manager: '',
          address: '',
          country: '',
          phone: '',
          is_active: true,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchStudios();
    },
  };
  </script>
  