<template>
  <div> 
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import ApplicationManager from './ApplicationManager.vue';
import ForumMain from '../sorusor/ForumMain.vue';
import ChatMain from '../chat/ChatMain.vue';
import GptMain from '../gpt/GptMain.vue';
import AccountingMain from '../muhasebe/AccountingMain.vue';
import RandevuMain from '../randevu/RandevuMain.vue';
import NotesMain from '../notes/NotesMain.vue';
import UserPortalSettingsManager from './UserPortalSettingsManager.vue';
import AllUserPortalSettings from './AllUserPortalSettings.vue';
  
export default {
  data() {
    return {
      currentComponent: 'ApplicationManager',
      components: [  
        { name: 'Uygulamalar', component: 'ApplicationManager' }, 
        { name: 'Soru&Cevap', component: 'ForumMain' },
        { name: 'Noah AURA', component: 'GptMain' },
        { name: 'Noah Chat', component: 'ChatMain' },
        { name: 'Randevu Sistemi', component: 'RandevuMain' },
        { name: 'Muhasebe Sistemi', component: 'AccountingMain' },
        { name: 'Notlar', component: 'NotesMain' },
        { name: 'Kullanıcı Portal Ayarları', component: 'UserPortalSettingsManager' },
        { name: 'Bildirimler ve İstatistikler', component: 'AllUserPortalSettings' },
      ]
    };
  },

  components: { 
    ApplicationManager, 
    ForumMain, 
    ChatMain, 
    GptMain, 
    AccountingMain, 
    RandevuMain, 
    NotesMain, 
    UserPortalSettingsManager, 
    AllUserPortalSettings
  }
};
</script>

<style scoped>
.button-container {
margin: 2em 0.2em 0.6em 0.2em;
text-align: center;
display: flex;
justify-content: center;
flex-wrap: wrap;
}
 
</style>
