<template>
  <div class="web-management">
    <h3>Mesaj Siteleri Yönetimi</h3>

    <h4>{{ isEdit ? "Websiteyi Düzenle" : "Yeni Website Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateWeb() : createWeb()">
      <input v-model="selectedWeb.web_name" placeholder="Website Adı" required />
      <input v-model="selectedWeb.link" placeholder="Link" required />
      <label>
        <input type="checkbox" v-model="selectedWeb.is_active" />
        Aktif Mi?
      </label>
      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <h4>Tüm Web Siteleri</h4>
    <table>
      <thead>
        <tr>
          <th>Website Adı</th>
          <th>Link</th>
          <th>Aktif Mi?</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="web in webs" :key="web.web_id">
          <td>{{ web.web_name }}</td>
          <td>{{ web.link }}</td>
          <td>{{ web.is_active ? "Evet" : "Hayır" }}</td>
          <td>
            <button @click="editWeb(web)">Düzenle</button>
            <button @click="confirmDelete(web.web_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WebManager',
  data() {
    return {
      webs: [],
      selectedWeb: {
        web_id: null,
        web_name: '',
        link: '',
        is_active: false,
      },
      isEdit: false,
    };
  },
  methods: {
    async fetchWebs() {
      try {
        const response = await axios.get('/admin/webs');
        this.webs = response.data;
      } catch (error) {
        console.error('Web siteleri alınırken hata oluştu:', error);
      }
    },
    async createWeb() {
      try {
        await axios.post('/admin/webs', this.selectedWeb);
        this.fetchWebs();
        this.resetForm();
      } catch (error) {
        console.error('Web eklenirken hata oluştu:', error);
      }
    },
    editWeb(web) {
      this.selectedWeb = { ...web };
      this.isEdit = true;
    },
    async updateWeb() {
      try {
        await axios.put(`/admin/webs/${this.selectedWeb.web_id}`, this.selectedWeb);
        this.fetchWebs();
        this.resetForm();
      } catch (error) {
        console.error('Web güncellenirken hata oluştu:', error);
      }
    },
    async deleteWeb(web_id) {
      try {
        await axios.delete(`/admin/webs/${web_id}`);
        this.fetchWebs();
      } catch (error) {
        console.error('Web silinirken hata oluştu:', error);
      }
    },
    confirmDelete(web_id) {
      if (confirm('Bu web sitesini silmek istediğinize emin misiniz?')) {
        this.deleteWeb(web_id);
      }
    },
    resetForm() {
      this.selectedWeb = {
        web_id: null,
        web_name: '',
        link: '',
        is_active: false,
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchWebs();
  },
};
</script>
 