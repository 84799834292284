<template>
  <div>  
    <div class="ai-home">  
      <div class="container">
        <div class="box" @click="navigateTo('/administration/noah')">NOAH</div>  
          <div class="box" @click="navigateTo('/administration/shop')">SHOP</div>
          <div class="box" @click="navigateTo('/administration/refika')">REFIKA</div> 
          <div class="box" @click="navigateTo('/administration/ad')">AD</div> 
          <div class="box" @click="navigateTo('/administration/sys')">SYSTEM</div> 
          <div class="box" @click="navigateTo('/administration/test')">Test</div> 
      </div>
      <div class="log-out" @click="logout">Çıkış</div>
    </div>
  </div>
</template>

<script>
export default { 
  methods: {
    navigateTo(routePath) {
      this.$router.push(routePath);
    },
    logout() { 
      localStorage.removeItem('token'); 
      this.$router.push('/');
    }
  }
}


//YEDEK BUTON



// <div> 
//     <div class="ai-home"> 
//       <div class="container">
//         <div class="box ads" @click="navigateTo('/administration/noah')">NOAH</div> 
//         <div class="box report" @click="navigateTo('/administration/shop')">SHOP</div>
//           <div class="box settings" @click="navigateTo('/administration/refika')">REFIKA</div> 
//           <div class="box health" @click="navigateTo('/administration/ad')">AD</div>
//           <div class="box nrs" @click="navigateTo('/administration/sys')">SYSTEM</div> 
//           <div class="box beta" @click="navigateTo('/administration/test')">Test</div>
//       </div>
//       <div class="box log-out" @click="logout">Çıkış</div>
//     </div>
//   </div>
</script>

  
<style scoped>
.title {
  font-size: 24px;
  color: #28829e;
  margin-bottom: 10px;
}
.ai-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  min-height: 60vh;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px; 
  padding: 10px;
  margin: 5em 0;
}
.box {
  min-width: 10rem;
  min-height: 10rem;
  background-color: #050505;
  color: #cecece;
  display: flex; 
  justify-content: center;
  align-items: center;
  font-size: 18px;    
  text-align: center;
  font-weight: 300;
  border-radius: 10px; 
  border: 0.06rem solid #6666; 
  box-shadow: 0 2px 4px rgba(2, 48, 50, 0);  
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.5s ease, color 0.2s ease; 
}
.box:last-child { 
  background-color: #0a0a0a;
  color: #424242; 
}
.box:hover {   transform: scale(1.05);
  background-color: #000;
  border-color: #023032;
  color: white;
  cursor: pointer; 
  box-shadow: 0 4px 6px rgba(2, 48, 50, 0.5); 
}
.ads {
  background-color: #007999; 
}
.ads:hover {
  background-color: #064f62; 
}
.report {
  background-color: #917e5c; 
}
.report:hover {
  background-color: #5d523d;
}
.settings {
  background-color: #127750;
}
.settings:hover {
  background-color: #085f3e; 
}
.health {
  background-color: #953342; 
}
.health:hover {
  background-color: #5f252e; 
}
.nrs {
  background-color: #023032;
}
.nrs:hover {
  background-color: #022121; 
} 

.log-out {
  min-width: 10rem;
  min-height: 3rem;
  background-color: #050505;
  color: #c7c7c7;
  display: flex; 
  justify-content: center;
  align-items: center;
  font-size: 1rem;    
  text-align: center;
  font-weight: 300;
  border-radius: 10px; 
  border: 0.06rem solid #6666; 
  box-shadow: 0 2px 4px rgba(50, 2, 2, 0);  
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.5s ease, color 0.2s ease; 
}
.log-out:hover {   transform: scale(1.05);
  background-color: #000;
  border-color: #320202;
  color: white;
  cursor: pointer; 
  box-shadow: 0 4px 6px rgba(50, 2, 2, 0.5); 
}
.noah-logo {
  max-width: 12em;
  opacity: 0.6;
}
</style> 