<template>
  <div> 
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>


<script> 
import GeneralAgreementManager from './GeneralAgreementManager.vue';
import ProductAndPriceManager from './ProductAndPriceManager.vue'; 
import StudioMain from './studio/StudioMain.vue'; 
import SubDomains from './subdomains/SubDomains.vue'; 
import TargetAreaManager from './TargetAreaManager.vue';

export default {
  data() {
    return {
      currentComponent: 'StudioMain', 
      components: [ 
        { name: 'Stüdyo Yönetim', component: 'StudioMain' },  
        { name: 'Refika Raklam Siteleri', component: 'SubDomains' }, 
        { name: 'Refika Uygulamalar/Fiyatlar', component: 'ProductAndPriceManager' }, 
        { name: 'Genel Sözleşmeler', component: 'GeneralAgreementManager' }, 
        { name: 'Hedef Bölgeler', component: 'TargetAreaManager' }, 
      ]
    };
  },

  components: {
    StudioMain, 
    SubDomains,
    ProductAndPriceManager,
    GeneralAgreementManager,
    TargetAreaManager
  },
  
};
</script>
  
<style scoped>
.button-container {
  background-color: #000;
margin: 1em 0rem;
padding: 0.8rem;
text-align: center;
display: flex; 
flex-wrap: wrap; 
} 

.button-container button {  
display: inline-block;
padding: 0.4rem 2rem;
min-width: 6rem;
margin: 0.2rem;
border: 0.06rem solid #2f0038;
background-color: #0b0e0f;
color: #b5b5b5;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
} 
.button-container button:hover {
background-color: #2f0038;  
color: #dbdbdb;
} 
.button-container button.active {
background-color: #261429;
color: #ffffff; 
} 
@media (max-width: 360px) {
.button-container {
  flex-direction: column;
} 
.button-container button {
  width: 90%;
  margin: 0.2em auto; 
}
}
</style>
  