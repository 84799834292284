<template>
  <div> 
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import ProductGroup from './SubcategoryManager.vue';
import ProductManager from './ProductManager.vue';
import MinorCategoryManager from './MinorCategoryManager.vue';
import DocumentManager from './DocumentManager.vue';
import BatchCiManager from './BatchCiManager.vue';
import ProductFeatureManager from './ProductFeatureManager.vue';
 
export default {
  data() {
    return {
      currentComponent: 'ProductGroup',
      components: [  
        { name: 'Ürün Kategorileri', component: 'ProductGroup' },
        { name: 'Gösterilen Ürünler', component: 'MinorCategoryManager' },
        { name: 'Ürün benzersiz özellikler', component: 'ProductFeatureManager' },
        { name: 'Ürün Belgeleri', component: 'DocumentManager' },
        { name: 'Ürün Parti numaraları / CI kodları', component: 'BatchCiManager' },
        { name: 'Ürün yönetimi / PROMO', component: 'ProductManager' },
      ]
    };
  },

  components: {
    ProductGroup,  ProductManager, MinorCategoryManager, DocumentManager, BatchCiManager, ProductFeatureManager
  }
};
</script>

<style scoped>
.button-container {
margin: 2em 0.2em 0.6em 0.2em;
text-align: center;
display: flex;
justify-content: center;
flex-wrap: wrap;
}
 
</style>
