<template>
    <div>
      <div class="sub-button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div>
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script> 
import BulkQuestion from './BulkQuestion.vue';
import EducationData from './EducationData.vue';
import EducationLangManager from './EducationLangManager.vue';
import EducationManagement from './EducationManagement.vue'; 
import QuestionManager from './QuestionManager.vue';
import UserEducations from './UserEducations.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'EducationData', 
        components: [ 
          { name: 'Egitimler', component: 'EducationManagement' },
          { name: 'Egitim Dilleri', component: 'EducationLangManager' },
          { name: 'Eğitim içerikleri RAW', component: 'EducationData' }, 
          { name: 'Soru yönetimi', component: 'QuestionManager' },
          { name: 'Toplu Soru JSON girişi', component: 'BulkQuestion' }, 
          { name: 'Kullanıcı Eğitimleri', component: 'UserEducations' }, 
        ],
      };
    },
    components: {  
      EducationManagement,
      EducationData,
      QuestionManager,
      BulkQuestion,
      EducationLangManager,
      UserEducations
    },
  };
  </script>
    
  <style scoped> 
  </style>
    