<template>
    <div class="alert info"> 
      Bu özellik geçici olarak devredışıdır.
      <br>
      Kullancıı bildirimlerini kullanabilirsiniz.
      <br>
      Alternatif yöntemler nedeniyle askıya alındı
    </div>
  </template>
  
  <script>  
  
  export default {
    data() {
      return { 
      };
    }, 
  };
  </script>
    
  <style scoped> 
  </style>
    