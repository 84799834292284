<template>
    <div class="category-management">
      <div class="table-header">
        <h3>Tüm Kategoriler</h3>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table>
        <thead>
          <tr>
            <th>Kategori Adı</th>
            <th>Oluşturulma Tarihi</th>
            <th>Güncelle</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in categories" :key="category.category_id">
            <td>{{ category.category_name }}</td>
            <td>{{ formatDateTime(category.created_at) }}</td>
            <td>
              <button @click="editCategory(category)">Güncelle</button>
              <button class="deletebutton" v-if="showDeleteButtons" @click="confirmDelete(category.category_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <form @submit.prevent="isEdit ? updateCategory() : createCategory()">
        <h4>{{ isEdit ? "Kategoriyi Düzenle" : "Yeni Kategori Ekle" }}</h4>
        <input v-model="selectedCategory.category_name" placeholder="Kategori Adı" required />
  
        <div class="formbutton">
          <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
          <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        categories: [],
        showDeleteButtons: false,
        selectedCategory: {
          category_id: null,
          category_name: "",
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchCategories() {
        try {
          const response = await axios.get("/admin/categories");
          this.categories = response.data;
        } catch (error) {
          console.error("Kategoriler alınırken hata oluştu:", error);
        }
      },
      async createCategory() {
        try {
          await axios.post("/admin/categories", this.selectedCategory);
          this.fetchCategories();
          this.resetForm();
        } catch (error) {
          console.error("Kategori eklenirken hata oluştu:", error);
        }
      },
      editCategory(category) {
        this.selectedCategory = { ...category };
        this.isEdit = true;
      },
      async updateCategory() {
        try {
          await axios.put(`/admin/categories/${this.selectedCategory.category_id}`, this.selectedCategory);
          this.fetchCategories();
          this.resetForm();
        } catch (error) {
          console.error("Kategori güncellenirken hata oluştu:", error);
        }
      },
      async deleteCategory(category_id) {
        try {
          await axios.delete(`/admin/categories/${category_id}`);
          this.fetchCategories();
        } catch (error) {
          console.error("Kategori silinirken hata oluştu:", error);
        }
      },
      confirmDelete(category_id) {
        if (confirm("Bu kategoriyi silmek istediğinize emin misiniz?")) {
          this.deleteCategory(category_id);
        }
      },
      resetForm() {
        this.selectedCategory = {
          category_id: null,
          category_name: "",
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchCategories();
    },
  };
  </script>
  