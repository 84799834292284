<template>
    <div>
      <h3>Genel Sözleşmeler</h3>
  
      <div>MULTER ENTEGRASYONU EKSİK</div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Ad</th>
            <th>Açıklama</th>
            <th>Link</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="agreement in agreements" :key="agreement.agreement_id">
            <td>{{ agreement.agreement_id }}</td>
            <td>{{ agreement.name }}</td>
            <td>{{ agreement.description }}</td>
            <td><a :href="agreement.link" target="_blank">Görüntüle</a></td>
            <td><button @click="editAgreement(agreement)">Düzenle</button></td>
            <td><button @click="deleteAgreement(agreement.agreement_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>

      <br>
      <form @submit.prevent="isEdit ? updateAgreement() : createAgreement()">
        <input v-model="selectedAgreement.name" placeholder="Sözleşme Adı" required />
        <textarea v-model="selectedAgreement.description" placeholder="Açıklama"></textarea>
        <input v-model="selectedAgreement.link" type="url" placeholder="Döküman Linki" />
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="resetForm" type="button">İptal</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        agreements: [],
        selectedAgreement: { agreement_id: null, name: '', description: '', link: '' },
        isEdit: false,
      };
    },
    methods: {
      async fetchAgreements() {
        const res = await axios.get('/admin/refika/generalagreements');
        this.agreements = res.data.agreements;
      },
      async createAgreement() {
        await axios.post('/admin/refika/generalagreements', this.selectedAgreement);
        this.fetchAgreements();
        this.resetForm();
      },
      editAgreement(agreement) {
        this.selectedAgreement = { ...agreement };
        this.isEdit = true;
      },
      async updateAgreement() {
        await axios.put(`/admin/refika/generalagreements/${this.selectedAgreement.agreement_id}`, this.selectedAgreement);
        this.fetchAgreements();
        this.resetForm();
      },
      async deleteAgreement(id) {
        await axios.delete(`/admin/refika/generalagreements/${id}`);
        this.fetchAgreements();
      },
      resetForm() {
        this.selectedAgreement = { agreement_id: null, name: '', description: '', link: '' };
        this.isEdit = false;
      }
    },
    mounted() {
      this.fetchAgreements();
    },
  };
  </script>
  