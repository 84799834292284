<template>
  <div class="chatroom-manager">
    <h3>Sohbet Odası Yönetimi</h3>

    <h4>{{ isEdit ? "Sohbet Odasını Güncelle" : "Yeni Sohbet Odası Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateChatRoom() : createChatRoom()">
      <input v-model="selectedRoom.name" placeholder="Sohbet Odası Adı" required />
      <label>
        <input type="checkbox" v-model="selectedRoom.is_active" :true-value="true" :false-value="false" />
        Aktif mi?
      </label>
      <label>
        <input type="checkbox" v-model="selectedRoom.is_private" :true-value="true" :false-value="false" />
        Gizli mi?
      </label>
      
      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Adı</th>
          <th>Aktif</th>
          <th>Gizli</th>
          <th>Oluşturulma Tarihi</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="room in chatRooms" :key="room.id">
          <td>{{ room.id }}</td>
          <td>{{ room.name }}</td>
          <td>{{ room.is_active ? "Evet" : "Hayır" }}</td>
          <td>{{ room.is_private ? "Evet" : "Hayır" }}</td>
          <td>{{ formatDate(room.created_at) }}</td>
          <td>
            <button @click="editChatRoom(room)">Düzenle</button>
            <button class="delete" @click="deleteChatRoom(room.id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      chatRooms: [],
      selectedRoom: {
        id: null,
        name: "",
        is_active: true,
        is_private: false,
      },
      isEdit: false,
    };
  },
  methods: {
    async fetchChatRooms() {
      try {
        const response = await axios.get("/admin/chatrooms");
        this.chatRooms = response.data.data;
      } catch (error) {
        console.error("Sohbet odaları alınırken hata oluştu:", error);
      }
    },
    async createChatRoom() {
      try {
        await axios.post("/admin/chatrooms", {
          name: this.selectedRoom.name,
          is_active: this.selectedRoom.is_active,
          is_private: this.selectedRoom.is_private,
        });
        this.fetchChatRooms();
        this.resetForm();
      } catch (error) {
        console.error("Oda oluşturulurken hata oluştu:", error);
      }
    },
    async updateChatRoom() {
      try {
        await axios.put(`/admin/chatrooms/${this.selectedRoom.id}`, {
          name: this.selectedRoom.name,
          is_active: this.selectedRoom.is_active,
          is_private: this.selectedRoom.is_private,
        });
        this.fetchChatRooms();
        this.resetForm();
      } catch (error) {
        console.error("Oda güncellenirken hata oluştu:", error);
      }
    },
    async deleteChatRoom(id) {
      if (confirm("Bu sohbet odasını silmek istediğinizden emin misiniz? UYARI: BİR OADA SİLİNRSE MESAJLARI DA SİLİNİR")) {
        try {
          await axios.delete(`/admin/chatrooms/${id}`);
          this.fetchChatRooms();
        } catch (error) {
          console.error("Oda silinirken hata oluştu:", error);
        }
      }
    },
    editChatRoom(room) {
      this.selectedRoom = { ...room };
      this.isEdit = true;
    },
    resetForm() {
      this.selectedRoom = { id: null, name: "", is_active: true, is_private: false };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
  mounted() {
    this.fetchChatRooms();
  },
};
</script>

<style scoped>
.chatroom-manager {
  padding: 20px;
} 
</style>
