<template>
    <div>
      <h3>Hesap Seviyeleri</h3>
      <table>
        <thead>
          <tr> 
            <th>Seviye No</th>
            <th>Detay</th>
            <th>Oluşturulma Tarihi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="level in levels" :key="level.id"> 
            <td>
              <div v-if="editing.id === level.id && editing.field === 'seviye_no'">
                <input type="number" v-model="level.seviye_no" @blur="updateLevel(level)" />
              </div>
              <div v-else @click="setEditing(level.id, 'seviye_no')">
                <strong>{{ level.seviye_no }}</strong>
              </div>
            </td>
            <td>
              <div v-if="editing.id === level.id && editing.field === 'details'">
                <input type="text" v-model="level.details" @blur="updateLevel(level)" />
              </div>
              <div v-else @click="setEditing(level.id, 'details')">
                {{ level.details || '---' }}
              </div>
            </td>
            <td>{{ formatDate(level.created_at) }}</td>
            <td>
              <button class="delete" @click="deleteLevel(level.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <h3>Yeni Hesap Seviyesi Ekle</h3>
      <form @submit.prevent="addLevel">
        <div>
          <label>Seviye No:</label>
          <input type="number" v-model="newLevel.seviye_no" required />
        </div>
        <div>
          <label>Detay:</label>
          <input type="text" v-model="newLevel.details" />
        </div>
        <button type="submit">Ekle</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        levels: [],
        newLevel: {
          seviye_no: '',
          details: '',
        },
        editing: {
          id: null,
          field: '',
        },
      };
    },
    methods: { 
      fetchLevels() {
        axios.get('/admin/hesap-seviyeleri')
          .then(response => {
            this.levels = response.data.data;
          })
          .catch(error => {
            console.error('Seviyeler getirilirken hata:', error);
          });
      }, 
      addLevel() {
        axios.post('/admin/hesap-seviyeleri', this.newLevel)
          .then(response => {
            this.levels.push(response.data.data);
            this.newLevel = { seviye_no: '', details: '' };
          })
          .catch(error => {
            console.error('Seviye eklenirken hata:', error);
          });
      }, 
      updateLevel(level) {
        axios.put(`/admin/hesap-seviyeleri/${level.id}`, level)
          .then(() => {
            this.fetchLevels();
            this.editing.id = null;
            this.editing.field = '';
          })
          .catch(error => {
            console.error('Seviye güncellenirken hata:', error);
          });
      }, 
      deleteLevel(id) {
        if (confirm('Bu seviyeyi silmek istediğinizden emin misiniz?')) {
          axios.delete(`/admin/hesap-seviyeleri/${id}`)
            .then(() => {
              this.levels = this.levels.filter(level => level.id !== id);
            })
            .catch(error => {
              console.error('Seviye silinirken hata:', error);
            });
        }
      }, 
      setEditing(id, field) {
        this.editing.id = id;
        this.editing.field = field;
      }, 
      formatDate(dateString) {
        if (!dateString) return '---';
        return new Date(dateString).toLocaleString();
      },
    },
    mounted() {
      this.fetchLevels();
    },
  };
  </script> 