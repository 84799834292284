<template>
    <div class="current-stocks">
      <h3>Güncel Ürün Stokları</h3>
   
      <input 
        type="text" 
        v-model="searchQuery" 
        placeholder="Ürün adı ile ara..."
        class="search"
      />
  
      <table class="table">
        <thead>
          <tr>
            <th>Ürün Seri No</th>
            <th>Ürün Adı</th>
            <th @click="sortByStock" class="sortable">
              Stok Miktarı <span v-if="sortColumn === 'stock_amount'">{{ sortDirection === 'asc' ? '🔼' : '🔽' }}</span>
            </th>
            <th @click="sortByStatus" class="sortable">
              Durum/Satışta <span v-if="sortColumn === 'status'">{{ sortDirection === 'asc' ? '🔼' : '🔽' }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in filteredAndSortedProducts" :key="product.product_id">
            <td>{{ product.product_serial }}</td>
            <td>{{ product.product_name }}</td>
            <td>{{ product.stock_amount }}</td>
            <td>
              <span :class="getStatusClass(product.status)">
                {{ product.status }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "CurrentStocks",
    data() {
      return {
        products: [],
        searchQuery: "",
        sortColumn: null,
        sortDirection: "asc",
      };
    },
    computed: {
      filteredAndSortedProducts() {
        let filtered = this.products.filter((product) =>
          product.product_name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
  
        if (this.sortColumn) {
          filtered.sort((a, b) => {
            let valA = a[this.sortColumn];
            let valB = b[this.sortColumn];
  
            if (this.sortColumn === "status") {
              // "Aktif" olanları üstte, "İnaktif" olanları altta sıralar
              valA = valA === "Aktif" ? 1 : 0;
              valB = valB === "Aktif" ? 1 : 0;
            }
  
            if (this.sortDirection === "asc") {
              return valA > valB ? 1 : -1;
            } else {
              return valA < valB ? 1 : -1;
            }
          });
        }
  
        return filtered;
      },
    },
    methods: {
      async fetchProducts() {
        try {
          const response = await axios.get("/admin/products");
          this.products = response.data;
        } catch (error) {
          console.error("Ürünler alınırken hata:", error);
        }
      },
      getStatusClass(status) {
        return {
          "status-active": status === "Aktif",
          "status-inactive": status === "İnaktif",
        };
      },
      sortByStock() {
        this.toggleSort("stock_amount");
      },
      sortByStatus() {
        this.toggleSort("status");
      },
      toggleSort(column) {
        if (this.sortColumn === column) {
          this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
        } else {
          this.sortColumn = column;
          this.sortDirection = "asc";
        }
      },
    },
    mounted() {
      this.fetchProducts();
    },
  };
  </script>
  
  <style scoped>
  .current-stocks {
    padding: 20px;
  }
   
  
  .sortable {
    user-select: none;
  }
  
  .status-active {
    color: white;
    background-color: green;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .status-inactive {
    color: white;
    background-color: red;
    padding: 5px 10px;
    border-radius: 4px;
  }
  </style>
  