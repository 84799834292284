<template>
  <div class="product-management">
    <div class="table-header">
      <h3>Güncel Ürünler</h3>
      <div>
        <label>
          <input type="checkbox" v-model="showArchiveButtons" /> Arşivleme Butonlarını Göster
        </label>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label> 
      </div>
    </div>
    <input type="text"  v-model="searchQuery" placeholder="Ürün adı ile ara..." />
    <table>
      <thead>
        <tr>
          <th>Seri Numarası</th>
          <th>Ürün Adı</th>
          <th>Açıklama</th>
          <th>Stok Miktarı</th>
          <th>Durum</th>
          <th>Oluşturulma Tarihi</th>
          <th>Kategori</th>
          <th v-if="showArchiveButtons">Arşivle</th>
          <th>Güncelle</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in filteredProducts" :key="product.product_id">
          <td>#{{ product.product_serial }}</td>
          <td>{{ product.product_name }}</td>
          <td>{{ product.description }}</td>
          <td>{{ product.stock_amount }}</td>
          <td>
            <select v-model="product.status" @change="updateProductStatus(product)">
              <option value="Aktif">Aktif</option>
              <option value="İnaktif">İnaktif</option>
            </select>
          </td>
          <td>{{ formatDateTime(product.created_at) }}</td>
          <td>
            <button @click="openCategoryModal(product)">Ürün Kategorisi</button>
          </td>
          <td v-if="showArchiveButtons">
            <button @click="archiveProduct(product.product_id)" v-if="!product.is_archived">Arşivle</button>
            <span v-if="product.is_archived">Arşivlendi</span>
          </td>
          <td>
            <button class="confirm" @click="editProduct(product)">Güncelle</button>
            <button class="deletebutton" v-if="showDeleteButtons" @click="confirmDelete(product.product_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Ürün Ekle/Düzenle Formu -->
    <form @submit.prevent="isEdit ? updateProduct() : createProduct()">
      <h4>{{ isEdit ? "Ürünü Düzenle" : "Yeni Ürün Ekle" }}</h4>
      <label>Ürün Seri Numarası:</label>
      <input type="number" v-model="selectedProduct.product_serial" placeholder="Seri no" required min="0" />
      <input v-model="selectedProduct.product_name" placeholder="Ürün Adı" required />
      <textarea v-model="selectedProduct.description" placeholder="Açıklama"></textarea>
      <label>Stok Miktarı:</label>
      <input type="number" v-model="selectedProduct.stock_amount" placeholder="Stok Miktarı" required min="0" />

      <select v-model="selectedProduct.status" required>
        <option value="" disabled>Durum Seçin</option>
        <option value="Aktif">Aktif</option>
        <option value="İnaktif">İnaktif</option>
      </select>

      <div class="formbutton">
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </div>
    </form>

    <!-- Ürün Kategori Modal -->
    <ProductCategories
      v-if="showCategoryModal"
      :productId="selectedProductForCategory.product_id"
      :productName="selectedProductForCategory.product_name"
      @close="showCategoryModal = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import ProductCategories from "./ProductCategories.vue"; // Modal bileşenini içe aktar

export default {
  components: {
    ProductCategories,
  },
  data() {
    return {
      products: [],
      showDeleteButtons: false,
      showArchiveButtons: false,
      searchQuery: "", 
      showCategoryModal: false,
      selectedProductForCategory: {},
      selectedProduct: {
        product_id: null,
        product_name: "",
        description: "",
        stock_amount: 0,
        product_serial: 0,
        status: "Aktif", 
      },
      isEdit: false,
    };
  },
  computed: {
    filteredProducts() {
      return this.products.filter(product =>
        product.product_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get("/admin/currentproducts");
        this.products = response.data;
      } catch (error) {
        console.error("Ürünler alınırken hata oluştu:", error);
      }
    },
    openCategoryModal(product) {
      this.selectedProductForCategory = { ...product };
      this.showCategoryModal = true;
    },
    async createProduct() {
      try {
        await axios.post("/admin/products", this.selectedProduct);
        this.fetchProducts();
        this.resetForm();
      } catch (error) {
        console.error("Ürün eklenirken hata oluştu:", error);
      }
    },
    editProduct(product) {
      this.selectedProduct = { ...product };
      this.isEdit = true;
    },
    async updateProduct() {
      try {
        await axios.put(`/admin/products/${this.selectedProduct.product_id}`, this.selectedProduct);
        this.fetchProducts();
        this.resetForm();
      } catch (error) {
        console.error("Ürün güncellenirken hata oluştu:", error);
      }
    },
    async updateProductStatus(product) {
      try {
        await axios.put(`/admin/products/${product.product_id}`, product);
        this.fetchProducts();
        alert("Ürün durumu başarıyla güncellendi.");
      } catch (error) {
        console.error("Ürün durumu güncellenirken hata oluştu:", error);
        alert("Ürün durumu güncellenirken bir hata oluştu.");
      }
    },
    async deleteProduct(product_id) {
      try {
        await axios.put(`/admin/products/softdelete/${product_id}`);
        this.fetchProducts();
      } catch (error) {
        console.error("Ürün silinirken hata oluştu:", error);
      }
    },
    confirmDelete(product_id) {
      if (confirm("Bu ürünü silmek istediğinize emin misiniz?")) {
        this.deleteProduct(product_id);
      }
    },
    async archiveProduct(product_id) {
      try {
        await axios.put(`/admin/products/archive/${product_id}`);
        this.fetchProducts();  
      } catch (error) {
        console.error("Ürün arşivlenirken hata oluştu:", error);
      }
    },
    resetForm() {
      this.selectedProduct = {
        product_id: null,
        product_name: "",
        description: "",
        stock_amount: 0,
        product_serial: 0,
        status: "Aktif",
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    }, 
  },
  mounted() {
    this.fetchProducts();
  },
};
</script>

<style scoped>
form p {
  margin: 5px 0 0 0;
}
</style>
