<template>
    <div class="user-activities">
      <h2>Kullanıcı Aktiviteleri</h2>
      <input v-model="userId" type="number" placeholder="User ID girin" />
      <button class="confirm" @click="fetchUserActivities">Aktiviteleri Getir</button>
  
      <ul v-if="activities.length">
        <li v-for="activity in activities" :key="activity.id">
          {{ activity.activity }} - <span>{{ activity.created_at }}</span>
          <button class="delete" @click="deleteActivity(activity.id)">Sil</button>
        </li>
      </ul>
      <p v-else>Kullanıcı aktivitesi yok.</p>
  
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        userId: "",
        activities: [],
        errorMessage: "",
      };
    },
    methods: {
      async fetchUserActivities() {
        if (!this.userId) {
          this.errorMessage = "Lütfen bir kullanıcı ID girin.";
          return;
        }
        this.errorMessage = "";
        try {
          const response = await axios.get(`/admin/useractivities/${this.userId}`);
          this.activities = response.data.data || [];
          if (this.activities.length === 0) {
            this.errorMessage = "Kullanıcı aktivitesi yok.";
          }
        } catch (error) {
          this.errorMessage = `Hata: ${error.response?.data?.message || error.message}`;
          console.error("Aktiviteler alınırken hata oluştu:", error);
        }
      },
      async deleteActivity(id) {
        if (!confirm("Bu aktiviteyi silmek istediğinizden emin misiniz?")) return;
        try {
          await axios.delete(`/admin/useractivities/${id}`);
          this.activities = this.activities.filter((act) => act.id !== id);
        } catch (error) {
          this.errorMessage = `Hata: ${error.response?.data?.message || error.message}`;
          console.error("Aktivite silinirken hata oluştu:", error);
        }
      },
    },
  };
  </script>
   
  <style scoped>
  .user-activities {
    padding: 20px;
    max-width: 1200px; 
  }
  input {
    padding: 8px;
    margin-right: 10px;
  }
  button {
    padding: 8px 12px;
    cursor: pointer;
  }
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
  .error {
    color: red;
    margin-top: 10px;
  }
  </style>
  