<template>
    <div class="settings-manager">
      <h2>Kullanıcı Portal Ayarları Yönetimi</h2>
  
      <!-- Kullanıcı Arama -->
      <div class="search-container">
        <input
          v-model="searchQuery"
          @input="filterUsers"
          placeholder="Kullanıcı adı veya ID ara"
          class="search-input"
        />
        <ul v-if="filteredUsers.length > 0" class="user-suggestions">
          <li 
            v-for="user in filteredUsers" 
            :key="user.user_id"
            @click="selectUser(user)"
            class="suggestion-item"
          >
            {{ user.user_name }} (ID: {{ user.user_id }})
          </li>
        </ul>
      </div>
  
      <!-- Ayar Formu -->
      <div v-if="selectedUser" class="selected-user">
        <h3>Seçili Kullanıcı: <strong>{{ selectedUser.user_name }} (ID: {{ selectedUser.user_id }})</strong></h3>
        
        <form @submit.prevent="updateSettings" class="settings-form">
          <div class="form-group" v-for="(value, key) in filteredSettings" :key="key">
            <label>
              <span>{{ labels[key] || formatKey(key) }}</span>
              <input 
                v-if="key === 'pin'" 
                type="number" 
                v-model="settings[key]"
                class="pin-input"
              />
              <input 
                v-else 
                type="checkbox" 
                v-model="settings[key]"
              />
            </label>
          </div>
          <button type="submit" class="save-button">Kaydet</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'UserPortalSettingsManager',
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: '',
        selectedUser: null,
        settings: {},
        labels: {
          is_advanced_mode_on: 'Gelişmiş Mod',
          is_note_notification_on: 'Not Bildirimleri',
          is_appoinment_notification_on: 'Randevu Bildirimleri',
          is_accounting_notification_on: 'Muhasebe Bildirimleri',
          is_whatsapp_notification_on: 'WhatsApp Bildirimleri',
          is_email_notification_on: 'E-posta Bildirimleri',
          is_local_notification_on: 'Yerel Bildirimler',
          is_pinlock_active: 'PIN Kilidi Aktif',
          pin: 'PIN Kodu'
        }
      };
    },
    computed: {
      filteredSettings() {
        return Object.entries(this.settings)
          .filter(([key]) => !['id', 'user_id', 'created_at', 'updated_at'].includes(key))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
      }
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get('/admin/mainusers');
          this.users = response.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata:", error);
        }
      },
      filterUsers() {
        this.filteredUsers = this.users.filter(user =>
          user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.user_id.toString().includes(this.searchQuery)
        );
      },
      async selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = '';
        this.filteredUsers = [];
        await this.fetchSettings();
      },
      async fetchSettings() {
        try {
          const response = await axios.get(`/admin/userportalsettings/${this.selectedUser.user_id}`);
          this.settings = response.data.settings;
        } catch (error) {
          console.error("Ayarlar alınırken hata:", error);
        }
      },
      async updateSettings() {
        try {
          await axios.put(`/admin/userportalsettings/${this.selectedUser.user_id}`, this.settings);
          alert('Ayarlar başarıyla kaydedildi.');
        } catch (error) {
          console.error("Ayarlar güncellenirken hata:", error);
        }
      },
      formatKey(key) {
        return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
      }
    },
    mounted() {
      this.fetchUsers();
    }
  };
  </script>
  
  
  <style scoped>
  .settings-manager {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #050505;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .search-container {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #086b68;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .user-suggestions {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;
    background: #000000;
    border: 1px solid #086b68;
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .suggestion-item {
    padding: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .suggestion-item:hover {
    background-color: #000000;
  }
  
  .selected-user {
    padding: 1rem;
    border: 1px solid #086b68;
    border-radius: 4px;
    background-color: #000000;
  }
  
  .settings-form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pin-input {
    width: 80px;
  }
  
  .save-button {
    padding: 0.5rem 1rem;
    background-color: #4caf50; 
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 1rem;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  </style>
  