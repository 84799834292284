<template>
    <div>
      <div class="sub-button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div>
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script>   
import CertificatesManager from './CertificatesManager.vue';
import MasterLevelManager from './MasterLevelManager.vue';
import NoahMasterManager from './NoahMasterManager.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'NoahMasterManager', 
        components: [  
          { name: 'Master Seviyeleri', component: 'MasterLevelManager' }, 
          { name: 'Masterlar', component: 'NoahMasterManager' }, 
          { name: 'Sertifikalar', component: 'CertificatesManager' }, 
        ],
      };
    },
    components: {   
      MasterLevelManager,
      NoahMasterManager,
      CertificatesManager
    },
  };
  </script>
    
  <style scoped> 
  </style>
    