<template>
  <div class="agreement-container">
    <div class="agreement-form">
      <h4 v-if="isEditing">Sözleşmeyi Düzenle</h4>
      <h4 v-else>Yeni Sözleşme Ekle</h4>

      <label>
        Kategori:
        <input v-model="currentAgreement.category" placeholder="Kategori girin" />
      </label>
      <label>
        Sözleşme Adı:
        <input v-model="currentAgreement.agreement_name" placeholder="Sözleşme adını girin" />
      </label>
      <label>
        İçerik:
        <textarea v-model="currentAgreement.content" placeholder="Sözleşme içeriğini girin"></textarea>
      </label>
      <label>
        Sıra Numarası:
        <input type="number" v-model.number="currentAgreement.order_number" placeholder="Sıra numarasını girin" />
      </label>
      <label>
        Aktif mi:
        <input type="checkbox" v-model="currentAgreement.is_active" />
      </label>
      <button @click="saveAgreement">Sözleşmeyi {{ isEditing ? 'Güncelle' : 'Ekle' }}</button>
      <button v-if="isEditing" @click="resetForm">İptal</button>
    </div>

    <h4>Sözleşmeler</h4>
    <div v-if="groupedAgreements.length === 0">Hiç sözleşme bulunamadı.</div>
    <div v-else>
      <div v-for="(agreements, category) in groupedAgreements" :key="category" class="accordion">
        <div @click="toggleAccordion(category)" class="accordion-header">
          {{ category }}
          <span class="expand-button">&#9660;</span>
        </div>
        <div class="category-content" v-show="activeAccordion === category">
          <div v-for="agreement in agreements" :key="agreement.id" class="accordion">
            <div @click="toggleAgreementContent(agreement.id)" class="accordion-header question-header">
              {{ agreement.agreement_name }}
              <span class="expand-button">&#9660;</span>
            </div>
            <div class="accordion-content" v-show="activeContent === agreement.id">
              <p>{{ agreement.content }}</p>
              <p><strong>Sıra Numarası:</strong> {{ agreement.order_number }}</p>
              <p><strong>Aktif:</strong> {{ agreement.is_active ? 'Evet' : 'Hayır' }}</p>
              <button class="edit-btn" @click="editAgreement(agreement)">Düzenle</button>
              <button class="delete-btn" @click="deleteAgreement(agreement.id)">Sil</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AgreementManagement',
  data() {
    return {
      agreements: [],
      currentAgreement: {
        id: null,
        category: '',
        agreement_name: '',
        content: '',
        order_number: 0,
        is_active: false
      },
      isEditing: false,
      activeAccordion: null,
      activeContent: null
    };
  },
  computed: {
    groupedAgreements() {
      return this.agreements.reduce((groups, agreement) => {
        const category = agreement.category || 'Kategori Belirtilmemiş';
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(agreement);
        return groups;
      }, {});
    }
  },
  async mounted() {
    await this.fetchAgreements();
  },
  methods: {
    async fetchAgreements() {
      try {
        const response = await axios.get('/admin/agreements');
        this.agreements = response.data.agreements;
      } catch (error) {
        console.error('Sözleşmeler alınırken hata oluştu:', error); 
      }
    },

    editAgreement(agreement) {
      this.currentAgreement = { ...agreement };
      this.isEditing = true;
    },

    async saveAgreement() {
      try {
        if (this.isEditing) {
          await axios.put(`/admin/agreements/${this.currentAgreement.id}`, this.currentAgreement);
          alert('Sözleşme başarıyla güncellendi!');
        } else {
          await axios.post('/admin/agreements', this.currentAgreement);
          alert('Sözleşme başarıyla eklendi!');
        }

        await this.fetchAgreements();
        this.resetForm();
      } catch (error) {
        console.error('Sözleşme kaydedilirken hata oluştu:', error);
        alert('Sözleşme kaydedilirken hata oluştu. Lütfen girişlerinizi kontrol edip tekrar deneyin.');
      }
    },

    async deleteAgreement(id) {
      try {
        const isConfirmed = window.confirm('Bu sözleşmeyi silmek istediğinizden emin misiniz?');

        if (isConfirmed) {
          await axios.delete(`/admin/agreements/${id}`);
          alert('Sözleşme başarıyla silindi.');
          await this.fetchAgreements();
        }
      } catch (error) {
        console.error('Sözleşme silinirken hata oluştu:', error);
        alert('Sözleşme silinirken hata oluştu. Lütfen tekrar deneyin.');
      }
    },

    resetForm() {
      this.currentAgreement = {
        id: null,
        category: '',
        agreement_name: '',
        content: '',
        order_number: 0,
        is_active: true
      };
      this.isEditing = false;
      this.activeAccordion = null;
      this.activeContent = null;
    },

    toggleAccordion(category) {
      this.activeAccordion = this.activeAccordion === category ? null : category;
      if (this.activeAccordion !== category) {
        this.activeContent = null;
      }
    },

    toggleAgreementContent(id) {
      this.activeContent = this.activeContent === id ? null : id;
    }
  }
};
</script>

  
  <style scoped>
  .agreement-container {
    width: 100%;
    margin: 0 auto;
    padding: 1em;
  }
  
  .accordion {
    border: none;
    margin-bottom: 0.8em;
  }
  
  .accordion-header {
    padding: 0.8em 2em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000; 
    font-weight: 600;
  }
  
  .accordion-header:hover {
    background-color: #070e11;
  }
  
  .category-content {
    padding: 0.5em 2em;
    background-color: #000000;
  }
  
  .question-header {
    background-color: #000000;
    margin-left: 1em;
  }
  
  .question-header:hover {
    background-color: #000000;
  }
  
  .accordion-content {
    padding: 0.8em 1em;
    margin-left: 2em;
    border-top: 1px solid #917e5c;
    background-color: #000000; 
  }
  
  .expand-button {
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .agreement-form {
    margin: 2em 0em;
    background-color: #000000;
    padding: 2em;
    border: 1px solid #353535;
    border-radius: 8px;
  }
  
  .agreement-form label {
    display: block;
    margin-bottom: 1em; 
  }
  
  .agreement-form input,
  .agreement-form textarea {
    width: 100%;
    padding: 0.5em;
    margin-top: 0.5em;
    border: 1px solid #1b1b1b;
    background-color: #000000;
    color: #b1c3c9;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .agreement-form input[type="checkbox"] {
    width: auto;
    margin-left: 0.5em;
  }
  
  .agreement-form input:focus,
  .agreement-form textarea:focus {
    outline: none;
    box-shadow: 0 0 3px #007999;
  }
  
  .agreement-form button {
    padding: 0.7em 1.5em;
    background-color: #127750;
    border: none;
    color: #cbe5ef;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 0.5em;
    transition: background-color 0.3s;
  }
  
  .agreement-form button:hover {
    background-color: #05ad68;
  }
  
  .agreement-form button:last-child {
    background-color: #953342;
  }
  
  .agreement-form button:last-child:hover {
    background-color: #bc1b3a;
  }
  
  .edit-btn {
    background-color: #127750;
    color: #cbe5ef;
    padding: 0.5em 1.5em;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 0.5em;
    border-radius: 4px;
  }
  
  .edit-btn:hover {
    background-color: #05ad68;
  }
  
  .delete-btn {
    background-color: #953342;
    color: #cbe5ef;
    padding: 0.5em 1.5em;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 4px;
  }
  
  .delete-btn:hover {
    background-color: #bc1b3a;
  }
  
  h4 {
    color: #917e5c;
    margin-bottom: 1em;
  }
  </style>
  