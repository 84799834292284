<template>
    <div> 
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  
  <script>   
import AllArchivedProducts from './AllArchivedProducts.vue';
import AllDeletedProducts from './AllDeletedProducts.vue';
import AllProductPrices from './AllProductPrices.vue';
import ProductCosts from './ProductCosts.vue';
import ProductFeatures from './ProductFeatures.vue';
import ProductImages from './ProductImages.vue';
import ProductManager from './ProductManager.vue'; 
import ProductPlattformManager from './ProductPlattformManager.vue';
import ProductPrices from './ProductPrices.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'ProductManager', 
        components: [
          { name: 'Güncel Ürünler', component: 'ProductManager' }, 
          { name: 'Ürün Fiyatları', component: 'ProductPrices' }, 
          { name: 'Tüm fiyatlar', component: 'AllProductPrices' },  
          { name: 'Ürün Maliyetleri', component: 'ProductCosts' },
          { name: 'Ürün Arşivi', component: 'AllArchivedProducts' },
          { name: 'Ürün Özellikleri', component: 'ProductFeatures' },
          { name: 'Ürün Platformları', component: 'ProductPlattformManager' },
          { name: 'Ürün Görselleri', component: 'ProductImages' }, 
          { name: 'CRUD', component: 'AllDeletedProducts' }, 
          
        ]
      };
    },
  
    components: {  
        ProductManager,
        ProductPrices,
        AllProductPrices, 
        AllArchivedProducts,
        AllDeletedProducts, 
        ProductFeatures,
        ProductPlattformManager,
        ProductCosts,
        ProductImages
    },
    
  };
  </script>
    
  <style scoped> 
      .button-container button.active {
    background-color: #815328;
    color: #ffffff; 
    } 
  </style>
    