<template>
    <div class="document-management">
      <h3>Doküman Yönetimi</h3>
  
      <h4>{{ isEdit ? "Doküman Düzenle" : "Yeni Doküman Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateDocument() : createDocument()">
        <input v-model="selectedDocument.name" placeholder="Doküman Adı" required />
  
        <div>
          <label for="document">Doküman:</label>
          <input class="upload-button" type="file" id="document" @change="handleFileUpload('document', $event)" />
        </div>
  
        <div>
          <label for="document-icon">Doküman İkonu:</label>
          <input class="upload-button" type="file" id="document-icon" @change="handleFileUpload('document_icon', $event)" />
          <img v-if="preview.document_icon" :src="preview.document_icon" alt="Doküman İkonu" class="image-preview" />
        </div>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Doküman Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Tüm Dokümanlar</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="document-table">
        <thead>
          <tr>
            <th>Doküman Adı</th>
            <th>Doküman Bağlantısı</th>
            <th>Doküman İkonu</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in documents" :key="document.document_id">
            <td>{{ document.name }}</td>
            <td><a :href="document.document_link" target="_blank">{{ document.document_link }}</a></td>
            <td>
              <img
                v-if="document.icon_link"
                :src="document.icon_link"
                alt="Doküman İkonu"
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <button @click="editDocument(document)">Düzenle</button>
              <button v-if="showDeleteButtons" class="delete" @click="confirmDelete(document.document_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        documents: [],
        selectedDocument: {
          document_id: null,
          name: "",
          document: null,
          document_icon: null,
        },
        preview: {
          document_icon: null,
        },
        isEdit: false,
        showDeleteButtons: false,
      };
    },
    methods: {
      async fetchDocuments() {
        try {
          const response = await axios.get("/admin/documents");
          this.documents = response.data.documents;
          console.log("Dokümanlar başarıyla alındı:", response.data);
        } catch (error) {
          console.error("Dokümanlar alınırken hata oluştu:", error);
        }
      },
      handleFileUpload(type, event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (type === "document_icon") {
            this.preview.document_icon = e.target.result;
            this.selectedDocument.document_icon = file;
          } else if (type === "document") {
            this.selectedDocument.document = file;
          }
        };
        reader.readAsDataURL(file);
      },
      async createDocument() {
        try {
          const formData = new FormData();
          formData.append("name", this.selectedDocument.name);
          if (this.selectedDocument.document) {
            formData.append("document", this.selectedDocument.document);
          }
          if (this.selectedDocument.document_icon) {
            formData.append("document_icon", this.selectedDocument.document_icon);
          }
  
          await axios.post("/admin/document", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
  
          this.fetchDocuments();
          this.resetForm();
        } catch (error) {
          console.error("Doküman eklenirken hata oluştu:", error);
        }
      },
      async updateDocument() {
        try {
          const formData = new FormData();
          formData.append("name", this.selectedDocument.name);
          if (this.selectedDocument.document) {
            formData.append("document", this.selectedDocument.document);
          }
          if (this.selectedDocument.document_icon) {
            formData.append("document_icon", this.selectedDocument.document_icon);
          }
  
          await axios.put(`/admin/document/${this.selectedDocument.document_id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
  
          this.fetchDocuments();
          this.resetForm();
        } catch (error) {
          console.error("Doküman güncellenirken hata oluştu:", error);
        }
      },
      editDocument(document) {
        this.selectedDocument = { ...document, document: null, document_icon: null };
        this.preview.document_icon = document.icon_link;
        this.isEdit = true;
      },
      async deleteDocument(id) {
        try {
          await axios.delete(`/admin/document/${id}`);
          this.fetchDocuments();
        } catch (error) {
          console.error("Doküman silinirken hata oluştu:", error);
        }
      },
      confirmDelete(id) {
        if (confirm("Bu dokümanı silmek istediğinizden emin misiniz?")) {
          this.deleteDocument(id);
        }
      },
      resetForm() {
        this.selectedDocument = {
          document_id: null,
          name: "",
          document: null,
          document_icon: null,
        };
        this.preview.document_icon = null;
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchDocuments();
    },
  };
  </script>
  