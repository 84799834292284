<template>
    <div class="product-cost-manager">
      <h3>Ürün Maliyetleri</h3>
      <div class="alert info"><b>Usd ve Euro cinsinden girerseniz, buraya kaydı girdiğiniz/oluşturduğunuz tarihteki döviz kuru baz alınır.</b> 
        <br>Sistem sekmesinde dövüz kurları hergün öğleyin saat 12:00 daki fiyatları baz alır. <br>Bu ssati değiştirmek için Test sekmesinde CRON işlemleri güncelleyebilirsiniz.
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Ürün Adı</th>
            <th>Maliyet (Maks. Sıfırdan sonra 2 Basamak)</th>
            <th>Parabirimi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.product_id">
            <td>{{ product.product_name }}</td>
            <td>
              <input 
                type="number" 
                step="0.01" 
                :value="getProductCostAmount(product.product_id)" 
                @blur="onCostBlur(product.product_id, $event.target.value)"
              />
            </td>
            <td>
              <select 
                :value="getProductCostCurrency(product.product_id)" 
                @change="onCurrencyChange(product.product_id, $event.target.value)"
              >
                <option v-for="currency in currencies" 
                        :key="currency.currency_id" 
                        :value="currency.currency_id">
                  {{ currency.currency_name }} ({{ currency.currency_symbol }})
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "ProductCostManager",
    data() {
      return {
        products: [],
        currencies: [], 
        productCostsMap: {},
      };
    },
    methods: { 
      async fetchProducts() {
        try {
          const response = await axios.get("/admin/products");
          this.products = response.data;
        } catch (error) {
          console.error("Ürünler alınırken hata:", error);
        }
      }, 
      async fetchCurrencies() {
        try {
          const response = await axios.get("/admin/currencies");
          this.currencies = response.data;
        } catch (error) {
          console.error("Parabirimleri alınırken hata:", error);
        }
      }, 
      async fetchProductCosts() {
        try {
          const response = await axios.get("/admin/newproductcosts"); 
          this.productCostsMap = {};
          response.data.forEach(cost => {
            this.productCostsMap[cost.product_id] = {
              amount: cost.amount,
              currency_id: cost.currency_id,
            };
          });
        } catch (error) {
          console.error("Ürün maliyetleri alınırken hata:", error);
        }
      }, 
      getProductCostAmount(productId) {
        return this.productCostsMap[productId] ? this.productCostsMap[productId].amount : "";
      }, 
      getProductCostCurrency(productId) {
        return this.productCostsMap[productId] ? this.productCostsMap[productId].currency_id : "";
      }, 
      async onCostBlur(productId, value) {
        const amount = parseFloat(value).toFixed(2); 
        const currency_id = this.getProductCostCurrency(productId) || (this.currencies[0] && this.currencies[0].currency_id);
        await this.upsertCost(productId, amount, currency_id);
      }, 
      async onCurrencyChange(productId, newCurrencyId) {
        const amount = this.getProductCostAmount(productId) || 0;
        await this.upsertCost(productId, amount, newCurrencyId);
      }, 
      async upsertCost(productId, amount, currency_id) {
        try {
          const payload = { amount, currency_id };
          const response = await axios.put(`/admin/newproducts/${productId}/costs`, payload); 
          this.productCostsMap[productId] = {
            amount: response.data.amount,
            currency_id: response.data.currency_id,
          };
        } catch (error) {
          console.error("Ürün maliyeti güncellenirken hata:", error);
        }
      },
      
      async fetchAllData() {
        await Promise.all([
          this.fetchProducts(),
          this.fetchCurrencies(),
          this.fetchProductCosts(),
        ]);
      },
    },
    mounted() {
      this.fetchAllData();
    },
  };
  </script>
  
  <style scoped> 
  </style>
  