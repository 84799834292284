<template>
  <div class="container">
    <h3>Ürün ve Parti No ve CI Kod Yönetimi</h3>

    <div class="alert info">
      Bir ürün Seçin -> Bir Parti no ekleyin (eklenen parti noyu değiştirirseniz otomatik güncellenir) <br>
      Bir Parti no ile ilişkili CI kodu ekleyin. (eklenen CI kodunu değiştirirseniz otomatik güncellenir)
    </div>

    <label>Ürün Seç:</label>
    <select v-model="selectedProductId">
      <option v-for="category in minorCategories" :key="category.id" :value="category.id">
        {{ category.name }}
      </option>
    </select>

    <h3>Parti Yönetimi</h3>
    <div class="new-form">
      <label>Yeni Parti Adı ya da no:</label>
      <input v-model="newBatch.batch_name" placeholder="Parti adı ya da no">
      <label>Detaylar:</label>
      <input v-model="newBatch.details" placeholder="Detaylar">
      <button class="confirm" @click="createProductBatch">Ekle</button>
    </div>

    <ul>
      <li v-for="batch in productBatches" :key="batch.id">
        <label> Parti Adı:</label>
        <input v-model="batch.batch_name" @change="updateProductBatch(batch)">
        <label> Parti Detayı:</label>
        <input v-model="batch.details" @change="updateProductBatch(batch)">
        <button class="delete" @click="confirmDeleteProductBatch(batch.id)">Sil</button>
        <button class="confirm" @click="openCiModal(batch.id)">CI Kodlarını Görüntüle</button>
      </li>
    </ul>

    <!-- CI Kodları Modal -->
    <div class="modal-overlay" v-if="showCiModal" @click="closeModalOnClickOutside">
        <div class="modal" @click.stop>
           <div class="button-container">
             <button class="delete" @click="closeCiModal">Kapat</button>
           </div>
           <h3>CI Kodları Yönetimi</h3> 

          <div class="new-form">
          <label>Yeni CI Kodu:</label>
          <input v-model="newCiCode.ci_code" placeholder="CI Kodu">
          <label>Renk Kodu (örn. #0a0a0a):</label>
          <input v-model="newCiCode.color_code" placeholder="Renk Kodu">
          <label>Detaylar:</label>
          <input v-model="newCiCode.details" placeholder="Detaylar">
          <button class="confirm" @click="createCiCode">Ekle</button>
        </div>

        <ul>
          <li v-for="ci in ciCodes" :key="ci.id">
            <input v-model="ci.ci_code" @change="updateCiCode(ci)">
            <input v-model="ci.color_code" @change="updateCiCode(ci)">
            <input v-model="ci.details" @change="updateCiCode(ci)">
            <button class="delete" @click="confirmDeleteCiCode(ci.id)">Sil</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import axios from 'axios';

const minorCategories = ref([]);
const selectedProductId = ref(null);
const productBatches = ref([]);
const selectedBatchId = ref(null);
const ciCodes = ref([]);
const showCiModal = ref(false);

const newBatch = ref({ batch_name: '', details: '' });
const newCiCode = ref({ ci_code: '', color_code: '', details: '' });

// Data fetching functions (öncekiyle aynı)
const fetchMinorCategories = async () => {
  const response = await axios.get('/admin/minor-categories');
  minorCategories.value = response.data.minorCategories;
};

const fetchProductBatches = async () => {
  if (!selectedProductId.value) return;
  const response = await axios.get(`/admin/product-batches/${selectedProductId.value}`);
  productBatches.value = response.data.batches;
};

const fetchCiCodes = async () => {
  if (!selectedBatchId.value) return;
  const response = await axios.get(`/admin/ci-codes/${selectedBatchId.value}`);
  ciCodes.value = response.data.ciCodes;
};

const closeModalOnClickOutside = (event) => {
  if (event.target.classList.contains("modal-overlay")) {
    closeCiModal();
  }
}; 

// CRUD işlemleri ve alert onayı
const createProductBatch = async () => {
  await axios.post('/admin/product-batch', {
    product_id: selectedProductId.value,
    batch_name: newBatch.value.batch_name,
    details: newBatch.value.details
  });
  newBatch.value = { batch_name: '', details: '' };
  fetchProductBatches();
};

const updateProductBatch = async (batch) => {
  await axios.put(`/admin/product-batch/${batch.id}`, batch);
  fetchProductBatches();
};

const confirmDeleteProductBatch = (batchId) => {
  if (confirm('Partiyi silmek istediğinize emin misiniz?')) {
    deleteProductBatch(batchId);
  }
};

const deleteProductBatch = async (batchId) => {
  await axios.delete(`/admin/product-batch/${batchId}`);
  fetchProductBatches();
};

const createCiCode = async () => {
  await axios.post('/admin/ci-code', {
    product_id: selectedProductId.value,
    productbatch_id: selectedBatchId.value,
    ...newCiCode.value
  });
  newCiCode.value = { ci_code: '', color_code: '', details: '' };
  fetchCiCodes();
};

const updateCiCode = async (ci) => {
  await axios.put(`/admin/ci-code/${ci.id}`, ci);
  fetchCiCodes();
};

const confirmDeleteCiCode = (ciId) => {
  if (confirm('CI kodunu silmek istediğinize emin misiniz?')) {
    deleteCiCode(ciId);
  }
};

const deleteCiCode = async (ciId) => {
  await axios.delete(`/admin/ci-code/${ciId}`);
  fetchCiCodes();
};

// Modal controls
const openCiModal = (batchId) => {
  selectedBatchId.value = batchId;
  showCiModal.value = true;
};

const closeCiModal = () => {
  showCiModal.value = false;
};

watch(selectedProductId, fetchProductBatches);
onMounted(fetchMinorCategories);
watch(selectedBatchId, fetchCiCodes);
</script>


<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.new-form {
  background-color: #050505;
  padding: 2rem;
  border: 1px solid #6d6d6d;
  border-radius: 5px;
  margin: 1rem 0;
}

h2, h3 {
  margin-bottom: 10px;
}
select, input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
}
button {
  margin-right: 5px;
  padding: 8px 12px;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  overflow: auto;
  max-height: 600px;
  max-width: 600px;
  width: 100%; 
}
li{margin: 2rem 0;  background-color: #050505;
  padding: 2rem;}
</style>
