<template>
    <div> 
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  
  <script> 
  import ProductGroupListManager from './ProductGroupListManager.vue';
import ProductGroupManager from './ProductGroupManager.vue'; 
import PromotionManager from './PromotionManager.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'ProductGroupManager', 
        components: [
         { name: 'Ürün Grubu/Seti', component: 'ProductGroupManager' }, 
          { name: 'Ürün gruplarındaki ürünler', component: 'ProductGroupListManager' }, 
          { name: 'Promosyonlar', component: 'PromotionManager' }, 
          
        ]
      };
    },
  
    components: { 
        ProductGroupManager,
        ProductGroupListManager,
        PromotionManager
    },
    
  };
  </script>
    
  <style scoped> 
      .button-container button.active {
    background-color: #815328;
    color: #ffffff; 
    } 
  </style>
    