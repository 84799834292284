<template>
    <div class="container">
      <h3>GPT Yönetimi</h3>
  <div class="alert warning">
Lütfen sıcaklık ayarını 0 ile 1 arasında kullanın. Örneğin 0.5. <br> Farklı değerler hatalara neden olur
  </div>
      <label class="toggle-delete">
        <input type="checkbox" v-model="showDeleteButtons" />
        Sil butonlarını göster
      </label>
   
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Ad</th>
            <th>Model</th>
            <th>Sıcaklık</th>
            <th>Maksimum Token</th>
            <th>Prompt</th>
            <th>İzin Verilen Konular</th>
            <th>Durum</th>
            <th v-if="showDeleteButtons">Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="gpt in gpts" :key="gpt.gpt_id">
            <td>{{ gpt.gpt_id }}</td>
            <td contenteditable @blur="updateGPT(gpt, 'gpt_name', $event)">{{ gpt.gpt_name }}</td>
             
            <td>
              <select v-model="gpt.model_name" @change="updateGPT(gpt, 'model_name', $event, true)">
                <option v-for="model in gptNames" :key="model.id" :value="model.gpt_name">
                  {{ model.gpt_name }}
                </option>
              </select>
            </td>
  
            <td contenteditable @blur="updateGPT(gpt, 'temperature', $event)">{{ gpt.temperature }}</td>
            <td contenteditable @blur="updateGPT(gpt, 'max_tokens', $event)">{{ gpt.max_tokens }}</td>
            <td contenteditable @blur="updateGPT(gpt, 'system_prompt', $event)">{{ gpt.system_prompt }}</td>
            <td contenteditable @blur="updateGPT(gpt, 'allowed_topics', $event)">{{ gpt.allowed_topics }}</td>
             
            <td>
              <select v-model="gpt.is_active" @change="updateGPT(gpt, 'is_active', $event, true)">
                <option :value="true">Aktif</option>
                <option :value="false">Pasif</option>
              </select>
            </td>
  
            <td v-if="showDeleteButtons">
              <button class="delete" @click="deleteGPT(gpt.gpt_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <h3>Yeni GPT Oluştur</h3>
      <form @submit.prevent="createGPT">
        <input type="text" v-model="newGPT.gpt_name" placeholder="GPT Adı" required />
         
        <select v-model="newGPT.model_name" required>
          <option v-for="model in gptNames" :key="model.id" :value="model.gpt_name">
            {{ model.gpt_name }}
          </option>
        </select>
  
        <input type="number" step="0.1" v-model="newGPT.temperature" placeholder="Sıcaklık (0-1)" required />
        <input type="number" v-model="newGPT.max_tokens" placeholder="Maksimum Token" required />
        <textarea v-model="newGPT.system_prompt" placeholder="GPT'nin Promptu"></textarea>
        <input type="text" v-model="newGPT.allowed_topics" placeholder="İzin verilen konular (JSON formatında)" />
  
        <select v-model="newGPT.is_active">
          <option :value="true">Aktif</option>
          <option :value="false">Pasif</option>
        </select>
  
        <button type="submit">GPT Oluştur</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { ref, onMounted } from "vue";
  
  export default {
    setup() {
      const gpts = ref([]);
      const gptNames = ref([]);
      const showDeleteButtons = ref(false);
      const newGPT = ref({
        gpt_name: "",
        model_name: "",
        temperature: 0.7,
        max_tokens: 1000,
        system_prompt: "",
        allowed_topics: "{}",
        is_active: true,
      });
  
      //  GPT Listesini API'den çek
      const fetchGPTs = async () => {
        try {
          const response = await axios.get("/admin/gpt/gpt");
          gpts.value = response.data.gpts;
        } catch (error) {
          console.error("GPT listesi alınırken hata:", error);
        }
      };
  
      //  GPT İsimlerini API'den çek
      const fetchGPTNames = async () => {
        try {
          const response = await axios.get("/admin/gpt/gptnames");
          gptNames.value = response.data.gptNames;
        } catch (error) {
          console.error("GPT isimleri alınırken hata:", error);
        }
      };
  
      //  GPT Güncelleme Fonksiyonu
      const updateGPT = async (gpt, field, event, isSelect = false) => {
        const newValue = isSelect ? event.target.value : event.target.innerText;
        try {
          await axios.put(`/admin/gpt/gpt/${gpt.gpt_id}`, { [field]: newValue });
          gpt[field] = newValue;
        } catch (error) {
          console.error("GPT güncellenirken hata:", error);
        }
      };
  
      //  GPT Silme Fonksiyonu
      const deleteGPT = async (gpt_id) => {
        if (!confirm("Bu GPT'yi kalıcı olarak silmek istediğinize emin misiniz?")) return;
        try {
          await axios.delete(`/admin/gpt/gpt/${gpt_id}`);
          gpts.value = gpts.value.filter((gpt) => gpt.gpt_id !== gpt_id);
        } catch (error) {
          console.error("GPT silinirken hata:", error);
        }
      };
  
      //  Yeni GPT Oluşturma
      const createGPT = async () => {
        try {
          const response = await axios.post("/admin/gpt/gpt", newGPT.value);
          gpts.value.push(response.data.gpt);
          newGPT.value = { gpt_name: "", model_name: "", temperature: 0.7, max_tokens: 1000, system_prompt: "", allowed_topics: "{}", is_active: true };
        } catch (error) {
          console.error("GPT oluşturulurken hata:", error);
        }
      };
  
      onMounted(() => {
        fetchGPTs();
        fetchGPTNames();
      });
  
      return { gpts, gptNames, showDeleteButtons, newGPT, updateGPT, deleteGPT, createGPT };
    },
  };
  </script>
   
  
  <style scoped>
  .container {
    max-width: 1280px;
    margin: auto;
    padding: 20px;
  }
  h3 {
    text-align: center;
  } 
  .toggle-delete {
    display: flex;
    align-items: center;
    margin: 10px 0;
  } 
  .toggle-delete input {
    margin-right: 5px;
  } 
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  input, select  {
    padding: 5px; 
  }
  td  {
    max-width: 350px; 
  }
  </style> 