<template>
  <div class="product-management">
    <h3>Ürünler</h3>

    <h4>{{ isEdit ? "Ürünü Düzenle" : "Yeni Ürün Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateProduct() : createProduct()">
      <input v-model="selectedProduct.name" placeholder="Ürün Adı" required />
      <input v-model="selectedProduct.description" placeholder="Açıklama" />
      <select v-model="selectedProduct.main_category_id" required @change="filterSubcategories">
        <option disabled value="">Ana Kategoriyi Seçin</option>
        <option v-for="category in categories" :key="category.id" :value="category.id">
          {{ category.name }}
        </option>
      </select>
      <select v-model="selectedProduct.subcategory_id" required>
        <option disabled value="">Alt Kategoriyi Seçin</option>
        <option v-for="subcategory in filteredSubcategories" :key="subcategory.id" :value="subcategory.id">
          {{ subcategory.name }}
        </option>
      </select>
      <button type="submit">{{ isEdit ? "Güncelle" : "Ürün Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Ürünler</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table>
      <thead>
        <tr>
          <th>Ürün Adı</th>
          <th>Açıklama</th>
          <th>Ana Kategori</th>
          <th>Alt Kategori</th>
          <th>Düzenle</th>
          <th v-if="showDeleteButtons">Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.id">
          <td>{{ product.name }}</td>
          <td>{{ product.description }}</td>
          <td>{{ product.mainCategory.name }}</td>
          <td>{{ product.subcategory.name }}</td>
          <td><button @click="editProduct(product)">Ürünü Düzenle</button></td>
          <td v-if="showDeleteButtons"><button @click="confirmDelete(product.id)">Ürünü Sil</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default { 
  data() {
    return {
      products: [],
      categories: [],
      subcategories: [],
      filteredSubcategories: [],
      selectedProduct: {
        id: null,
        name: '',
        description: '',
        main_category_id: '',
        subcategory_id: '',
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get('/admin/products');
        this.products = response.data.products;
      } catch (error) {
        console.error('Ürünler alınırken hata oluştu:', error); 
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('/admin/main-categories');
        this.categories = response.data.mainCategories;
      } catch (error) {
        console.error('Kategoriler alınırken hata oluştu:', error); 
      }
    },
    async fetchSubcategories() {
      try {
        const response = await axios.get('/admin/sub-categories');
        this.subcategories = response.data.subCategories;
      } catch (error) {
        console.error('Alt kategoriler alınırken hata oluştu:', error); 
      }
    },
    filterSubcategories() {
      const mainCategoryId = this.selectedProduct.main_category_id;
      this.filteredSubcategories = this.subcategories.filter(
        subcategory => subcategory.main_category_id === mainCategoryId
      );
      this.selectedProduct.subcategory_id = '';
    },
    async createProduct() {
      try {
        await axios.post('/admin/product', {
          name: this.selectedProduct.name,
          description: this.selectedProduct.description,
          main_category_id: this.selectedProduct.main_category_id,
          subcategory_id: this.selectedProduct.subcategory_id,
        });
        this.fetchProducts();
        this.resetForm(); 
      } catch (error) {
        console.error('Ürün eklenirken hata oluştu:', error); 
      }
    },
    editProduct(product) {
      this.selectedProduct = { ...product };
      this.isEdit = true;
      this.filterSubcategories();
    },
    async updateProduct() {
      try {
        await axios.put(`/admin/product/${this.selectedProduct.id}`, {
          name: this.selectedProduct.name,
          description: this.selectedProduct.description,
          main_category_id: this.selectedProduct.main_category_id,
          subcategory_id: this.selectedProduct.subcategory_id,
        });
        this.fetchProducts();
        this.resetForm(); 
      } catch (error) {
        console.error('Ürün güncellenirken hata oluştu:', error); 
      }
    },
    async deleteProduct(id) {
      try {
        await axios.delete(`/admin/product/${id}`);
        this.fetchProducts(); 
      } catch (error) {
        console.error('Ürün silinirken hata oluştu:', error); 
      }
    },
    confirmDelete(id) {
      if (confirm('Bu ürünü silmek istediğinizden emin misiniz?')) {
        this.deleteProduct(id);
      }
    },
    resetForm() {
      this.selectedProduct = {
        id: null,
        name: '',
        description: '',
        main_category_id: '',
        subcategory_id: '',
      };
      this.isEdit = false;
      this.filteredSubcategories = [];
    },
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchCategories();
    this.fetchSubcategories();
  },
};
</script>

<style scoped> 
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
} 
</style>
