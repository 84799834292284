<template>
  <div class="container">
    <h4>E-posta Kampanyaları Yönetimi</h4>
 
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Adı</th>
          <th>Şablon</th>
          <th>Durum</th>
          <th>Dakikada Gönderilecek E-postalar</th>
          <th>Zamanlanmış Başlangıç</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="campaign in campaigns" :key="campaign.campaign_id">
          <td>{{ campaign.campaign_id }}</td>
          <td>{{ campaign.name }}</td>
          <td>{{ campaign.template?.name || 'N/A' }}</td>
          <td>{{ campaign.status }}</td>
          <td>{{ campaign.emails_per_minute }}</td>
          <td>{{ campaign.scheduled_start || 'Zamanlanmadı' }}</td>
          <td>
            <button @click="editCampaign(campaign)">Düzenle</button>
            <button @click="confirmDelete(campaign.campaign_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
 
    <div class="form-area">
      <h4>{{ isEditing ? 'Kampanyayı Düzenle' : 'Yeni Kampanya Ekle' }}</h4>
      <form @submit.prevent="submitCampaign">
        <input v-model="form.name" placeholder="Kampanya Adı" required />
        <select v-model="form.template_id" required>
          <option disabled value="">Şablon Seçin</option>
          <option v-for="template in templates" :key="template.template_id" :value="template.template_id">
            {{ template.name }}
          </option>
        </select>
        <select v-model="form.status" required>
          <option disabled value="">Durum Seçin</option>
          <option value="draft">Taslak</option>
          <option value="active">Aktif</option> 
          <option value="completed">Tamamlandı</option>
        </select>
        <input v-model="form.emails_per_minute" type="number" placeholder="Dakikada Gönderilecek E-posta Sayısı" required />
        <input v-model="form.scheduled_start" type="datetime-local" placeholder="Zamanlanmış Başlangıç" />
        <button type="submit">{{ isEditing ? 'Güncelle' : 'Ekle' }}</button>
        <button type="button" @click="resetForm">İptal</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    campaigns: [],
    templates: [],
    form: {
      campaign_id: null,
      name: '',
      template_id: '',
      status: '',
      emails_per_minute: 10,
      scheduled_start: '',
    },
    isEditing: false,
  };
},
async mounted() {
  await this.fetchCampaigns();
  await this.fetchTemplates();
},
methods: {
  async fetchCampaigns() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailcampaigns', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.campaigns = response.data;
    } catch (error) {
      console.error('Kampanyalar alınırken hata oluştu:', error);
    }
  },
  async fetchTemplates() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailtemplates', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.templates = response.data;
    } catch (error) {
      console.error('Şablonlar alınırken hata oluştu:', error);
    }
  },
  editCampaign(campaign) {
    this.form = { ...campaign };
    this.isEditing = true;
  },
  confirmDelete(campaignId) {
    if (confirm('Bu kampanyayı silmek istediğinize emin misiniz?')) {
      this.deleteCampaign(campaignId);
    }
  },
  async deleteCampaign(campaignId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/emailcampaigns/${campaignId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchCampaigns();
    } catch (error) {
      console.error('Kampanya silinirken hata oluştu:', error);
    }
  },
  async submitCampaign() {
    try {
      const token = localStorage.getItem('token');
      const method = this.form.campaign_id ? 'put' : 'post';
      const url = this.form.campaign_id
        ? `/admin/emailcampaigns/${this.form.campaign_id}`
        : '/admin/emailcampaigns';
      await axios[method](url, this.form, { 
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchCampaigns();
      this.resetForm();
    } catch (error) {
      console.error('Kampanya eklenirken/düzenlenirken hata oluştu:', error);
    }
  },
  resetForm() {
    this.form = {
      campaign_id: null,
      name: '',
      template_id: '',
      status: '',
      emails_per_minute: 10,
      scheduled_start: '',
    };
    this.isEditing = false;
  },
},
};
</script>
 