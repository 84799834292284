<template>
    <div> 
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script> 
  import SurveyManager from './SurveyManager.vue';
  import SurveyEditor from './SurveyEditor.vue'; 
  import SurveyHistory from './SurveyHistory.vue'; 
  import SurveyResponses from './SurveyResponses.vue'; 

  export default {
    data() {
      return {
        currentComponent: 'SurveyManager',
        components: [ 
          { name: 'Anket Yönetim', component: 'SurveyManager' },
          { name: 'Anket Düzenleyici', component: 'SurveyEditor' },
          { name: 'Anket Geçmişi', component: 'SurveyHistory' },
          { name: 'Anket Yanıtları', component: 'SurveyResponses' },
        ]
      };
    }, 
    components: { 
      SurveyManager,
      SurveyEditor,
      SurveyHistory,
      SurveyResponses                           
    }
  };
  </script>
  
  <style scoped>
  .button-container {
  margin: 2em 0.2em 0.6em 0.2em;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  }
   
  </style>
  