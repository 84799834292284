<template>
  <div class="container">
    <h3>Ürün Özellikleri Yönetimi</h3>

    <label>Ürün Seç:</label>
    <select v-model="selectedProductId">
      <option v-for="category in minorCategories" :key="category.id" :value="category.id">
        {{ category.name }}
      </option>
    </select>

    <h3>Yeni Özellik Ekle</h3>
    <div class="new-form">
      <label>Özellik Adı:</label>
      <input v-model="newFeature.name" placeholder="Özellik adı">
      <label>Detay:</label>
      <textarea v-model="newFeature.detail" placeholder="Detay"></textarea>
      <button class="confirm" @click="createProductFeature">Ekle</button>
    </div>

    <h3>Seçilen Ürünün özellikleri</h3>
    <ul>
      <li v-for="feature in productFeatures" :key="feature.id">
        <input v-model="feature.name" @change="updateProductFeature(feature)">
        <input v-model="feature.detail" @change="updateProductFeature(feature)">
        <button class="delete" @click="deleteProductFeature(feature.id)">Sil</button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import axios from 'axios';

const minorCategories = ref([]);
const selectedProductId = ref(null);
const productFeatures = ref([]);

const newFeature = ref({ name: '', detail: '' });

const fetchMinorCategories = async () => {
  try {
    const response = await axios.get('/admin/minor-categories');
    minorCategories.value = response.data.minorCategories;
  } catch (error) {
    console.error('Ürünler alınırken hata oluştu:', error);
  }
};

const fetchProductFeatures = async () => {
  if (!selectedProductId.value) return;
  try {
    const response = await axios.get(`/admin/product-features/${selectedProductId.value}`);
    productFeatures.value = response.data.features;
  } catch (error) {
    console.error('Ürün özellikleri alınırken hata oluştu:', error);
  }
};

const createProductFeature = async () => {
  try {
    await axios.post('/admin/product-feature', {
      product_id: selectedProductId.value,
      name: newFeature.value.name,
      detail: newFeature.value.detail
    });
    newFeature.value = { name: '', detail: '' };
    fetchProductFeatures();
  } catch (error) {
    console.error('Ürün özelliği oluşturulurken hata oluştu:', error);
  }
};

const updateProductFeature = async (feature) => {
  try {
    await axios.put(`/admin/product-feature/${feature.id}`, {
      name: feature.name,
      detail: feature.detail
    });
    fetchProductFeatures();
  } catch (error) {
    console.error('Ürün özelliği güncellenirken hata oluştu:', error);
  }
};

const deleteProductFeature = async (featureId) => {
  if (!confirm("Bu özelliği silmek istediğinizden emin misiniz?")) return;

  try {
    await axios.delete(`/admin/product-feature/${featureId}`);
    fetchProductFeatures();
  } catch (error) {
    console.error('Ürün özelliği silinirken hata oluştu:', error);
  }
};
 
watch(selectedProductId, () => {
  productFeatures.value = [];
  fetchProductFeatures();
});
 
onMounted(fetchMinorCategories);
</script>


<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
h2, h3 {
  margin-bottom: 10px;
}
select, input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
}
button {
  margin-right: 5px;
  padding: 8px 12px;
}
.new-form {
  background-color: #050505;
  padding: 2rem;
}
</style>
