<template>
  <div class="announcements">
    <form @submit.prevent="isEditMode ? updateAnnouncement() : createAnnouncement()">
      <h4>{{ isEditMode ? 'Duyuruyu Güncelle' : 'Yeni Duyuru Oluştur' }}</h4>
      
      <label>Tür</label>
      <input v-model="formData.type" placeholder="Türü giriniz" required />
  
      <label>Hizmetler</label>
      <input v-model="formData.services" placeholder="Hizmet bilgilerini giriniz" />
  
      <label>Başlangıç Zamanı</label>
      <input v-model="formData.start_time" type="datetime-local" />
  
      <label>Bitiş Zamanı</label>
      <input v-model="formData.end_time" type="datetime-local" />
  
      <label>Süre</label>
      <input v-model="formData.duration" placeholder="Süreyi giriniz" />
  
      <label>Başlıklar</label>
      <textarea v-model="formData.headers" placeholder="Başlıkları giriniz"></textarea>
  
      <label>Detaylar</label>
      <textarea v-model="formData.details" placeholder="Detayları giriniz" required></textarea>
  
      <label>Durum</label>
      <select v-model="formData.status">
        <option value="">-- Durum Seçin --</option> 
        <option value="Yaklaşan">Yaklaşan</option>
        <option value="Devam Ediyor">Devam Ediyor</option>
        <option value="Tamamlandı">Tamamlandı</option>
        <option value="İptal Edildi">İptal Edildi</option>
      </select>
      
      <label>Tamamlanma Notu</label>
      <textarea v-model="formData.completed_note" placeholder="Tamamlanma notunu giriniz"></textarea>
  
      <label>Önemli Mi?</label>
      <input style="max-width: 1.2em;" type="checkbox" v-model="formData.is_important" /> <br>
  
      <button type="submit">{{ isEditMode ? 'Güncelle' : 'Oluştur' }}</button>
      <button type="button" v-if="isEditMode" @click="cancelEdit">İptal</button>
    </form>
   
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Tür</th>
          <th>Hizmetler</th>
          <th>Detaylar</th>
          <th>Başlangıç Zamanı</th>
          <th>Bitiş Zamanı</th>
          <th>Durum</th>
          <th>Önemli</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="announcement in announcements" :key="announcement.announcement_id">
          <td>{{ announcement.announcement_id }}</td>
          <td>{{ announcement.type }}</td>
          <td>{{ announcement.services }}</td>
          <td>{{ announcement.details }}</td>
          <td>{{ announcement.start_time }}</td>
          <td>{{ announcement.end_time }}</td>
          <td>{{ announcement.status }}</td>
          <td>{{ announcement.is_important ? 'Evet' : 'Hayır' }}</td>
          <td>
            <button @click="editAnnouncement(announcement)">Düzenle</button>
            <button class="delete" @click="deleteAnnouncement(announcement.announcement_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      announcements: [],
      isEditMode: false,
      formData: {
        announcement_id: null,
        type: '',
        services: '',
        start_time: '',
        end_time: '',
        duration: null,
        headers: '',
        details: '',
        status: 'Yaklaşan',
        completed_note: '',
        is_important: false
      }
    };
  },
  created() {
    this.fetchAnnouncements();
  },
  methods: {
    async fetchAnnouncements() {
      try {
        const response = await axios.get('/admin/announcements');
        this.announcements = response.data.map(announcement => ({
          ...announcement,
          start_time: this.formatDateTimeUTC(announcement.start_time),
          end_time: this.formatDateTimeUTC(announcement.end_time)
        })).sort((a, b) => b.announcement_id - a.announcement_id);
      } catch (error) {
        console.error('Duyurular alınırken hata oluştu:', error);
      }
    },
    formatDateTimeUTC(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    async createAnnouncement() {
      try {
        const data = {
          ...this.formData,
          start_time: this.formData.start_time || null,
          end_time: this.formData.end_time || null,
        };
        await axios.post('/admin/announcements', data);
        this.fetchAnnouncements();
        this.resetForm();
      } catch (error) {
        console.error('Duyuru oluşturulurken hata oluştu:', error);
      }
    },
    async updateAnnouncement() {
      try {
        const data = {
          ...this.formData,
          start_time: this.formData.start_time || null,
          end_time: this.formData.end_time || null,
        };
        await axios.put(`/admin/announcements/${this.formData.announcement_id}`, data);
        this.fetchAnnouncements();
        this.resetForm();
      } catch (error) {
        console.error('Duyuru güncellenirken hata oluştu:', error);
      }
    },
    async deleteAnnouncement(id) {
      try {
        const confirmation = window.confirm("Bu duyuruyu silmek istediğinize emin misiniz?");
        if (!confirmation) return; 
        await axios.delete(`/admin/announcements/${id}`);
        this.fetchAnnouncements();
      } catch (error) {
        console.error('Duyuru silinirken hata oluştu:', error);
      }
    },
    editAnnouncement(announcement) {
      this.isEditMode = true;
      this.formData = {
        ...announcement,
        start_time: this.formatDateTimeUTC(announcement.start_time),
        end_time: this.formatDateTimeUTC(announcement.end_time)
      };
    },
    cancelEdit() {
      this.resetForm();
    },
    resetForm() {
      this.isEditMode = false;
      this.formData = {
        announcement_id: null,
        type: '',
        services: '',
        start_time: '',
        end_time: '',
        duration: null,
        headers: '',
        details: '',
        status: 'Yaklaşan',
        completed_note: '',
        is_important: false
      };
    }
  }
};
</script>

<style scoped>
.announcements {
  margin: 20px; 
}

label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

input,
textarea,
select {
  width: 100%;
  margin-top: 5px;
  padding: 8px;
  box-sizing: border-box;
} 
</style>

  