<template>
    <div> 
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  
  <script>  
  export default {
    data() {
      return {
        currentComponent: 'CurrentStocks', 
        components: [
          { name: 'Ödeme Yöntemleri', component: 'CurrentStocks' }, 
          { name: 'Özetler', component: 'ProductPrices' }, 
          { name: 'Giriş/Çıkış Ekle', component: 'AllProductPrices' }, 
          { name: 'Giderler', component: 'AllProductPr2ices' }, 
          { name: 'Gelirler', component: 'AllProductPrw2iaces' }, 
          { name: 'İstatistikler', component: 'AllProductPrw2ices' }, 
          { name: 'Aktiviteler', component: 'AllProdudctPrw2ices' }, 
        ]
      };
    },
  
    components: { 
    },
    
  };
  </script>
    
  <style scoped> 
      .button-container button.active {
    background-color: #815328;
    color: #ffffff; 
    } 
  </style>
    