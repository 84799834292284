<template>
    <div>
      <h3>Promosyonlar</h3>
  
      <table v-if="promotions.length" class="promotion-table">
        <thead>
          <tr>
            <th>Promosyon ID</th>
            <th>Ürün Grubu / Set Adı</th>
            <th>Başlangıç Tarihi</th>
            <th>Bitiş Tarihi</th>
            <th>İndirim Oranı (%)</th>
            <th>İndirim Miktarı</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="promo in promotions" :key="promo.promotion_id">
            <td>{{ promo.promotion_id }}</td>
            <td>{{ promo.productGroup ? promo.productGroup.productgroup_name : 'Ürün Grubu Bulunamadı' }}</td>
            <td>{{ promo.start_date }}</td>
            <td>{{ promo.end_date }}</td>
            <td>{{ promo.discount_percentage ? promo.discount_percentage : '-' }}%</td>
            <td>{{ promo.discount_amount ? promo.discount_amount : '-' }} TL</td>
            <td>
              <select v-model="promo.is_active" @change="updatePromotionStatus(promo)">
                <option :value="true">Aktif</option>
                <option :value="false">Pasif</option>
              </select>
            </td>
            <td>
              <button @click="editPromotion(promo)">Düzenle</button>
              <button class="delete" @click="deletePromotion(promo.promotion_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <div v-else>
        <p>Henüz promosyon bulunmamaktadır.</p>
      </div>
  
      <div v-if="showForm" class="form-container">
        <h3>{{ editingPromotion ? 'Promosyonu Düzenle' : 'Yeni Promosyon Ekle' }}</h3>
        <form @submit.prevent="savePromotion">
          <label for="productgroup_id">Ürün Grubu:</label>
          <select v-model="formData.productgroup_id" required>
            <option v-for="group in productGroups" :key="group.productgroup_id" :value="group.productgroup_id">
              {{ group.productgroup_name }}
            </option>
          </select>
  
          <label for="start_date">Başlangıç Tarihi:</label>
          <input type="date" v-model="formData.start_date" required />
  
          <label for="end_date">Bitiş Tarihi:</label>
          <input type="date" v-model="formData.end_date" required />
  
          <label for="discount_percentage">İndirim Oranı (%):</label>
          <input type="number" v-model="formData.discount_percentage" min="0" max="100" step="0.01" />
  
          <label for="discount_amount">İndirim Miktarı (TL):</label>
          <input type="number" v-model="formData.discount_amount" min="0" step="0.01" />
  
          <label for="is_active">Durum:</label>
          <input type="checkbox" v-model="formData.is_active" />
  
          <button class="confirm" type="submit">{{ editingPromotion ? 'Güncelle' : 'Ekle' }}</button>
        </form>
      </div>
  
      <button class="confirm" @click="addNewPromotion">Yeni Promosyon Ekle</button>
    </div>
  </template>
  
  
  <script>
  import axios from "axios";

  export default {
    data() {
      return {
        promotions: [], 
        productGroups: [], 
        formData: { 
          promotion_id: null,
          productgroup_id: null,
          start_date: '',
          end_date: '',
          discount_percentage: null,
          discount_amount: null,
          is_active: false,
        },
        showForm: false, 
        editingPromotion: false, 
      };
    },
    mounted() {
      this.fetchPromotions();
      this.fetchProductGroups();
    },
    methods: { 
      async fetchPromotions() {
        try {
          const response = await axios.get("/admin/promotions");
          this.promotions = response.data;
        } catch (error) {
          console.error("Promosyonları getirirken hata:", error);
        }
      }, 
      async fetchProductGroups() {
        try {
          const response = await axios.get("/admin/productgroups");
          this.productGroups = response.data;
        } catch (error) {
          console.error("Ürün gruplarını getirirken hata:", error);
        }
      }, 
      addNewPromotion() {
        this.showForm = true;
        this.editingPromotion = false;
        this.formData = {
          promotion_id: null,
          productgroup_id: null,
          start_date: '',
          end_date: '',
          discount_percentage: null,
          discount_amount: null,
          is_active: false,
        };
      }, 
      editPromotion(promo) {
        this.showForm = true;
        this.editingPromotion = true;
        this.formData = { ...promo };
      }, 
      async savePromotion() {
        try {
          if (this.editingPromotion) {
            await axios.put(`/admin/promotions/${this.formData.promotion_id}`, this.formData);
          } else {
            await axios.post("/admin/promotions", this.formData);
          }
          this.fetchPromotions();
          this.showForm = false;
        } catch (error) {
          console.error("Promosyon kaydederken hata:", error);
        }
      }, 
      async deletePromotion(id) {
        try {
          await axios.delete(`/admin/promotions/${id}`);
          this.fetchPromotions();
        } catch (error) {
          console.error("Promosyon silerken hata:", error);
        }
      }, 
      async updatePromotionStatus(promo) {
        try { 
          await axios.put(`/admin/promotions/${promo.promotion_id}`, {
            is_active: promo.is_active,
          });
        } catch (error) {
          console.error("Promosyon durumu güncellenirken hata:", error);
        }
      }
    },
  };
</script>

  
  <style scoped> 
  .confirm{margin-top: 2em;}
  </style> 