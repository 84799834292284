<template>
  <div>
    <div class="container">
      <div>
        <h4>Anketler</h4>
        <ul>
          <li v-for="survey in surveys" :key="survey.survey_id">
            <p><b class="title"> Başlangıç:</b> {{ formatDateTime(survey.start_date) }} <b class="title"> Bitiş: </b>{{ formatDateTime(survey.end_date) }}</p>
            <p class="title">{{ survey.title }}</p>
            <p>{{ survey.description }}</p>
<div style="display: flex; gap: 10px;">
  <button @click="editSurvey(survey)">Düzenle</button>
  <button @click="deleteSurvey(survey.survey_id)">Sil</button>
</div>
          </li>
        </ul>
      </div>
      <div class="form-area">
        <h4>{{ editingSurvey ? 'Anketi Düzenle' : 'Yeni Anket Ekle' }}</h4>
        <form @submit.prevent="submitSurvey">
          <input v-model="surveyForm.title" placeholder="Başlık" required />
          <textarea v-model="surveyForm.description" placeholder="Açıklama"></textarea>
          <input type="datetime-local" v-model="surveyForm.start_date" placeholder="Başlangıç Tarihi" />
          <input type="datetime-local" v-model="surveyForm.end_date" placeholder="Bitiş Tarihi" />
          <select v-model="surveyForm.status">
            <option value="active">Aktif</option>
            <option value="inactive">Pasif</option>
            <option value="completed">Tamamlandı</option>
          </select>

          <input v-model="surveyForm.duration" placeholder="Süre (dakika)" required />
          <p style="margin-bottom: 0;">Üyeler için anket mi?</p>
          <select v-model="surveyForm.survey_type">
            <option value="public">Genel</option>
            <option value="private">Özel</option>
          </select>
          
          <p style="margin-bottom: 0;">Anket sonuçları:</p>
          <select v-model="surveyForm.result_type">
            <option value="public">Herkese Açık</option>
            <option value="private">Özel</option>
          </select>

          <div v-if="surveyForm.survey_type === 'private'">
            <select v-model="surveyForm.currency_id" @change="updateCurrencyName">
              <option v-for="currency in currencies" :value="currency.currency_id" :key="currency.currency_id">
                {{ currency.currency_name }}
              </option>
            </select> 
            <input v-model="surveyForm.amount" type="number" placeholder="Miktar" step="0.00000001" />
          </div>
          
          <button type="submit">Kaydet</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      surveys: [],
      surveyForm: {
        title: '',
        description: '',
        start_date: '',
        end_date: '',
        status: 'active',
        survey_type: 'public',
        result_type: 'private',
        duration: '',
        currency_id: null,
        currency_name: '',
        amount: null, 
      },
      currencies: [],
      editingSurvey: false
    };
  },
  async mounted() {
    await this.fetchSurveys();
    await this.fetchCurrencies();
  },
  methods: {
    async fetchSurveys() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/surveys', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.surveys = response.data;
      } catch (error) {
        console.error('Anketler alınırken hata oluştu:', error);
      }
    },
    async fetchCurrencies() { 
      try {
        const response = await axios.get('/auth/currencies' );
        this.currencies = response.data;
      } catch (error) {
        console.error('Para birimleri alınırken hata oluştu:', error);
      }
    },
    onSurveyTypeChange() {
      if (this.surveyForm.survey_type !== 'private') {
        this.surveyForm.currency_id = null;
        this.surveyForm.amount = null;
      }
    },
    editSurvey(survey) {
      this.surveyForm = {
        ...survey,
        start_date: this.formatDateTime(survey.start_date),
        end_date: this.formatDateTime(survey.end_date),
        survey_type: survey.survey_type || 'public',
        result_type: survey.result_type || 'private',
        duration: survey.duration || '',
        currency_id: survey.currency_id || null,
        amount: survey.amount || null
      };
      this.editingSurvey = true;
    },
    updateCurrencyName() {
      const selectedCurrency = this.currencies.find(currency => currency.currency_id === this.surveyForm.currency_id);
      this.surveyForm.currency_name = selectedCurrency ? selectedCurrency.currency_name : '';
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const [date, time] = new Date(dateTime).toISOString().split('T');
      const formattedTime = time.substring(0, 5);
      return `${date} ${formattedTime}`;
    }, 
    async deleteSurvey(surveyId) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/admin/surveys/${surveyId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.fetchSurveys();
      } catch (error) {
        console.error('Anket silinirken hata oluştu:', error);
      }
    },
    async submitSurvey() {
      try {
        const token = localStorage.getItem('token');
        const method = this.surveyForm.survey_id ? 'put' : 'post';
        const url = this.surveyForm.survey_id ? `/admin/surveys/${this.surveyForm.survey_id}` : '/admin/surveys';
        await axios[method](url, this.surveyForm, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.resetForm();
        await this.fetchSurveys();
      } catch (error) {
        console.error('Anket gönderilirken hata oluştu:', error);
      }
    },
    resetForm() {
      this.surveyForm = {
        title: '',
        description: '',
        start_date: '',
        end_date: '',
        status: 'active',
        survey_type: 'public',
        result_type: 'private',
        duration: '',
        currency_id: null,
        amount: null
      };
      this.editingSurvey = false;
    } 
  }
};
</script>



<style scoped>
.container { 
  padding: 1em; 
}
.missions {
  background-color: #222222;
  margin-top: 1em;
  padding: 1em;
}

.form-area {
  background-color: #050505;
  margin-top: 1em;
  padding: 1em;
  border-radius: 1em;
} 
ul {
  list-style-type: none;
  padding: 0;
}

li {  
  padding: 0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
  background-color: #080808;
  transition: background-color 0.3s;
}

li:hover {
  background-color: #000; 
}

button {  
  padding: 0.5em 2em;
  margin: 0.5em 1em 0.5em 0em;
  border-radius: 4px; 
  cursor: pointer;
  transition: background-color 0.3s;
}
 
form {
  margin-top: 20px;
}
</style>  