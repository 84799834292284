<template>
  <div class="container">
    <h3>Sohbet Yönetimi</h3>

    <label class="toggle-delete">
      <input type="checkbox" v-model="showDeleteButtons" />
      Sil butonlarını göster
    </label>

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Kullanıcı ID</th>
          <th>GPT ID</th>
          <th>Ad</th>
          <th>Arşiv Durumu</th>
          <th>Silinmiş Durumu</th>
          <th>Oluşturulma Tarihi</th>
          <th>Güncellenme Tarihi</th>
          <th v-if="showDeleteButtons">Kalıcı Sil</th>
          <th>Görüntüle</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="chat in chats" :key="chat.chat_id">
          <td>{{ chat.chat_id }}</td>
          <td>{{ chat.user_id }}</td>
          <td>{{ chat.gpt_id }}</td>
          <td contenteditable @blur="updateChat(chat, 'chat_name', $event)">
            {{ chat.chat_name }}
          </td>
          <!-- Arşiv Durumu: Seçim değiştiğinde ilgili fonksiyon çağrılır -->
          <td>
            <select @change="handleArchiveChange(chat, $event)">
              <option :selected="chat.is_archived" value="true">Arşivli</option>
              <option :selected="!chat.is_archived" value="false">Arşivli Değil</option>
            </select>
          </td>
          <!-- Silinmiş Durumu: Seçim değiştiğinde soft delete fonksiyonu çağrılır -->
          <td>
            <select @change="handleDeleteChange(chat, $event)">
              <option :selected="!chat.is_deleted" value="false">Aktif</option>
              <option :selected="chat.is_deleted" value="true">Silinmiş</option>
            </select>
          </td>
          <td>{{ formatDateTime(chat.created_at) }}</td>
          <td>{{ formatDateTime(chat.updated_at) }}</td>
          <td v-if="showDeleteButtons">
            <button class="delete" @click="deleteChat(chat.chat_id)">Kalıcı Sil</button>
          </td>
          <td>
            <button @click="showChatModal(chat.chat_id)">Görüntüle</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Chat Detayları Modal Bileşeni -->
    <UserChatModal
      v-if="isModalVisible"
      :chat_id="selectedChatId"
      @close="closeModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import UserChatModal from "./UserChatModal.vue";

export default {
  name: "ChatManagement",
  components: {
    UserChatModal,
  },
  data() {
    return {
      chats: [],
      showDeleteButtons: false,
      isModalVisible: false,
      selectedChatId: null,
    };
  },
  methods: {
    fetchChats() {
      axios
        .get("/admin/noahai/chats")
        .then((response) => {
          this.chats = response.data.chats;
        })
        .catch((error) => {
          console.error("Sohbet listesi alınırken hata:", error);
        });
    },
    updateChat(chat, field, event) {
      const newValue = event.target.innerText;
      axios
        .put(`/admin/noahai/chats/${chat.chat_id}`, { [field]: newValue })
        .then(() => {
          chat[field] = newValue;
        })
        .catch((error) => {
          console.error("Sohbet güncellenirken hata:", error);
        });
    },
    deleteChat(chat_id) {
      if (!confirm("Bu sohbeti kalıcı olarak silmek istediğinize emin misiniz?"))
        return;
      axios
        .delete(`/admin/noahai/chats/${chat_id}`)
        .then(() => {
          this.chats = this.chats.filter((chat) => chat.chat_id !== chat_id);
        })
        .catch((error) => {
          console.error("Sohbet silinirken hata:", error);
        });
    },
    // Soft delete işlemi için PUT kullanıyoruz
    softDeleteChat(chat_id) {
      if (!confirm("Bu sohbeti geçici olarak silmek istediğinize emin misiniz?"))
        return;
      axios
        .put(`/admin/noahai/chats/${chat_id}/soft-delete`)
        .then(() => {
          this.fetchChats();
        })
        .catch((error) => {
          console.error("Sohbet soft delete yapılırken hata:", error);
        });
    },
    // Arşivleme işlemi için PUT kullanıyoruz
    archiveChat(chat_id) {
      axios
        .put(`/admin/noahai/chats/${chat_id}/archive`)
        .then(() => {
          this.fetchChats();
        })
        .catch((error) => {
          console.error("Sohbet arşivlenirken hata:", error);
        });
    },
    // Arşivden çıkarma işlemi için PUT kullanıyoruz
    unarchiveChat(chat_id) {
      axios
        .put(`/admin/noahai/chats/${chat_id}/unarchive`)
        .then(() => {
          this.fetchChats();
        })
        .catch((error) => {
          console.error("Sohbet arşivden çıkarılırken hata:", error);
        });
    },
    handleArchiveChange(chat, event) {
      const value = event.target.value === "true";
      if (value && !chat.is_archived) {
        this.archiveChat(chat.chat_id);
      } else if (!value && chat.is_archived) {
        this.unarchiveChat(chat.chat_id);
      }
    },
    handleDeleteChange(chat, event) {
      const value = event.target.value === "true";
      if (value && !chat.is_deleted) {
        this.softDeleteChat(chat.chat_id);
      }
    },
    showChatModal(chat_id) {
      this.selectedChatId = chat_id;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  mounted() {
    this.fetchChats();
  },
};
</script>
 