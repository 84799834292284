<template>
    <div>
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div>
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script> 
import MessageManager from './MessageManager.vue';
import MessageTypeManager from './MessageTypeManager.vue'; 
import UserMessages from './UserMessages.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'MessageTypeManager', 
        components: [ 
          { name: 'Mesaj Tipleri Yönetimi', component: 'MessageTypeManager' },
          { name: 'Mesaj Yönetimi', component: 'MessageManager' },
          { name: 'Kullanıcı mesaj kutuları', component: 'UserMessages' }, 
        ],
      };
    },
    components: { 
      MessageManager,
      MessageTypeManager, 
      UserMessages
    },
  };
  </script>
    
  <style scoped> 
  </style>
    