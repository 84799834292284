<template>
    <div> 
        <div style="padding: 1em;">
            <h3>Lütfen Bir Kullanıcı Seçin</h3>
            <p>Kullanıcı Adı ya da ID'si</p>
            <input type="text">
        </div>
      <div class="button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div> 
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  
  <script>  
  export default {
    data() {
      return {
        currentComponent: 'CurrentStocks', 
        components: [
          { name: 'Ürün İzleme Aktivitesi', component: 'CurrentStocks' }, 
          { name: 'Sepet Hareketleri', component: 'ProductPrices' }, 
          { name: 'Satın Alma Geçmişi', component: 'AllProductPrices' }, 
          { name: 'Kullanıcı Aktivite Grafiği', component: 'AllProductPr2ices' }, 
          { name: 'Toplam Harcama', component: 'AllProductPrw2iaces' }, 
          { name: 'Sitede Kalma Süresi ve diğer İstatistikler', component: 'AllProductPrw2ices' }, 
          { name: 'Kullanıcıya ait tüm Loglar', component: 'AllProdudctPrw2ices' },
          { name: 'Loglara Dayalı AI Tavsiyesi (BETA)', component: 'AllProdudcftPrw2ices' }, 
        ]
      };
    },
  
    components: { 
    },
    
  };
  </script>
    
  <style scoped> 
      .button-container button.active {
    background-color: #815328;
    color: #ffffff; 
    } 
  </style>
    