<template>
    <div class="activity-log" @scroll="handleScroll">
<div class="alert">
  Sil butonu uyarısız siler.
</div>
        <div class="table-header">
            <h3>Aktivite Kayıtları</h3>
            <label>
              <input type="checkbox" v-model="showDeleteButtons" />
              Sil butonunu göster
            </label>
        </div>
      
      <table>
        <thead>
          <tr>
            <th>Kullanıcı</th>
            <th>Aktivite</th>
            <th>Web Adı</th> 
            <th>Tarih</th>
            <th>IP Adresi</th>
            <th v-if="showDeleteButtons">Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in activityLogs" :key="log.id">
            <td>{{ log.user ? log.user.user_name : 'Bilinmiyor' }}</td>
            <td>{{ log.activity }}</td>
            <td>{{ log.web ? log.web.web_name : 'Bilinmiyor' }}</td> 
            <td>{{ formatDateTime(log.timestamp) }}</td>
            <td>{{ log.ip_address }}</td>
            <td v-if="showDeleteButtons">
              <button class="deletebutton" @click="deleteLog(log.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <button v-if="loading" disabled>Yükleniyor...</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        activityLogs: [],
        page: 1,
        limit: 100,
        loading: false,
        showDeleteButtons: false,
        hasMore: true, 
      };
    },
    methods: {
      async fetchActivityLogs() {
        if (!this.hasMore || this.loading) return; 
  
        this.loading = true;
  
        try {
          const response = await axios.get(`/admin/activity-logs?page=${this.page}&limit=${this.limit}`);
          
          if (response.data.length < this.limit) {
            this.hasMore = false; 
          }
  
          this.activityLogs.push(...response.data); 
          this.loading = false;
        } catch (error) {
          console.error('Aktivite kayıtları alınırken hata oluştu:', error);
          this.loading = false;
        }
      },
      async deleteLog(id) {
        try {
          await axios.delete(`/admin/activity-logs/${id}`);
          this.activityLogs = this.activityLogs.filter(log => log.id !== id); 
        } catch (error) {
          console.error('Aktivite kaydı silinirken hata oluştu:', error);
        }
      },
      loadMore() {
        this.page += 1; 
        this.fetchActivityLogs(); 
      },
      handleScroll() {
        const bottom = this.$el.scrollHeight === this.$el.scrollTop + this.$el.clientHeight;
        if (bottom && !this.loading && this.hasMore) {
          this.loadMore(); 
        }
      },
    },
    mounted() {
      this.fetchActivityLogs(); 
      window.addEventListener('scroll', this.handleScroll); 
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll); 
    },
  };
  </script>
  