<template>
  <div class="admin-support-tickets">
    <div class="details" v-if="selectedTicket"> 
      <h3>Destek Talebi Detayları</h3>
      <p><strong>Konu:</strong> {{ selectedTicket.subject }}</p>
      <p><strong>Durum:</strong> {{ selectedTicket.status }}</p> 

      <div v-for="response in sortedResponses" :key="response.response_id" 
          :class="{'admin-response': response.is_admin_response, 'user-response': !response.is_admin_response}">
        <p><strong>{{ response.responder.user_name }}:</strong> 
          <pre class="response-message">{{ response.message }}</pre>
        </p>
        <p class="response-date">{{ formatDate(response.response_date) }}</p>
        <button class="delete" @click="deleteResponse(response.response_id)">Sil</button>
      </div>
      

      <textarea v-model="responseMessage"></textarea>
      <button style="background-color: #127750; color: #b1c3c9;" @click="addResponse">Yanıt Gönder</button>
        <select v-model="selectedStatus">
          <option value="Open">Açık</option>
          <option value="Responded">Yanıtlandı</option> 
          <option value="Closed">Kapalı</option>
        </select>
      <button @click="updateTicketStatus">Durumu Güncelle</button>
    </div> 
    <h3 style="margin-top: 2em;">Aktif Talepler</h3>
    <table>
      <thead>
        <tr>
          <th>Talep ID</th>
          <th>Kullanıcı ID</th>
          <th>Konu</th>
          <th>Durum</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ticket in tickets" :key="ticket.ticket_id">
          <td>{{ ticket.ticket_id }}</td>
          <td>{{ ticket.user_id }}</td>
          <td>{{ ticket.subject }}</td>
          <td>{{ ticket.status }}</td>
          <td>
            <button @click="selectTicket(ticket)">Detaylar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      tickets: [],
      selectedTicket: null,
      responseMessage: '',
      selectedStatus: '',
    };
  },
  async mounted() {
    await this.fetchTickets();
  },
  computed: {
  sortedResponses() {
    return this.selectedTicket 
      ? [...this.selectedTicket.responses].sort((a, b) => new Date(a.response_date) - new Date(b.response_date))
      : [];
  }
},
  methods: {
    async fetchTickets() {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/admin/support-tickets', { headers: { Authorization: `Bearer ${token}` } });
        this.tickets = response.data.tickets;
      } catch (error) {
        console.error('Talepler getirilirken hata oluştu:', error);
      }
    },
    selectTicket(ticket) {
      this.selectedTicket = ticket;
    },
    async addResponse() {
      if (!this.responseMessage.trim()) {
        alert("Yanıt boş olamaz.");
        return;
      }
    
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post('/admin/add-response', {
          ticket_id: this.selectedTicket.ticket_id,
          message: this.responseMessage
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
    
        if (response.data && response.data.success) { 
          this.responseMessage = '';
          const newResponse = response.data.response;
          newResponse.responder = { user_name: "Admin" }; 
          this.selectedTicket.responses.push(newResponse);
        } else { 
          alert("Yanıt eklenirken hata oluştu: " + (response.data.error || "Bilinmeyen hata"));
        }
      } catch (error) {
        console.error('Yanıt eklenirken hata oluştu:', error);
        alert("Yanıt eklenirken hata oluştu: " + (error.response && error.response.data && error.response.data.error ? error.response.data.error : "Bilinmeyen hata"));
      }
    }, 
    async updateTicketStatus() {
      if (!this.selectedTicket || !this.selectedStatus) {
        alert("Lütfen bir talep ve bir durum seçin.");
        return;
      }
    
      const token = localStorage.getItem('token');
      try {
        const response = await axios.put('/admin/update-ticket-status', {
          ticket_id: this.selectedTicket.ticket_id,
          status: this.selectedStatus
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
    
        if (response.data && response.data.success) {
          this.selectedTicket.status = this.selectedStatus; 
          alert("Talep durumu başarıyla güncellendi.");
        } else {
          alert("Talep durumu güncellenirken hata oluştu: " + (response.data.error || "Bilinmeyen hata"));
        }
      } catch (error) {
        console.error('Talep durumu güncellenirken hata oluştu:', error);
        alert("Talep durumu güncellenirken hata oluştu: " + (error.response && error.response.data && error.response.data.error ? error.response.data.error : "Bilinmeyen hata"));
      }
    },
    
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
    },
    async deleteResponse(responseId) {
      if (!confirm("Bu yanıtı silmek istediğinizden emin misiniz?")) {
        return;
      }
    
      const token = localStorage.getItem('token');
      try {
        const response = await axios.delete(`/admin/delete-response/${responseId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
    
        if (response.data && response.data.success) {
          this.selectedTicket.responses = this.selectedTicket.responses.filter(r => r.response_id !== responseId);
          alert("Yanıt başarıyla silindi.");
        } else {
          alert("Yanıt silinirken hata oluştu: " + (response.data.error || "Bilinmeyen hata"));
        }
      } catch (error) {
        console.error('Yanıt silinirken hata oluştu:', error);
        alert("Yanıt silinirken hata oluştu: " + (error.response && error.response.data && error.response.data.error ? error.response.data.error : "Bilinmeyen hata"));
      }
    }, 
  }
};
</script>


<style scoped>
.admin-support-tickets { 
  color: #a1a1a1;
  padding: 1em;
  border-radius: 0.4em; 
}

.admin-support-tickets h3 { 
  margin-bottom: 1em;
}

.admin-support-tickets p {
  margin-bottom: 0.5em;
}

.responses {
  margin-top: 1em;
  background-color: #000;
  padding: 1em;
  border-radius: 0.5em; 
}

.response-date {
  color: #afafaf;
  font-style: italic;
}

textarea, select { 
  padding: 0.6em;
  border-radius: 0.2em;
  margin-top: 1em;
  box-sizing: border-box;
  width: 100%;
}
textarea {
  min-height: 10em;
}
textarea:focus, select:focus {
  outline: none; 
}

button {
  margin-top: 1em;
  padding: 0.6em 3em; 
  border-radius: 0.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.user-response {
  background-color: #0a0a0a;
  padding: 0.5em;
  margin-top: 0.5em; 
  border-radius: 0.5em;
  border-left: 2px solid #953342;
}

.admin-response {
  background-color: #181818;
  padding: 0.5em;
  margin-top: 0.5em;  
  text-align: right; 
  border-radius: 0.5em;
  border-right: 2px solid #127750;
}

.user-response:hover, .admin-response:hover {
  background-color: #000000;
}
.details {
  background-color: #000000;
  padding: 1em;
} 
.response-message {
  white-space: pre-wrap; 
}
</style>
