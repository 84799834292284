<template>
  <div class="container">
    <h4>E-posta Kategorileri Yönetimi</h4>
<div class="cat-container">
  <div v-for="category in categories" :key="category.category_id" class="category-item">
      <h4>{{ category.name }}</h4>
      <p>Hizmet: {{ category.service.name }}</p>
      <button @click="editCategory(category)">Düzenle</button>
      <button class="delete" @click="confirmDelete(category.category_id)">Sil</button>
    </div>
</div>
    <div class="form-area">
      <h4>{{ editingCategory ? 'Kategoriyi Düzenle' : 'Yeni Kategori Ekle' }}</h4>
      <form @submit.prevent="submitCategory">
        <input v-model="categoryForm.name" placeholder="Kategori Adı" required />
        <select v-model="categoryForm.service_id" required>
          <option disabled value="">Hizmet Seçin</option>
          <option v-for="service in services" :key="service.service_id" :value="service.service_id">
            {{ service.name }}
          </option>
        </select>
        <button type="submit">Kaydet</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
  return {
    categories: [],
    services: [],
    categoryForm: {
      category_id: null,
      name: '',
      service_id: '',
    },
    editingCategory: false,
  };
},
async mounted() {
  await this.fetchCategories();
  await this.fetchServices();
},
methods: {
  async fetchCategories() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/emailcategories', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.categories = response.data;
    } catch (error) {
      console.error('Kategoriler alınırken hata oluştu:', error);
    }
  },
  async fetchServices() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/generalservices', {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.services = response.data;
    } catch (error) {
      console.error('Hizmetler alınırken hata oluştu:', error);
    }
  },
  editCategory(category) {
    this.categoryForm = { ...category };
    this.editingCategory = true;
  },
  confirmDelete(categoryId) {
    if (confirm('Bu kategoriyi silmek istediğinize emin misiniz?')) {
      this.deleteCategory(categoryId);
    }
  },
  async deleteCategory(categoryId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/emailcategories/${categoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await this.fetchCategories();
    } catch (error) {
      console.error('Kategori silinirken hata oluştu:', error);
    }
  },
  async submitCategory() {
    try {
      const token = localStorage.getItem('token');
      const method = this.categoryForm.category_id ? 'put' : 'post';
      const url = this.categoryForm.category_id
        ? `/admin/emailcategories/${this.categoryForm.category_id}`
        : '/admin/emailcategories';
      await axios[method](url, this.categoryForm, {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.resetForm();
      await this.fetchCategories();
    } catch (error) {
      console.error('Kategori eklenirken/düzenlenirken hata oluştu:', error);
    }
  },
  resetForm() {
    this.categoryForm = { category_id: null, name: '', service_id: '' };
    this.editingCategory = false;
  },
},
}; 
</script> 

<style>
.cat-container{ 
  display: flex; 
  flex-wrap: wrap;
  gap: 10px;}
  .category-item{ background-color: #000; padding: 1em;
  min-width: 15rem;
  max-width: 25rem;}


</style>