<template>
    <div class="profile-manager">
      <h2>Kullanıcı Profil Resmi Yönetimi</h2>
      
      <!-- Kullanıcı Arama ve Seçim -->
      <div class="search-container">
        <input 
          v-model="searchQuery"
          @input="filterUsers"
          placeholder="Kullanıcı adı veya ID ara"
          class="search-input"
        />
        <ul v-if="filteredUsers.length > 0" class="user-suggestions">
          <li 
            v-for="user in filteredUsers"  
            :key="user.user_id"
            @click="selectUser(user)"
            class="suggestion-item"
          >
            {{ user.user_name }} (ID: {{ user.user_id }})
          </li>
        </ul>
      </div>
      
      <!-- Seçilen Kullanıcı ve Profil Resmi İşlemleri -->
      <div v-if="selectedUser" class="selected-user">
        <h3>
          Seçili Kullanıcı: 
          <strong>{{ selectedUser.user_name }} (ID: {{ selectedUser.user_id }})</strong>
        </h3>
        
        <!-- Mevcut Profil Resmi -->
        <div v-if="userProfilePicture" class="current-picture">
          <h4>Mevcut Profil Resmi:</h4>
          <img 
            :src="userProfilePicture.image_link" 
            alt="Profil Resmi" 
            class="profile-image"
          />
        </div>
        <div v-else class="no-picture">
          <p>Bu kullanıcıya ait profil resmi bulunmamaktadır.</p>
        </div>
        
        <!-- Profil Resmi Yükleme/Güncelleme Formu -->
        <form @submit.prevent="saveProfilePicture" class="picture-form">
          <label class="form-label">Profil Resmi Yükle:</label>
          <input type="file" @change="handleFileChange" accept="image/*" class="file-input" />
          <button type="submit" class="save-button">
            {{ userProfilePicture ? 'Güncelle' : 'Ekle' }}
          </button>
        </form>
        
        <!-- Sil Butonu (varsa mevcut resim için) -->
        <div v-if="userProfilePicture">
          <button @click="deleteProfilePicture" class="delete-button">Sil</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'UserProfilePictureManager',
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: '',
        selectedUser: null,
        userProfilePicture: null,
        selectedFile: null,
      };
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get('/admin/mainusers');
          this.users = response.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata:", error);
        }
      },
      filterUsers() {
        this.filteredUsers = this.users.filter(user =>
          user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.user_id.toString().includes(this.searchQuery)
        );
      },
      async selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = '';
        this.filteredUsers = [];
        await this.fetchUserProfilePicture();
      },
      async fetchUserProfilePicture() {
        try {
          const response = await axios.get('/admin/user-profile-pictures');
          if (response.data.success) {
            const pictures = response.data.data; 
            this.userProfilePicture = pictures.find(picture => picture.user_id === this.selectedUser.user_id) || null;
          }
        } catch (error) {
          console.error("Profil resmi alınırken hata:", error);
        }
      },
      handleFileChange(event) {
        this.selectedFile = event.target.files[0];
      },
      async saveProfilePicture() {
        if (!this.selectedFile) {
          alert("Lütfen bir dosya seçin.");
          return;
        }
        const formData = new FormData();
        formData.append("uploadType", "profilpictures");
        formData.append('image', this.selectedFile);
        formData.append('user_id', this.selectedUser.user_id);
        try {
          if (this.userProfilePicture) { 
            const response = await axios.put(`/admin/user-profile-picture/${this.userProfilePicture.id}`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.success) {
              alert("Profil resmi güncellendi.");
            }
          } else { 
            const response = await axios.post('/admin/user-profile-picture', formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.success) {
              alert("Profil resmi eklendi.");
            }
          } 
          await this.fetchUserProfilePicture();
        } catch (error) {
          console.error("Profil resmi kaydedilirken hata:", error);
          alert("Profil resmi kaydedilirken hata oluştu.");
        }
      },
      async deleteProfilePicture() {
        if (!this.userProfilePicture) {
          alert("Silinecek profil resmi bulunmamaktadır.");
          return;
        }
        if (confirm("Bu profil resmini silmek istediğinize emin misiniz?")) {
          try {
            const response = await axios.delete(`/admin/user-profile-picture/${this.userProfilePicture.id}`);
            if (response.data.success) {
              alert("Profil resmi silindi.");
              this.userProfilePicture = null;
            }
          } catch (error) {
            console.error("Profil resmi silinirken hata:", error);
            alert("Profil resmi silinirken hata oluştu.");
          }
        }
      }
    },
    mounted() {
      this.fetchUsers();
    }
  };
  </script>
  
  <style scoped>
  .profile-manager {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #050505;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .search-container {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #086b68;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .user-suggestions {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;
    background: #000000;
    border: 1px solid #086b68;
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .suggestion-item {
    padding: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .suggestion-item:hover {
    background-color: #000000;
  }
  
  .selected-user {
    padding: 1rem;
    border: 1px solid #086b68;
    border-radius: 4px;
    background-color: #000000;
  }
  
  .current-picture {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .profile-image {
    max-width: 250px;
    border-radius: 4px;
    border: 1px solid #086b68;
  }
  
  .picture-form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .file-input { 
    padding: 0.5rem;
    border-radius: 4px;
  }
  
  .save-button {
    padding: 0.5rem 1rem;
    background-color: #4caf50; 
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  .delete-button {
    padding: 0.5rem 1rem;
    background-color: #f44336; 
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .delete-button:hover {
    background-color: #e53935;
  }
  </style>
  