<template>
  <div> 
    <div class="head">SHOP ve NRS
    </div>
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div> 
    <component :is="currentComponent"></component>
  </div>
</template>


<script>
import AccountingMain from './accounting/AccountingMain.vue';
import UserActivityMain from './activity/UserActivityMain.vue';
import CategoryManager from './categories/CategoryManager.vue';
import SubCategoryManager from './categories/SubCategoryManager.vue';
import OrdersMain from './orders/OrdersMain.vue';
import ProductMain from './products/ProductMain.vue';
import PromotionMain from './promotions/PromotionMain.vue';
import StockMain from './stock/StockMain.vue';

    

export default {
  data() {
    return {
      currentComponent: 'CategoryManager', 
      components: [
        { name: 'Ürün Kategorileri', component: 'CategoryManager' }, 
        { name: 'Alt Kategoriler', component: 'SubCategoryManager' },
        { name: 'Ürünler', component: 'ProductMain' }, 
        { name: 'Stoklar', component: 'StockMain' }, 
        { name: 'Siparişler', component: 'OrdersMain' }, 
        { name: 'Muhasebe', component: 'AccountingMain' }, 
        { name: 'Promosyon yönetimi', component: 'PromotionMain' }, 
        { name: 'Kullanıcı Aktivitesi', component: 'UserActivityMain' },  
        { name: 'Tüm Aktiviteler (Live)', component: 'ActivityLog' },  
      ]
    };
  },

  components: {  
    CategoryManager,
    SubCategoryManager,
    PromotionMain,
    ProductMain,
    StockMain,
    OrdersMain,
    AccountingMain,
    UserActivityMain
  },
  
};
</script>
  
<style scoped>
.button-container {
    align-items: center;
    justify-content: center;
    padding: 1em;
    background-color: black;
    } 
    .head {
    text-align: center;
    font-size: 2em; font-weight: 300;
    } 
    .button-container button.active {
    background-color: #815328;
    color: #ffffff; 
    } 
</style>
  