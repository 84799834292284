<template>
  <div class="container">
    <h3>GPT İsimleri Yönetimi (orijinal modeller)</h3> 

    <div class="alert info">
  LÜTFEN DİKKAT. GPT model isimleri (isimin doğru yazılmaması hataya neden olur) ve güncel token ücretlerini takip ediniz. <br>
  <br>Bize uygun olabilecekleri listeledik. <br><br>
  https://platform.openai.com/docs/pricing
</div>

    <div style="padding: 1em; background-color: black; margin: 1em 0;">
      <p style="color: yellow;"> Tablodaki fiyatlar 1M token başınadır. Aşağıdaki metin 100 token uzunluktadır. Bunun 10.000 katı 1M token'dir.</p>

      <p>Bir zamanlar küçük bir kasabada, herkesin birbirini tanıdığı, 
        sıcak bir atmosferin hâkim olduğu bir yer vardı. Bu kasabada, Elif adında bir kız yaşardı. 
        Elif, hayal gücü geniş biriydi; her gün kasabanın etrafında dolaşarak yeni maceralar keşfetmeyi severdi. 
        Bir gün, kasabanın dışında gizemli bir orman keşfetti. Ormanın derinliklerine doğru</p>
    </div>


    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>GPT Adı</th>
          <th>Input Token Price</th>
          <th>Out Token Price</th>
          <th>İşlem</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="gptName in gptNames" :key="gptName.id">
          <td>{{ gptName.id }}</td>
          <td contenteditable @blur="updateGPTName(gptName, 'gpt_name', $event)">
            {{ gptName.gpt_name }}
          </td>
          <td contenteditable @blur="updateGPTName(gptName, 'input_token_price', $event)">
            {{ gptName.input_token_price }}
          </td>
          <td contenteditable @blur="updateGPTName(gptName, 'out_token_price', $event)">
            {{ gptName.out_token_price }}
          </td>
          <td>
            <button class="delete" @click="deleteGPTName(gptName.id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>

    <h3>Yeni GPT Adı Ekle</h3>
    <form @submit.prevent="createGPTName">
      <input type="text" v-model="newGPTName.gpt_name" placeholder="GPT Adı" required />
      <input type="number" step="0.01" v-model="newGPTName.input_token_price" placeholder="Input Token Price" />
      <input type="number" step="0.01" v-model="newGPTName.out_token_price" placeholder="Out Token Price" />
      <button type="submit">Ekle</button>
    </form>
  </div>
</template>


  
<script>
import axios from "axios";
import { ref, onMounted } from "vue";

export default {
  setup() {
    const gptNames = ref([]);
    const newGPTName = ref({
      gpt_name: "",
      input_token_price: null,
      out_token_price: null,
    });

    const fetchGPTNames = async () => {
      try {
        const response = await axios.get("/admin/gpt/gptnames");
        gptNames.value = response.data.gptNames;
      } catch (error) {
        console.error("GPT isimleri alınırken hata:", error);
      }
    };

    const updateGPTName = async (gptName, field, event) => {
      const newValue = event.target.innerText;
      try {
        await axios.put(`/admin/gpt/gptnames/${gptName.id}`, { [field]: newValue });
        gptName[field] = newValue;
      } catch (error) {
        console.error("GPT Adı güncellenirken hata:", error);
      }
    };

    const deleteGPTName = async (id) => {
      if (!confirm("Bu GPT adını kalıcı olarak silmek istediğinize emin misiniz?")) return;
      try {
        await axios.delete(`/admin/gpt/gptnames/${id}`);
        gptNames.value = gptNames.value.filter((gptName) => gptName.id !== id);
      } catch (error) {
        console.error("GPT Adı silinirken hata:", error);
      }
    };

    const createGPTName = async () => {
      try {
        const response = await axios.post("/admin/gpt/gptnames", newGPTName.value);
        gptNames.value.push(response.data.gptName);
        newGPTName.value = {
          gpt_name: "",
          input_token_price: null,
          out_token_price: null,
        };
      } catch (error) {
        console.error("GPT Adı eklenirken hata:", error);
      }
    };

    onMounted(fetchGPTNames);

    return { gptNames, newGPTName, updateGPTName, deleteGPTName, createGPTName };
  },
};
</script>

  
  <style scoped>
  .container {
    max-width: 980px;
    margin: auto;
    padding: 20px;
  }
  
  h3 {
    text-align: center;
  }
    
  .delete:hover {
    background-color: darkred;
  }
  
  form {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  input, button {
    padding: 5px; 
  } 
  </style>
  