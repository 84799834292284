<template>
    <div class="studio-social-media-management">
      <h3>Stüdyo Sosyal Medya Yönetimi</h3> 
  
      <h4>Tüm Kayıtlar</h4>
      <table class="social-media-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Stüdyo</th>
            <th>Platform</th>
            <th>Hesap</th>
            <th>Şifre</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="social in socials" :key="social.studiosocialmedia_id">
            <td>{{ social.studiosocialmedia_id }}</td>
            <td>{{ social.studio ? social.studio.name : '' }}</td>
            <td>{{ social.platform }}</td>
            <td>{{ social.account }}</td>
            <td>{{ social.password }}</td>
            <td><button @click="editSocial(social)">Düzenle</button></td>
            <td><button @click="confirmDelete(social.studiosocialmedia_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>

      <br>
      <h4>{{ isEdit ? "Kaydı Düzenle" : "Yeni Sosyal Medya Hesabı Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateSocial() : createSocial()">
        <label>Stüdyo Seç</label>
        <select v-model="selectedSocial.studio_id" required>
          <option disabled value="">Lütfen bir stüdyo seçin</option>
          <option v-for="studio in studios" :key="studio.studio_id" :value="studio.studio_id">
            {{ studio.name }}
          </option>
        </select>
  
        <input v-model="selectedSocial.platform" placeholder="Platform (Instagram, Facebook...)" required />
        <input v-model="selectedSocial.account" placeholder="Hesap Kullanıcı Adı" required />
        <textarea v-model="selectedSocial.password" placeholder="Şifre (bilgi amaçlı)" required></textarea>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Kaydet" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        socials: [],
        studios: [],
        selectedSocial: {
          studiosocialmedia_id: null,
          studio_id: '',
          platform: '',
          account: '',
          password: '',
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchStudios() {
        try {
          const response = await axios.get('/admin/refika/studios');
          this.studios = response.data.studios;
        } catch (error) {
          console.error('Stüdyolar alınırken hata oluştu:', error);
        }
      },
      async fetchSocials() {
        try {
          const response = await axios.get('/admin/refika/studiosocialmedia');
          this.socials = response.data.socials;
        } catch (error) {
          console.error('Sosyal medya kayıtları alınırken hata oluştu:', error);
        }
      },
      async createSocial() {
        try {
          await axios.post('/admin/refika/studiosocialmedia', {
            studio_id: this.selectedSocial.studio_id,
            platform: this.selectedSocial.platform,
            account: this.selectedSocial.account,
            password: this.selectedSocial.password,
          });
          this.fetchSocials();
          this.resetForm();
        } catch (error) {
          console.error('Kayıt eklenirken hata oluştu:', error);
        }
      },
      editSocial(social) {
        this.selectedSocial = { ...social, studio_id: social.studio_id };
        this.isEdit = true;
      },
      async updateSocial() {
        try {
          await axios.put(`/admin/refika/studiosocialmedia/${this.selectedSocial.studiosocialmedia_id}`, {
            studio_id: this.selectedSocial.studio_id,
            platform: this.selectedSocial.platform,
            account: this.selectedSocial.account,
            password: this.selectedSocial.password,
          });
          this.fetchSocials();
          this.resetForm();
        } catch (error) {
          console.error('Kayıt güncellenirken hata oluştu:', error);
        }
      },
      async deleteSocial(id) {
        try {
          await axios.delete(`/admin/refika/studiosocialmedia/${id}`);
          this.fetchSocials();
        } catch (error) {
          console.error('Kayıt silinirken hata oluştu:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu kaydı silmek istediğinizden emin misiniz?')) {
          this.deleteSocial(id);
        }
      },
      resetForm() {
        this.selectedSocial = {
          studiosocialmedia_id: null,
          studio_id: '',
          platform: '',
          account: '',
          password: '',
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchStudios();
      this.fetchSocials();
    },
  };
  </script>
  