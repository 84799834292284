<template>
    <div class="admin-user-tests">
      <h3>Kullanıcı Testleri</h3>
      <input
        v-model="searchQuery"
        @input="filterUsers"
        placeholder="Kullanıcı adı veya ID ara"
      />
      <ul v-if="filteredUsers.length > 0" class="user-suggestions">
        <li
          v-for="user in filteredUsers"
          :key="user.user_id"
          @click="selectUser(user)"
          style="cursor: pointer;"
        >
          {{ user.user_name }} (ID: {{ user.user_id }})
        </li>
      </ul>
  
      <div v-if="selectedUser">
        <h4>
          Seçilen Kullanıcı:
          <strong>{{ selectedUser.user_name }} (ID: {{ selectedUser.user_id }})</strong>
        </h4>
  
        <div v-if="userTests.length === 0">
          Bu kullanıcıya ait test kaydı bulunamadı.
        </div>
  
        <div v-else>
          <table cellspacing="0">
            <thead>
              <tr>
                <th>Test ID</th>
                <th>Test Adı</th>
                <th>Zorluk Seviyesi</th>
                <th>Eğitim Bilgisi</th>
                <th>Süre (dk)</th>
                <th>Son Attempt Durumu</th>
                <th>Başlama Zamanı</th>
                <th>Bitiş Zamanı</th>
                <th>Skor</th>
                <th>Cevaplar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="test in userTests" :key="test.test_id">
                <td>{{ test.test_id }}</td>
                <td>{{ test.test_name }}</td>
                <!-- 1) Zorluk seviyesini göstermek için yard. method kullanımı -->
                <td>{{ displayDifficulty(test.difficulty_filter) }}</td>
                <td>
                  <span v-for="edu in test.educations" :key="edu.education_id">
                    {{ edu.name }},
                  </span>
                </td>
                <td>{{ test.duration_minutes }}</td>
                <!-- 2) Attempt durumunu göstermek için hem metin hem renk -->
                <td>
                  <span :style="statusColor(test.attempt)">
                    {{ displayStatus(test.attempt) }}
                  </span>
                </td>
                <td>{{ test.attempt ? formatDateTime(test.attempt.start_time) : '-' }}</td>
                <td>{{ test.attempt ? formatDateTime(test.attempt.end_time) : '-' }}</td>
                <td>{{ test.attempt ? test.attempt.score : '-' }}</td>
                <td><button class="view-results" @click="viewTestResults(test)">Cevaplar</button></td>
              </tr>
            </tbody>
          </table>


          <div v-if="showResultsModal" class="modal-overlay">
            <div class="modal results-modal">
              <button class="close-button" @click="closeResultsModal">×</button>
              <h3>Test Sonuçları</h3>
              <p><strong>Toplam Soru:</strong> {{ testResults.length }}</p>
              <p><strong>Doğru Cevap:</strong> {{ correctAnswersCount }}</p>
              <p><strong>Hatalı Cevap:</strong> {{ testResults.length - correctAnswersCount }}</p>
              <p><strong>Puan:</strong> {{ score }}/100</p>
              
              <ul>
                <li v-for="result in testResults" :key="result.question_id">
                  <p>
                    <strong :style="{'color': result.selected_answer === result.correct_answer ? '#106e35' : '#9b0101'}">Soru:</strong> {{ result.question_text }}
                  </p>
                  <p><strong>Verilen Cevap:</strong> {{ result.selected_answer }}</p>
                  <p><strong>Doğru Cevap:</strong> {{ result.correct_answer }}</p>
                </li>
              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "AdminUserTests",
    data() {
      return {
        users: [],
        filteredUsers: [],
        searchQuery: "",
        selectedUser: null,
        showResultsModal: false,
        userTests: []
      };
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get("/admin/mainusers");
          this.users = response.data;
        } catch (error) {
          console.error("Kullanıcılar alınırken hata oluştu:", error);
        }
      },
      filterUsers() {
        this.filteredUsers = this.users.filter(user =>
          user.user_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.user_id.toString().includes(this.searchQuery)
        );
      },
      async viewTestResults(test) {
        try {
          const response = await axios.get(`/admin/user-test-results/${test.test_id}`);
          this.testResults = response.data.results;
          this.showResultsModal = true;
        } catch (error) {
          console.error('Sonuçlar alınırken hata:', error);
        }
      },
      closeResultsModal() {
        this.showResultsModal = false;
        this.testResults = [];
      },
      selectUser(user) {
        this.selectedUser = user;
        this.searchQuery = "";
        this.filteredUsers = [];
        this.fetchUserTests(user.user_id);
      },
      async fetchUserTests(userId) {
        try {
          const response = await axios.post("/admin/getusertests", {
            user_id: userId
          });
          if (response.data.success) {
            this.userTests = response.data.tests;
          } else {
            this.userTests = [];
          }
        } catch (error) {
          console.error("Testler alınırken hata oluştu:", error);
        }
      }, 
   
      displayDifficulty(difficultyValue) {
        switch (difficultyValue) {
          case 1:
            return "Kolay";
          case 2:
            return "Orta";
          case 3:
            return "Zor";
          default:
            return "-";
        }
      },
   
      displayStatus(attempt) {
        if (!attempt) {
          return "Başlamadı";
        }
        switch (attempt.status) {
          case "in_progress":
            return "Devam Ediyor";
          case "completed":
            return "Tamamlandı";
          case "timeout":
            return "Süresi Doldu";
          default:
            return "Başlamadı";
        }
      },
   
      statusColor(attempt) {
        if (!attempt) {
          return "color: gray;";
        }
        switch (attempt.status) {
          case "in_progress":
            return "color: orange;";
          case "completed":
            return "color: green;";
          case "timeout":
            return "color: red;";
          default:
            return "color: gray;";
        }
      }
    },
    computed: {
      correctAnswersCount() {
        return this.testResults.filter(result => result.selected_answer === result.correct_answer).length;
      },
      score() {
        return Math.round((this.correctAnswersCount / this.testResults.length) * 100);
      }
    },
    mounted() {
      this.fetchUsers();
    }
  };
  </script>
  
  
  <style scoped>
  .user-suggestions {
    list-style-type: none;
    border: 1px solid #ccc;
    max-width: 300px;
    margin: 0;
    padding: 0;
  }
  .user-suggestions li {
    padding: 5px;
    border-bottom: 1px solid #eee;
  }

  .modal-overlay {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1005;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal {
    background: #000000;
    padding: 20px;
    margin: 50px auto;
    width: 100%;
    max-width: 600px; 
    border: 1px solid #022121;
    border-radius: 10px;
  }
  .results-modal {
    overflow: auto;
    max-height: 80vh;
  }
  h2 {
    font-size: 1.8em;
  font-weight: 300;  
  }
  .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 32px; 
  cursor: pointer;
  color: #e6e6e6;
}

.close-button:hover {
  color: #ffffff;
}
  </style>
  