import { createRouter, createWebHistory } from 'vue-router';
import LoginForm from './components/LoginForm.vue';
import RegisterForm from './components/RegisterForm.vue';

// NEW ADMIN STRUCTURE
import AdministrationMain from './components/administration/AdministrationMain.vue';

// MAIN ROUTES  
import AdminNoahMain from './components/administration/NOAH/NoahAdmin.vue';
import AdminShopMain from './components/administration/SHOP/ShopAdmin.vue';
import AdminRefikaMain from './components/administration/REFIKA/RefikaAdmin.vue';
import AdminAdMain from './components/administration/AI/AdAdmin.vue';
import AdminSysMain from './components/administration/SYS/SysAdmin.vue';
import AdminTestMain from './components/administration/TEST/TestAdmin.vue';

const routes = [ 
    { path: '/', component: LoginForm, meta: { title: 'Giriş Yap - Noah PMU' } },
    { path: '/register', component: RegisterForm, meta: { title: 'Kayıt Ol - Noah PMU' } }, 

    //NEW STRUCTURE
    { path: '/administration', component: AdministrationMain, meta: { title: 'Yönetim Paneli - Noah PMU' } }, 

    // MAIN ROUTES  
    { path: '/administration/noah', component: AdminNoahMain, meta: { title: 'NOAH Yönetimi - Noah PMU' } },
    { path: '/administration/shop', component: AdminShopMain, meta: { title: 'Mağaza Yönetimi - Noah PMU' } },
    { path: '/administration/refika', component: AdminRefikaMain, meta: { title: 'Refika Yönetimi - Noah PMU' } },
    { path: '/administration/ad', component: AdminAdMain, meta: { title: 'Reklam Yönetimi - Noah PMU' } },
    { path: '/administration/sys', component: AdminSysMain, meta: { title: 'Sistem Yönetimi - Noah PMU' } },
    { path: '/administration/test', component: AdminTestMain, meta: { title: 'Test Yönetimi - Noah PMU' } }, 
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (!token && to.path !== '/' && to.path !== '/register') {
    next('/');
  } else {
    // Set the page title based on the route's meta title
    if (to.meta.title) {
      document.title = to.meta.title;
    } else {
      document.title = 'Varsayılan Başlık'; // Eğer meta title yoksa varsayılan bir başlık belirleyin
    }
    next();
  }
});

export default router;